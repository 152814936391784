import { Button, ButtonProps } from "@mui/material";
import { FC, forwardRef } from "react";
import { Colors } from "../../../configs";

export interface IButtonProps {}

type CustomButtonProps = IButtonProps & ButtonProps;

export const CustomButton: FC<CustomButtonProps> = forwardRef((props, ref) => {
  const { children, sx, ...otherProps } = props;

  const defaultBackground =
    (sx as any)?.background || (sx as any)?.backgroundColor || Colors.PRIMARY;

  return (
    <Button
      sx={{
        background: defaultBackground,
        color: Colors.WHITE,
        textTransform: "none",
        "&:hover": {
          background: defaultBackground, // Keeps the hover background the same as the normal background
        },
        "&::disabled": {
          background: Colors.BACKSHADE_GRAY,
          color: Colors.ACCENT_GRAY,
        },
        ...sx,
      }}
      {...otherProps}
      ref={ref}
    >
      {children}
    </Button>
  );
});
