import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  AdminDashboardPage,
  ChangePassword,
  LoginPage,
  SettingsPage,
} from "../pages";
import { LobbyPage } from "../pages/pos/lobby/Lobby";
import PrivateRoute from "../components/common/PrivateRoute";
import PublicRoute from "../components/common/PublicRoute";
import CreateCustomerPage from "../pages/pos/customer/create-customer";
import Alteration from "../pages/pos/alteration/Alteration";
import Pickup from "../pages/pos/pickup/pickup";
import CustomerHistoryPage from "../pages/pos/customer/customer-history";
import PosDashboardPage from "../pages/pos/PosDashboardPage";
import CustomerAnalysisScreen from "../pages/pos/customer/customer-analysis";
import BagRackRegisterPage from "../pages/pos/bag-rack-register/BagRackRegister";
import RackConfig from "../pages/admin/config/rack-config/rackConfig";
import UploadPrice from "../pages/admin/config/upload-price/uploadPrice";
import { ConfigPage } from "../pages/pos/configs/ConfigPage";
import DiscountsPage from "../pages/admin/discounts/discounts";
import MerchantsPage from "../pages/admin/config/merchants/merchants";
import MerchantCreatePage from "../pages/admin/config/merchants/create";
import StoresPage from "../pages/admin/config/stores/stores";
import CashiersPage from "../pages/admin/config/cashierPoints/cashierPoints";
import { PinTagConfigPage } from "../pages/admin/config/pinTagConfig/pin-tag-config";
import { PriceList } from "../pages/pos/price-list/price-list";
import { SearchPintagScreen } from "../pages/pos/pintag/search-pintag-screen";
import { OnboardingPage } from "../../src/pages/admin/onboarding-page/OnboardingPage";
import { CashierPointsPage } from "../pages/admin/config/stores/cashierPoints";
import { InstructionsPage } from "../pages/admin/config/instructions/Instructions";
import DailySnapshotScreen from "../pages/pos/daily-snapshot/dailySnapshotScreen";
import CashRegisterStartPage from "../pages/pos/cash-register/cashRegisterStartPage";
import CloseOutPage from "../pages/pos/cash-register/CloseOutPage";
import ViewAllPage from "../pages/pos/cash-register/ViewAllPage";
import DayEndPage from "../pages/pos/cash-register/DayEndPage";
import RegisterBalancingPage from "../pages/pos/cash-register/RegisterBalancingPage";
import { ManagerRoute } from "../components/common/ManagerRoute";
import { UpchargesPage } from "../pages/admin/config/upcharges/Upcharges";
import { SpotsPage } from "../pages/admin/config/spots/Spots";
import { DamagesPage } from "../pages/admin/config/damages/Damages";
import ConveyorInputPage from "../pages/pos/conveyor-input/conveyorInputPage";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth/login" replace />,
  },
  {
    path: "/auth/login",
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: "/auth/password/change",
    element: (
      <PublicRoute>
        <ChangePassword />
      </PublicRoute>
    ),
  },
  {
    path: "/pos/lobby",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <LobbyPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/:id",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <PosDashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/config/printer",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ConfigPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/:id/check-in/alteration",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <Alteration />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/:id/pickup",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerHistoryPage pickupMode={true} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/create",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CreateCustomerPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/:id/update",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CreateCustomerPage mode="edit" />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/:id/history",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerHistoryPage pickupMode={false} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/pickup/:id",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerHistoryPage pickupMode={true} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/customer/:id/analysis",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <CustomerAnalysisScreen />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/bag-rack-register",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <BagRackRegisterPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/cash-register",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ManagerRoute>
          <CashRegisterStartPage />
        </ManagerRoute>
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/cash-register/close-out",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ManagerRoute>
          <CloseOutPage />
        </ManagerRoute>
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/cash-register/view-all",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ManagerRoute>
          <ViewAllPage />
        </ManagerRoute>
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/cash-register/day-end",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ManagerRoute>
          <DayEndPage />
        </ManagerRoute>
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/cash-register/day-end/register-balancing",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ManagerRoute>
          <RegisterBalancingPage />
        </ManagerRoute>
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/dashboard",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <AdminDashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/rack-config",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <RackConfig />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/discounts",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <DiscountsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/upload-price",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <UploadPrice />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <MerchantsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/create",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <MerchantCreatePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/:merchantWhiteLabel/stores",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <StoresPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/:merchantWhiteLabel/stores/:storeID/points",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <CashiersPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/config/merchants/:merchantWhiteLabel/stores/:storeID/pin-tag",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <PinTagConfigPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/cashier-points",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <CashierPointsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/instructions",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <InstructionsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/upcharges",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <UpchargesPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/spots",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <SpotsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/damages",
    element: (
      <PrivateRoute disableAdminGuard={true}>
        <DamagesPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/price-list",
    element: <PriceList />,
  },
  {
    path: "/pos/search-pintag",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <SearchPintagScreen />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/search-invoice",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <SearchPintagScreen openTab={1} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/search-general",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <SearchPintagScreen openTab={2} />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/daily-snapshot",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <DailySnapshotScreen />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/convayor-input",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <ConveyorInputPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/pos/settings",
    //To Do: To be added secure route wrapper
    element: <SettingsPage />,
  },

  {
    path: "/pos/onboarding",
    element: (
      <PrivateRoute disablePosGuard={true}>
        <OnboardingPage />
      </PrivateRoute>
    ),
  },
]);

export { routers };
