import { FC, useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  CreateDamageAction,
  updateDamageAction,
  getAllDamagesAction,
} from "../../../redux/actions/damagesAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

const schema = Yup.object().shape({
  damage: Yup.string().required("Damage description is required"),
});

type DamageFormData = Yup.InferType<typeof schema>;

export interface ICreateDamageDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  populateFor?: { id?: number; damage: string };
}

export const AddDamagesDrawer: FC<ICreateDamageDrawerProps> = ({
  open,
  toggleDrawer,
  populateFor,
}) => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const { addDamageLoading, updateDamageLoading } = useAppSelector(
    (state) => state.damage
  );

  const { control, handleSubmit, reset } = useForm<DamageFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      damage: "",
    },
  });

  useEffect(() => {
    if (open) {
      reset({ damage: populateFor?.damage || "" });
    }
  }, [open, populateFor, reset]);

  const handleSaveDamage = async (data: DamageFormData) => {
    const payload = {
      storeId: 1,
      merchantId: 1,
      damage: data.damage,
    };

    try {
      if (populateFor?.id) {
        await dispatch(
          updateDamageAction({ ...payload, id: populateFor.id })
        ).unwrap();
        snackbar.showSnackbar("Damage updated successfully!", "success");
      } else {
        await dispatch(CreateDamageAction(payload)).unwrap();
        snackbar.showSnackbar("Damage added successfully!", "success");
      }
      dispatch(getAllDamagesAction());
      toggleDrawer();
    } catch (error) {
      snackbar.showSnackbar(
        "Failed to save damage. Please try again.",
        "error"
      );
    }
  };

  return (
    <>
      <CustomDrawer
        isOpen={open}
        toggleDrawer={toggleDrawer}
        sx={{ width: "28.65rem" }}
      >
        <form onSubmit={handleSubmit(handleSaveDamage)}>
          <Box
            sx={{
              paddingInline: "1.857rem",
              paddingTop: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                lineHeight: "1.7rem",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              {populateFor ? "Update Damage" : "Add a New Damage"}
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
            >
              <Typography>Damage Description</Typography>
              <Controller
                name="damage"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                    placeholder="Enter damage description"
                    helperText={fieldState.error?.message}
                    error={fieldState.invalid}
                  />
                )}
              />
            </Box>

            <CustomButton
              sx={{ width: "9.2rem", height: "2.6rem", marginTop: "1rem" }}
              type="submit"
              disabled={addDamageLoading || updateDamageLoading}
            >
              {addDamageLoading || updateDamageLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : populateFor ? (
                "Update Damage"
              ) : (
                "Add Damage"
              )}
            </CustomButton>
          </Box>
        </form>
      </CustomDrawer>
    </>
  );
};
