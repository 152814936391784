import { Box } from "@mui/material";
import { FC } from "react";

import { CustomButton } from "../button/button";
import { Colors } from "../../../configs";
import ReplayIcon from "@mui/icons-material/Replay";
import { pxToRem } from "../../../util";

interface ICashValuesProps {
  noteValue: number;
  count: number;
  onChange: (value: number) => void;
}
const CashValues: FC<ICashValuesProps> = ({ noteValue, count, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.642857143rem",
        width: pxToRem(84),
        maxWidth: pxToRem(84),
      }}
    >
      <CustomButton
        sx={{
          height: "8.29855214rem",
          width: "6rem",
          borderRadius: "1.78571429rem",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          background: "none",
        }}
        onClick={() => onChange(count + 1)}
      >
        <Box
          sx={{
            background:
              count === 0 ? Colors.ACCENT_GRAY_DISABLED : Colors.MAIN_GRAY,
            width: "6rem",
            height: "3.79855214rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.71428571rem",
            fontWeight: "bold",
            color: Colors.WHITE,
          }}
        >
          ${noteValue}
        </Box>
        <Box
          sx={{
            background: Colors.ACCENT_GRAY,
            width: "6rem",
            height: "4.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.71428571rem",
            fontWeight: "bold",
            color: count === 0 ? Colors.ACCENT_GRAY_DISABLED : Colors.MAIN_GRAY,
          }}
        >
          x{count}
        </Box>
      </CustomButton>
      <CustomButton
        sx={{
          width: "2.57142857rem",
          height: "2.57142857rem",
          minWidth: "2.57142857rem",
          minHeight: "2.57142857rem",
          borderRadius: "50%",
          background: Colors.ACCENT_GRAY,
          boxSizing: "border-box",
        }}
        onClick={() => onChange(0)}
      >
        <ReplayIcon
          sx={{
            fontSize: "1.42857143rem",
            color: count === 0 ? Colors.ACCENT_GRAY_DISABLED : Colors.MAIN_GRAY,
          }}
        />
      </CustomButton>
    </Box>
  );
};

export default CashValues;
