import { FC, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  CreateSpotAction,
  getAllSpotsAction,
  updateSpotAction,
} from "../../../redux/actions/spotsAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

const schema = Yup.object().shape({
  name: Yup.string().required("Spot name is required"),
  showNoFee: Yup.boolean().default(true),
  minorCost: Yup.number().required("Minor cost is required").min(1),
  mediumCost: Yup.number().required("Medium cost is required").min(1),
  majorCost: Yup.number().required("Major cost is required").min(1),
  showCustomFee: Yup.boolean().default(true),
});

type SpotFormData = Yup.InferType<typeof schema>;

export interface ICreateSpotDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  populateFor?: {
    id?: number;
    spot: string;
    showNoFee?: boolean;
    minorPrice?: number;
    mediumPrice?: number;
    majorPrice?: number;
    showCustomFee?: boolean;
  };
}

export const AddSpotsDrawer: FC<ICreateSpotDrawerProps> = ({
  open,
  toggleDrawer,
  populateFor,
}) => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const { addSpotLoading, updateSpotLoading, spotsLoading, deleteSpotLoading } =
    useAppSelector((state) => state.spot);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SpotFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      showNoFee: true,
      showCustomFee: true,
      minorCost: 0.0,
      mediumCost: 0.0,
      majorCost: 0.0,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        name: populateFor?.spot ?? "",
        showNoFee: populateFor?.showNoFee ?? true,
        minorCost: populateFor?.minorPrice || 0.0,
        mediumCost: populateFor?.mediumPrice || 0.0,
        majorCost: populateFor?.majorPrice || 0.0,
        showCustomFee: populateFor?.showCustomFee ?? true,
      });
    }
  }, [open, populateFor, reset]);

  const handleSaveSpot = async (data: SpotFormData) => {
    const payload = {
      storeId: 1,
      merchantId: 1,
      spot: data.name,
      minorPrice: data.minorCost,
      mediumPrice: data.mediumCost,
      majorPrice: data.majorCost,
      showNoFee: data.showNoFee,
      showCustomFee: data.showCustomFee,
    };

    try {
      if (populateFor?.id) {
        await dispatch(
          updateSpotAction({ ...payload, id: populateFor.id })
        ).unwrap();
        snackbar.showSnackbar("Spot updated successfully!", "success");
      } else {
        await dispatch(CreateSpotAction(payload)).unwrap();
        snackbar.showSnackbar("Spot added successfully!", "success");
      }
      dispatch(getAllSpotsAction());
      toggleDrawer();
    } catch (error) {
      snackbar.showSnackbar("Failed to save spot. Please try again.", "error");
    }
  };

  return (
    <>
      <CustomDrawer
        isOpen={open}
        toggleDrawer={toggleDrawer}
        sx={{ width: "28.65rem" }}
      >
        <form onSubmit={handleSubmit(handleSaveSpot)}>
          <Box
            sx={{
              paddingInline: "1.857rem",
              paddingTop: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                lineHeight: "1.7rem",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              {populateFor ? "Edit Spot" : "Add a New Spot"}
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
            >
              <Typography>Spot Name</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                    placeholder="Enter Spot Name"
                  />
                )}
              />
            </Box>

            <Divider sx={{ width: "100%" }} />

            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginBottom: "-1.2rem",
              }}
            >
              Sizes
            </Typography>

            <Controller
              name="showNoFee"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{
                        marginLeft: "0.2rem",
                        "& .MuiSvgIcon-root": { fontSize: "1.6rem" },
                      }}
                    />
                  }
                  label="Has no-fee option"
                />
              )}
            />

            <Controller
              name="showCustomFee"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{
                        marginLeft: "0.2rem",
                        "& .MuiSvgIcon-root": { fontSize: "1.6rem" },
                      }}
                    />
                  }
                  label="Has custom-fee option"
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "-1.2rem",
              }}
            >
              <Typography>Minor Price</Typography>
              <Controller
                name="minorCost"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    type="currency"
                    styles={{
                      textAlign: "left",
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    sx={{
                      width: "100%",
                      backgroundColor: Colors.WHITE,
                    }}
                    placeholder="Enter Minor Cost"
                  />
                )}
              />

              <Typography>Medium Price</Typography>
              <Controller
                name="mediumCost"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    type="currency"
                    styles={{
                      textAlign: "left",
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                    placeholder="Enter Medium Cost"
                  />
                )}
              />

              <Typography>Major Price</Typography>
              <Controller
                name="majorCost"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    type="currency"
                    styles={{
                      textAlign: "left",
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                    placeholder="Enter Major Cost"
                  />
                )}
              />
            </Box>

            <CustomButton
              sx={{ width: "9.2rem", height: "2.6rem", marginTop: "1rem" }}
              type="submit"
              disabled={
                addSpotLoading ||
                updateSpotLoading ||
                spotsLoading ||
                deleteSpotLoading
              }
            >
              {addSpotLoading ||
              updateSpotLoading ||
              spotsLoading ||
              deleteSpotLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : populateFor ? (
                "Update Spot"
              ) : (
                "Add Spot"
              )}
            </CustomButton>
          </Box>
        </form>
      </CustomDrawer>
    </>
  );
};
