import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { he } from "@faker-js/faker";
import dayjs, { Dayjs } from "dayjs";

import CustomModal from "../../common/custom-modal/custom-modal";
import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import CustomDateTimePicker from "../../atoms/custom-date-time-picker/custom-date-time-picker";
import { pxToRem } from "../../../util";
import { pad } from "lodash";

export interface TaxExceptionModalProps {
  open: boolean;
  onClose(): void;
}

const TaxExceptionModal: FC<TaxExceptionModalProps> = ({ open, onClose }) => {
  const [taxID, setTaxID] = useState("");
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(null);

  return (
    <CustomModal
      title={"Tax Exemption"}
      openModal={open}
      width="45rem"
      height="44rem"
      onClose={onClose}
    >
      <Box
        sx={{
          paddingTop: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <Typography sx={{ color: Colors.TEXT_GRAY_DARK, fontSize: "1.5rem" }}>
            Enter Tax ID:
          </Typography>
          <CustomTextBox
            placeholder="Your tax id here"
            onChange={(e) => setTaxID(e.target.value)}
            value={taxID}
            inputProps={{
              style: {
                fontSize: "1.7rem",
                height: "2rem",
                padding: "1rem 1.5rem",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          <Typography sx={{ color: Colors.TEXT_GRAY_DARK, fontSize: "1.5rem" }}>
            Expiry Date:
          </Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <CustomDateTimePicker
              type="date"
              value={expiryDate}
              onChange={(date) => setExpiryDate(date)}
              sx={{
                "& input": {
                  fontSize: "1.7rem",
                  height: pxToRem(56),
                  py: "1rem",
                  pl: "1.5rem",
                  minWidth: pxToRem(530),
                  width: pxToRem(530),
                  boxSizing: "border-box",
                },
                "& fieldset": {
                  width: pxToRem(530),
                  padding: 0,
                },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "2.5rem",
          }}
        >
          <CustomButton
            sx={{
              height: "3.85714286rem",
              // width: "16.2857143rem",
              minWidth: "16.2857143rem",
              flex: 1,
              fontSize: "1.42857143rem",
              background: "none",
              color: Colors.TEXT_GRAY_DARK,
              border: `0.1114285714rem solid ${Colors.TEXT_GRAY_DARK}`,
              whiteSpace: "nowrap",
              padding: "0",
            }}
          >
            Remove Exemption
          </CustomButton>
          <CustomButton
            sx={{
              height: "3.85714286rem",
              // width: "14.2857143rem",
              minWidth: "16.2857143rem",
              flex: 1,
              fontSize: "1.42857143rem",
              padding: "0",
            }}
          >
            Add Exemption
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default TaxExceptionModal;
