import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Chrono } from "react-chrono";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getInvoiceHistoryAction } from "../../../redux/actions/customerAction";

import { Colors } from "../../../configs";
import CustomModal from "../../common/custom-modal/custom-modal";
import { pxToRem } from "../../../util";

interface InvoiceHistoryModalProps {
  open: boolean;
  onClose: () => void;
  billId: string;
  selectedId: number;
}

const InvoiceHistoryModal: React.FC<InvoiceHistoryModalProps> = ({
  open,
  onClose,
  billId,
  selectedId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const invoiceHistory = useSelector(
    (state: RootState) => state.customer.invoiceHistory
  );

  const invoiceHistoryLoading = useSelector(
    (state: RootState) => state.customer.invoiceHistoryLoading
  );

  useEffect(() => {
    if (billId) {
      dispatch(getInvoiceHistoryAction(Number(selectedId)));
    }
  }, [selectedId, dispatch]);

  const timelineItems =
    invoiceHistory?.map((item) => ({
      title: (
        <Typography>
          <span style={{ color: Colors.MAIN_GRAY, fontWeight: "bold" }}>
            {new Date(item.createdAt).toLocaleDateString()}
          </span>
          {" - "}
          <span style={{ color: Colors.MAIN_GRAY }}>
            {new Date(item.createdAt).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </Typography>
      ),
      cardTitle: (
        <Typography sx={{ color: Colors.MAIN_GRAY, fontWeight: "bold" }}>
          {`${item.title || "Untitled"} | Cashier ID: ${item.cashierId}`}
        </Typography>
      ),
      cardSubtitle: (
        <Typography sx={{ color: Colors.MAIN_GRAY }}>
          {item.description || "No description"}
        </Typography>
      ),
      cardDetailedText: (
        <Typography sx={{ color: Colors.MAIN_GRAY }}>
          {item.description}
        </Typography>
      ),
    })) ?? [];

  return (
    <CustomModal
      title=""
      openModal={open}
      onClose={onClose}
      width="51.7857142857rem"
      height="70.8571428571rem"
    >
      <Box
        sx={{
          p: 4,
          position: "relative",
          width: "100%",
          height: "100%",
          borderRadius: "0.71rem",
          boxShadow: "none",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            component="button"
            sx={{
              bgcolor: Colors.SECONDARY,
              color: Colors.WHITE,
              width: "11.06rem",
              height: "2.95rem",
              fontSize: "1.4286rem",
              borderRadius: "0.71rem",
              padding: "0.5rem 1rem",
              border: "none",
              cursor: "pointer",
              marginTop: "-10rem",
              marginLeft: "-3rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            {`IN${billId}`}
          </Box>
        </Box>

        <Box
          sx={{
            height: "60rem",
            width: "130%",
            marginLeft: "-6rem",
            marginTop: "-1rem",
          }}
        >
          {timelineItems.length > 0 ? (
            <Chrono
              items={timelineItems}
              mode="VERTICAL"
              cardHeight={pxToRem(108.5)}
              theme={{
                primary: Colors.SECONDARY,
                secondary: Colors.WHITE,
                cardBgColor: Colors.WHITE,
              }}
              disableToolbar
            />
          ) : invoiceHistoryLoading ? (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt: 30,
              }}
            >
              <CircularProgress />
            </Typography>
          ) : (
            <Typography sx={{ pl: 4, pt: 4 }}>No history available</Typography>
          )}
        </Box>
      </Box>
    </CustomModal>
  );
};

export default InvoiceHistoryModal;
