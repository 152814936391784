// this file is temporary created because exisiting ParentNode file is not totally reusable.

import { FC } from "react";
import { Box, FormControlLabel, Typography } from "@mui/material";

import StyledTreeItem from "./styledTreeItem";
import { pxToRem } from "../../../util";
import IOSSwitch from "../custom-toggle-button/custom-toggle-button";
import { Colors } from "../../../configs";

export interface ITempoParentNodeProps {
  children?: React.ReactNode;
  nodeID: string;
  service: string;
  partialPickup?: boolean;
  qty: number;
  total: number;
  onTogglePartialPickup(status: boolean): void;
}

const TempoParentNode: FC<ITempoParentNodeProps> = ({
  children,
  nodeID,
  service,
  partialPickup = false,
  qty,
  total,
  onTogglePartialPickup,
}) => {
  return (
    <StyledTreeItem
      nodeId={nodeID}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Service Label */}
          <Typography
            sx={{
              fontSize: "1.14285714rem",
              lineHeight: "1.37142857rem",
              color: Colors.BLACK,
              fontWeight: "bold",
              width: "18rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {service}
          </Typography>

          {/* Partial Pickup Toggle */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.714285714rem",
              ml: pxToRem(-320),
            }}
          >
            <FormControlLabel
              checked={partialPickup}
              onChange={(e) => {
                e.stopPropagation();
                onTogglePartialPickup((e.target as HTMLInputElement).checked);
              }}
              onClick={(e) => e.stopPropagation()}
              control={<IOSSwitch sx={{ ml: pxToRem(10) }} />}
              label={
                <Typography sx={{ fontSize: "1.42857143rem", fontWeight: 400 }}>
                  Partial Pickup
                </Typography>
              }
              labelPlacement="start"
              sx={{ margin: 0 }}
            />
          </Box>

          {/*Quantity & Total */}
          <Typography
            sx={{
              fontSize: "1.42857143rem",
              color: Colors.TEXT_GRAY_DARK,
              ml: pxToRem(200),
            }}
          >
            x{qty}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.42857143rem",
              color: Colors.TEXT_GRAY_DARK,
              mr: pxToRem(30),
            }}
          >
            ${total.toFixed(2)}
          </Typography>
        </Box>
      }
      type="parent"
      sx={{
        "& > .MuiTreeItem-content": {
          background: Colors.BACKGROUND_GRAY,
          width: "100%",
          height: "3.85714286rem",
          borderRadius: "0.428571429rem",
          display: "flex",
          alignItems: "center",
          padding: "1.07142857rem",
          border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
          "&:hover": {
            background: Colors.ACCENT_GRAY,
          },
        },
      }}
    >
      {children}
    </StyledTreeItem>
  );
};

export default TempoParentNode;
