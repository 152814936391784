import { FC, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import {
  CreateUpchargeAction,
  getAllUpchargesAction,
  updateUpchargeAction,
} from "../../../redux/actions/upchargesAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { pxToRem } from "../../../util";

interface IVariant {
  upchargeVariant: string;
  price: number;
}

interface IUpcharge {
  id?: number;
  upcharge: string;
  upchargeVariants: IVariant[];
}

const schema = Yup.object().shape({
  upcharge: Yup.string().required("Upcharge is required"),
  upchargeVariants: Yup.array()
    .of(
      Yup.object().shape({
        upchargeVariant: Yup.string().required("Variant is required"),
        price: Yup.number()
          .positive("Price must be greater than 0")
          .required("Price is required"),
      })
    )
    .default([]),
});

export interface ICreateUpchargeDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  populateFor?: IUpcharge;
}

export const AddUpchargesDrawer: FC<ICreateUpchargeDrawerProps> = ({
  open,
  toggleDrawer,
  populateFor,
}) => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const { addUpchargeLoading, updateUpchargeLoading, upchargesLoading } =
    useAppSelector((state) => state.upcharge);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUpcharge>({
    resolver: yupResolver(schema),
    defaultValues: {
      upcharge: "",
      upchargeVariants: [],
    },
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "upchargeVariants",
  });

  useEffect(() => {
    if (open) {
      reset({
        upcharge: populateFor?.upcharge || "",
        upchargeVariants: populateFor?.upchargeVariants || [],
      });
      replace(populateFor?.upchargeVariants || []);
    } else {
      reset({ upcharge: "", upchargeVariants: [] });
      replace([]);
    }
  }, [open, populateFor, reset, replace]);

  const handleSaveUpcharge = async (data: IUpcharge) => {
    const payload = {
      merchantId: 1,
      storeId: 1,
      upcharge: data.upcharge,
      upchargeVariants: data.upchargeVariants,
    };

    try {
      if (populateFor?.id) {
        await dispatch(
          updateUpchargeAction({ ...payload, id: populateFor.id })
        ).unwrap();
        snackbar.showSnackbar("Upcharge updated successfully!", "success");
      } else {
        await dispatch(CreateUpchargeAction(payload)).unwrap();
        snackbar.showSnackbar("Upcharge added successfully!", "success");
      }
      dispatch(getAllUpchargesAction());
      toggleDrawer();
    } catch (error) {
      snackbar.showSnackbar(
        "Failed to save upcharge. Please try again.",
        "error"
      );
    }
  };

  return (
    <>
      <CustomDrawer
        isOpen={open}
        toggleDrawer={toggleDrawer}
        sx={{ width: "28.65rem" }}
      >
        <form onSubmit={handleSubmit(handleSaveUpcharge)}>
          <Box
            sx={{
              paddingInline: "1.857rem",
              paddingTop: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                lineHeight: "1.7rem",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              {populateFor ? "Edit Upcharge" : "Add a New Upcharge"}
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
            >
              <Typography>Upcharge Name</Typography>
              <Controller
                name="upcharge"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextBox
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                    placeholder="Enter Upcharge Name"
                  />
                )}
              />
            </Box>

            <Divider sx={{ width: "100%" }} />

            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Variants</Typography>
                <IconButton
                  onClick={() => append({ upchargeVariant: "", price: 0 })}
                  sx={{
                    color: "black",
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disabled={
                    addUpchargeLoading ||
                    updateUpchargeLoading ||
                    upchargesLoading
                  }
                >
                  <AddIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  maxHeight: "49rem",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": { width: "3px" },
                  "&::-webkit-scrollbar-thumb": { borderRadius: "6px" },
                  pr: "6px",
                  mr: "-8px",
                }}
              >
                {fields.length > 0 &&
                  fields.map((field, index) => (
                    <Box key={field.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0rem",
                        }}
                      >
                        <Box
                          sx={{
                            width: pxToRem(250),
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem",
                          }}
                        >
                          <Controller
                            name={`upchargeVariants.${index}.upchargeVariant`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: pxToRem(250),
                                  backgroundColor: Colors.WHITE,
                                  marginTop: "1rem",
                                }}
                                placeholder="Variant"
                              />
                            )}
                          />
                          <Controller
                            name={`upchargeVariants.${index}.price`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                                type="currency"
                                sx={{
                                  backgroundColor: Colors.WHITE,
                                }}
                                styles={{
                                  textAlign: "left",
                                  width: pxToRem(250),
                                }}
                                placeholder="Price"
                              />
                            )}
                          />
                        </Box>

                        <IconButton
                          onClick={() => remove(index)}
                          sx={{
                            color: "red",
                            aspectRatio: 1,

                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Divider
                        sx={{
                          width: "100%",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: "1rem",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "1rem",
                backgroundColor: Colors.WHITE,
              }}
            >
              <CustomButton
                sx={{ width: "9.2rem", height: "2.6rem" }}
                type="submit"
                disabled={
                  addUpchargeLoading ||
                  updateUpchargeLoading ||
                  upchargesLoading
                }
              >
                {addUpchargeLoading ||
                updateUpchargeLoading ||
                upchargesLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : populateFor ? (
                  "Update Upcharge"
                ) : (
                  "Add Upcharge"
                )}
              </CustomButton>
            </Box>
          </Box>
        </form>
      </CustomDrawer>
    </>
  );
};
