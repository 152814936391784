import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import {
  BaseResponseSingle,
  IupchargeResponse,
} from "../../typings/interfaces/responses";
import {
  ADD_UPCHARGE,
  DELETE_UPCHARGE,
  DELETE_UPCHARGE_VARIANT,
  GET_ALL_UPCHARGES,
  UPDATE_UPCHARGE,
} from "../../configs/apiConfig";
import { INewUpchargeRequest } from "../../typings/interfaces/requests/upcharges-requests";

export const getAllUpchargesAction = createAsyncThunk(
  "upcharges/all-upcharges",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IupchargeResponse>>(
        GET_ALL_UPCHARGES
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const CreateUpchargeAction = createAsyncThunk<
  IupchargeResponse,
  INewUpchargeRequest
>("upcharge/create-upcharge", async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post<BaseResponseSingle<IupchargeResponse>>(
      ADD_UPCHARGE(payload.merchantId, payload.storeId),
      payload
    );

    if (!response.data || !response.data.body) {
      throw new Error("Invalid API response format");
    }

    return response.data.body;
  } catch (err: any) {
    return rejectWithValue(err.response?.data || err.message);
  }
});

export const updateUpchargeAction = createAsyncThunk<
  IupchargeResponse,
  { merchantId: number; storeId: number; id: number } & INewUpchargeRequest
>(
  "upcharge/update-upcharge",
  async ({ merchantId, storeId, id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axios.put<BaseResponseSingle<IupchargeResponse>>(
        UPDATE_UPCHARGE(merchantId, storeId),
        { id, merchantId, storeId, ...payload }
      );
      console.log(response);
      return response.data.body;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const deleteUpchargeAction = createAsyncThunk<
  void,
  { merchantId: number; storeId: number; upChargeId: number }
>(
  "upcharge/delete-upcharge",
  async ({ merchantId, storeId, upChargeId }, { rejectWithValue }) => {
    try {
      await axios.delete(DELETE_UPCHARGE(merchantId, storeId, upChargeId));
      return;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const deleteUpchargeVariantAction = createAsyncThunk<
  void,
  { merchantId: number; storeId: number; upChargeId: number; variantId: number }
>(
  "upcharge/delete-upcharge-variant",
  async (
    { merchantId, storeId, upChargeId, variantId },
    { rejectWithValue }
  ) => {
    try {
      await axios.delete(
        DELETE_UPCHARGE_VARIANT(merchantId, storeId, upChargeId, variantId)
      );
      return;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
