import { FC, useEffect, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { AddCircleOutline, ExpandMore, ExpandLess } from "@mui/icons-material";
import { TreeView } from "@mui/x-tree-view";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton } from "../../../components";
import TempoParentNode from "../../../components/atoms/tree-view/TemporaryParentNode"; //temporary added until parentNode is made as reusable
import TempoChildNode from "../../../components/atoms/tree-view/TemporaryChildNode"; //temporary added until childNode is made as reusable
import { formatCurrency, getTenantId, pxToRem } from "../../../util";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ICartSession } from "../../../typings/interfaces/pos/cart-session";
import { CartItem } from "../../../typings/interfaces/pos/cart-item";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import PaymentModal, {
  IPaymentModalData,
} from "../../../components/pos/payment-modal/payment-modal";
import { PaymentModalContents } from "../../../typings/enum/paymentModalContents";
import Payment from "../../../components/pos/payment-modal/payment";
import { PaymentTypeEnum } from "../../../typings/enum/paymentTypes";
import {
  getBillItemIdListAction,
  newPaymentAction,
  pickUpItemAction,
} from "../../../redux/actions/cartAction";
import { BaseResponseSingle } from "../../../typings";
import { PaymentCreateResponse } from "../../../typings/interfaces/responses/paymentResponse";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import { IPaymentRequest } from "../../../typings/interfaces/requests";
import { useParams } from "react-router-dom";
import useCart from "../../../hooks/useCart";
import { store } from "../../../redux/store";
import { IHalfPayment } from "../../../typings/interfaces/payment/halfPayment";
import ItemNoteModal from "../../../components/pos/notes-modal/itemNoteModal";
import {
  getCustomerBillHistoryAction,
  getCustomerByIdAction,
} from "../../../redux/actions/customerAction";

interface IPickupModalProps {
  open: boolean;
  onClose: () => void;
}

enum PickupType {
  PRE_PAY_NOT_PICKUP = "Pre Pay - Not Pickup",
  PAY_AND_PICKUP = "Pay & Pickup",
  NOT_PAY_PICKUP = "Not Pay - Pickup",
}

interface IPickupCartItem extends CartItem {
  selected: boolean;
}
interface IPickupCartBill extends ICartSession {
  cartItems: IPickupCartItem[];
  billId: string;
  id: number;
}

const PickupModal: FC<IPickupModalProps> = ({ open, onClose }) => {
  const { cartHistorySelectedRows, paymentHalfDetails, billItemIdList } =
    useAppSelector((state) => state.cart);

  const { bills } = useAppSelector((state) => state.customer);
  const [preparedData, setPreparedData] = useState<IPickupCartBill[]>([]);
  const [pickupType, setPickupType] = useState<PickupType>(
    PickupType.PRE_PAY_NOT_PICKUP
  );
  const [modalType, setModalType] = useState<"pickup" | "payment">("pickup");
  const [paidAmountsMap, setPaidAmountsMap] = useState<Record<string, number>>(
    {}
  );

  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.CASH
  );

  const {
    getSessionTotalBySessionId,
    getSessionDiscountBySession,
    getSessionDiscountById,
    getSessionTotalBySession,
    cartTotal,
    totalDiscountAmount,
  } = useCart();

  const snackbar = useSnackbarContext();
  const tenantId = getTenantId();
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params["id"];

  useEffect(() => {
    setPreparedData(
      cartHistorySelectedRows.map((item) => {
        const temp: ICartSession = JSON.parse(item.billString) as ICartSession;
        const cartItems: IPickupCartItem[] = temp.cartItems.map((cartItem) =>
          prepareCartItem(cartItem)
        );
        const temp2: IPickupCartBill = temp as IPickupCartBill;
        temp2.cartItems = cartItems;
        temp2.billId = item.billId;
        temp2.id = item.id;
        return temp2;
      })
    );
  }, [cartHistorySelectedRows]);

  useEffect(() => {
    if (!bills || bills.length === 0) return;

    const paidMap: Record<string, number> = {};
    bills.forEach((bill) => {
      paidMap[bill.billId] = bill.paidAmount ?? 0;
    });
    setPaidAmountsMap(paidMap);
  }, [bills]);

  if (!customerId) {
    snackbar.showSnackbar("Customer ID not found", "error");
    onClose();
    return <></>;
  }

  const prepareCartItem = (cartItem: CartItem): IPickupCartItem => {
    return {
      ...cartItem,
      selected: true,
    };
  };

  const toggleSelect = (parentId: number, cartItemId: string) => {
    const updatedData = preparedData.map((parent) => {
      if (parent.id !== parentId) {
        return parent;
      }
      parent.cartItems = parent.cartItems.map((cartItem) => {
        if (cartItem.itemId === cartItemId) {
          return { ...cartItem, selected: !cartItem.selected };
        }
        return cartItem;
      });
      return parent;
    });
    setPreparedData(updatedData);
  };

  const handlePartialPickupToggle = (billIndex: number, newStatus: boolean) => {
    const updatedData = preparedData.map((parent, index) => {
      if (index === billIndex) {
        parent.cartItems = parent.cartItems.map((cartItem) => {
          return { ...cartItem, selected: newStatus ? false : true };
        });
      }
      return parent;
    });
    setPreparedData(updatedData);
  };

  const onSubmit = async () => {
    if (
      pickupType === PickupType.PRE_PAY_NOT_PICKUP ||
      pickupType === PickupType.PAY_AND_PICKUP
    ) {
      setModalType("payment");
    } else {
      handlePickupSubmit();
    }
  };

  const handlePickupSubmit = async () => {
    if (!tenantId) {
      snackbar.showSnackbar("Tenant ID not found", "error");
      return;
    }
    let isFailed = false;
    for (const item of preparedData) {
      try {
        const b = billItemIdList.find(
          (billItem) => item.id === billItem.billId
        );
        if (!b) {
          snackbar.showSnackbar("Bill item ID not found", "error");
          continue;
        }

        const res = await dispatch(
          pickUpItemAction({
            billId: item.id,
            itemIds: item.cartItems.map(
              (i) =>
                b.billItemIdList.find(
                  (billItem) => billItem.hashId === i.itemId
                )!.billItemId
            ),
          })
        ).unwrap();

        if (!res) throw new Error("Pickup failed");
      } catch (e: any) {
        snackbar.showSnackbar(e.message, "error");
        isFailed = true;
      }
    }

    !isFailed && snackbar.showSnackbar("Pickup successful", "success");
    setModalType("pickup");
    onClose();
  };

  const handleOnPaymentSubmit = async (data: IPaymentModalData) => {
    if (!tenantId) {
      snackbar.showSnackbar("Tenant ID not found", "error");
      return;
    }

    const totalPaidAmount = paymentHalfDetails.reduce(
      (acc, curr) => acc + curr.halfPaymentAmount,
      0
    );

    const totalBillAmount = preparedData
      .filter(
        (item) => item.cartItems.filter((item) => item.selected).length > 0
      )
      .reduce(
        (acc, curr) =>
          acc +
          (getSessionTotalBySession(curr as unknown as ICartSession) -
            getSessionDiscountBySession(curr as unknown as ICartSession)),
        0
      );
    const isFullPaymentDone = totalPaidAmount >= totalBillAmount;

    if (isFullPaymentDone) {
      if (pickupType !== PickupType.PRE_PAY_NOT_PICKUP) {
        handlePickupSubmit();
      }
      return;
    }

    preparedData.forEach((item) => {
      const paymentRequest: IPaymentRequest = {
        paymentType: paymentType,
        billId: item.id,
        customerId: Number(customerId),
        amount: data.paidAmount,
      };
      dispatch(newPaymentAction(paymentRequest)).then(async (res) => {
        const payload =
          res?.payload as BaseResponseSingle<PaymentCreateResponse>;

        const updatedPaymentHalfDetails =
          store.getState().cart.paymentHalfDetails;

        const updatedHalfPayment = JSON.parse(
          JSON.stringify(
            // clone deep gave unexpected behavior so that's why used this method
            updatedPaymentHalfDetails.find(
              (item: IHalfPayment) => item.billId === payload.body.billId
            )
          )
        ) as IHalfPayment;
        if (updatedHalfPayment) {
          updatedHalfPayment.halfPaymentAmount += payload.body.amount;
          updatedHalfPayment.payments.push(payload.body);
          try {
            dispatch(
              CartSliceAction.setHalfPayementDetails([
                updatedHalfPayment,
                ...updatedPaymentHalfDetails.filter(
                  (item: IHalfPayment) => item.billId !== payload.body.billId
                ),
              ])
            );
            snackbar.showSnackbar("Payment successful", "success");
          } catch (err: any) {
            snackbar.showSnackbar(`Failed to update payment`, "error");
          }
        } else {
          snackbar.showSnackbar(
            "Failed to find bill's saved half payment",
            "error"
          );
        }

        await dispatch(
          getCustomerBillHistoryAction({
            customerId: Number(customerId),
            page: 0,
            size: 10,
          })
        );
      });

      // const paymentRequest: IPaymentRequest = {
      //   paymentType: paymentType,
      //   billId: paymentHalfDetails.billId as number,
      //   customerId: Number(customerId),
      //   amount: data.paidAmount,
      // };
      // updateHalfOrFullPayment(paymentRequest, data);
    });

    snackbar.showSnackbar("Payment successful", "success");
  };

  const remainingBalance = preparedData
    .filter((item) => item.cartItems.some((ci) => ci.selected))
    .reduce((acc, curr) => {
      const total = curr.cartItems.reduce((acc, cartItem) => {
        const itemTotal =
          cartItem.qty * cartItem.unitPrice +
          cartItem.upcharges.reduce((a, c) => a + c.subUpcharge.price, 0) +
          cartItem.spots.reduce((a, c) => a + (c.subSpot.price ?? 0), 0);
        return acc + itemTotal;
      }, 0);
      const paid = paidAmountsMap[curr.billId] ?? 0;
      return acc + (total - paid);
    }, 0);

  return (
    <CustomModal
      title="Pickup"
      openModal={open}
      onClose={() => {
        setModalType("pickup");
        onClose();
      }}
      width={pxToRem(1263)}
      height={pxToRem(900)}
    >
      {modalType === "pickup" && (
        <>
          {/* Top tree structure box */}
          <Box
            sx={{
              width: pxToRem(1132),
              height: pxToRem(429),
              backgroundColor: Colors.WHITE,
              border: `${pxToRem(1)} solid ${Colors.ACCENT_GRAY}`,
              borderRadius: pxToRem(5),
              marginBottom: pxToRem(10),
              padding: pxToRem(10),
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { width: "0.5rem" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ADADAD",
                borderRadius: "0rem",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "0rem",
              },
            }}
          >
            <TreeView
              aria-label="pickup-list"
              defaultExpandIcon={<ExpandMore />}
              defaultCollapseIcon={<ExpandLess />}
              sx={{
                overflowX: "hidden",
                gap: pxToRem(15),
                display: "flex",
                flexDirection: "column",
              }}
            >
              {preparedData.map((parent, index) => (
                <TempoParentNode
                  key={index}
                  nodeID={String(index)}
                  service={parent.cartItems[0].serviceType!?.categoryName}
                  qty={parent.cartItems.reduce(
                    (acc, curr) => acc + curr.qty,
                    0
                  )}
                  total={
                    getSessionTotalBySession(parent) -
                    getSessionDiscountBySession(parent)
                  }
                  partialPickup={parent.cartItems.some(
                    (item) => item.selected === false
                  )}
                  onTogglePartialPickup={(newStatus) => {
                    handlePartialPickupToggle(index, newStatus);
                  }}
                >
                  {parent.cartItems.map((cartItem, index) => (
                    <TempoChildNode
                      key={index}
                      nodeID={cartItem.itemId}
                      qty={cartItem.qty}
                      total={
                        cartItem.qty * cartItem.unitPrice +
                        cartItem.upcharges.reduce(
                          (acc, curr) => acc + curr.subUpcharge.price,
                          0
                        ) +
                        cartItem.spots.reduce(
                          (acc, curr) => acc + (curr.subSpot.price ?? 0),
                          0
                        )
                      }
                      details={[
                        cartItem.garmentType?.name,
                        cartItem.garmentColor
                          ?.map((color) =>
                            color
                              .replace(/_/g, " ")
                              .replace(/(\b[a-z](?=[a-z]{1}))/g, (x) =>
                                x.toUpperCase()
                              )
                          )
                          .join(", "),
                        cartItem.materialType?.name,
                        cartItem.texture,
                        cartItem.garmentStyle?.name,
                      ]}
                      selected={cartItem.selected}
                      onToggleSelect={() =>
                        toggleSelect(parent.id, cartItem.itemId)
                      }
                    />
                  ))}
                </TempoParentNode>
              ))}
            </TreeView>
          </Box>

          {/* Mid Container */}
          <Box
            sx={{
              width: pxToRem(1132),
              height: pxToRem(54),
              backgroundColor: Colors.BACKSHADE_GRAY,
              border: `${pxToRem(1)} solid ${Colors.ACCENT_GRAY}`,
              borderRadius: pxToRem(5),
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: `0 ${pxToRem(30)}`,
              marginY: pxToRem(15),
            }}
          >
            <Typography
              sx={{
                color: Colors.TEXT_GRAY_DARK,
                fontSize: pxToRem(20),
                fontWeight: "bold",
              }}
            >
              Number of pieces:{" "}
              {preparedData
                .filter(
                  (item) =>
                    item.cartItems.filter((item) => item.selected).length > 0
                )
                .reduce(
                  (acc, curr) =>
                    acc +
                    curr.cartItems.reduce((acc, curr) => acc + curr.qty, 0),
                  0
                )}
            </Typography>
            <Typography
              sx={{
                color: Colors.TEXT_GRAY_DARK,
                fontSize: pxToRem(20),
                display: "flex",
                justifyContent: "space-between",
                gap: pxToRem(180),
              }}
            >
              <span>Payable amount</span>{" "}
              <span>
                {formatCurrency({
                  amount:
                    preparedData
                      .filter((item) =>
                        item.cartItems.some((ci) => ci.selected)
                      )
                      .reduce((acc, curr) => {
                        const total = curr.cartItems.reduce((acc, cartItem) => {
                          const itemTotal =
                            cartItem.qty * cartItem.unitPrice +
                            cartItem.upcharges.reduce(
                              (a, c) => a + c.subUpcharge.price,
                              0
                            ) +
                            cartItem.spots.reduce(
                              (a, c) => a + (c.subSpot.price ?? 0),
                              0
                            );
                          return acc + itemTotal;
                        }, 0);

                        const paid = paidAmountsMap[curr.billId] ?? 0;
                        return acc + (total - paid);
                      }, 0) - totalDiscountAmount,

                  prefix: "$",
                })}
              </span>
            </Typography>
          </Box>

          <Divider
            sx={{
              width: pxToRem(1132),
              height: pxToRem(2),
              backgroundColor: Colors.PRIMARY,
              marginY: pxToRem(15),
            }}
          />

          {/* Bottom Section */}
          <Box
            sx={{
              display: "flex",
              width: pxToRem(1132),
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: pxToRem(20),
                rowGap: pxToRem(30),
                width: "auto",
              }}
            >
              {Object.values(PickupType).map((label, index) => (
                <CustomButton
                  key={label}
                  sx={{
                    width: pxToRem(282),
                    height: pxToRem(78),
                    fontSize: pxToRem(22),
                    borderRadius: pxToRem(10),
                    backgroundColor:
                      pickupType === label ? Colors.PRIMARY : Colors.WHITE,
                    color: pickupType === label ? Colors.WHITE : Colors.PRIMARY,
                    border:
                      pickupType === label
                        ? "none"
                        : `2px solid ${Colors.PRIMARY}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: pxToRem(8),
                  }}
                  onClick={() => setPickupType(label)}
                >
                  {label}
                </CustomButton>
              ))}

              <CustomButton
                sx={{
                  width: pxToRem(282),
                  height: pxToRem(78),
                  fontSize: pxToRem(22),
                  borderRadius: pxToRem(10),
                  backgroundColor: Colors.WHITE,
                  color: Colors.PRIMARY,
                  border: `${pxToRem(2)} solid ${Colors.PRIMARY}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: pxToRem(8),
                  "&:disabled": {
                    border: `${pxToRem(2)} solid ${
                      Colors.ACCENT_GRAY_DISABLED
                    }`,
                  },
                }}
                disabled={true}
              >
                <AddCircleOutline
                  sx={{
                    color: "inherit",
                    width: pxToRem(37),
                    height: pxToRem(37),
                  }}
                />
                Retail Items
              </CustomButton>
            </Box>

            <Box
              sx={{
                height: pxToRem(195),
                width: pxToRem(2),
                backgroundColor: Colors.PRIMARY,
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: pxToRem(10),
              }}
            >
              <Box
                sx={{
                  width: pxToRem(465),
                  height: pxToRem(123),
                  border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
                  borderRadius: pxToRem(7),
                  padding: `${pxToRem(10)} ${pxToRem(25)}`,
                }}
              >
                <Typography
                  sx={{
                    fontSize: pxToRem(20),
                    color: Colors.TEXT_GRAY_DARK,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Total:</span>{" "}
                  <span>
                    {formatCurrency({
                      amount: preparedData
                        .filter(
                          (item) =>
                            item.cartItems.filter((item) => item.selected)
                              .length > 0
                        )
                        .reduce(
                          (acc, curr) =>
                            acc +
                            curr.cartItems.reduce(
                              (acc, curr) =>
                                acc +
                                (curr.qty * curr.unitPrice +
                                  curr.upcharges.reduce(
                                    (acc, curr) => acc + curr.subUpcharge.price,
                                    0
                                  ) +
                                  curr.spots.reduce(
                                    (acc, curr) =>
                                      acc + (curr.subSpot.price ?? 0),
                                    0
                                  )),
                              0
                            ),
                          0
                        ),
                      prefix: "$",
                    })}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: pxToRem(20),
                    color: Colors.TEXT_GRAY_DARK,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Total Discount:</span>{" "}
                  <span>{totalDiscountAmount}</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: pxToRem(20),
                    fontWeight: "bold",
                    color: Colors.TEXT_GRAY_DARK,
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: pxToRem(10),
                  }}
                >
                  <span>Net Total:</span>{" "}
                  <span>
                    {formatCurrency({
                      amount:
                        preparedData
                          .filter(
                            (item) =>
                              item.cartItems.filter((item) => item.selected)
                                .length > 0
                          )
                          .reduce(
                            (acc, curr) =>
                              acc +
                              curr.cartItems.reduce(
                                (acc, curr) =>
                                  acc +
                                  (curr.qty * curr.unitPrice +
                                    curr.upcharges.reduce(
                                      (acc, curr) =>
                                        acc + curr.subUpcharge.price,
                                      0
                                    ) +
                                    curr.spots.reduce(
                                      (acc, curr) =>
                                        acc + (curr.subSpot.price ?? 0),
                                      0
                                    )),
                                0
                              ),
                            0
                          ) - totalDiscountAmount,
                      prefix: "$",
                    })}
                  </span>
                </Typography>
              </Box>

              <CustomButton
                onClick={onSubmit}
                sx={{
                  width: pxToRem(465),
                  height: pxToRem(60),
                  fontSize: pxToRem(28),
                  backgroundColor: Colors.PRIMARY,
                  color: Colors.WHITE,
                  borderRadius: pxToRem(10),
                }}
                disabled={
                  preparedData.reduce(
                    (acc, curr) =>
                      acc +
                      curr.cartItems.filter((item) => item.selected).length,
                    0
                  ) === 0
                }
              >
                Proceed to Cart
              </CustomButton>
            </Box>
          </Box>
        </>
      )}
      {modalType === "payment" && (
        <Payment
          onCheckout={handleOnPaymentSubmit}
          onPaymentTypeChange={(type) => {
            setPaymentType(type);
          }}
          paymentType={paymentType}
          pickupAmount={preparedData
            .filter(
              (item) =>
                item.cartItems.filter((item) => item.selected).length > 0
            )
            .reduce(
              (acc, curr) =>
                acc +
                curr.cartItems.reduce(
                  (acc, curr) =>
                    acc +
                    (curr.qty * curr.unitPrice +
                      curr.upcharges.reduce(
                        (acc, curr) => acc + curr.subUpcharge.price,
                        0
                      ) +
                      curr.spots.reduce(
                        (acc, curr) => acc + (curr.subSpot.price ?? 0),
                        0
                      )),
                  0
                ),
              0
            )}
          remainingBalance={remainingBalance}
        />
      )}
    </CustomModal>
  );
};

export default PickupModal;
