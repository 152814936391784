import { FC } from "react";
import { Box, Typography, Checkbox } from "@mui/material";

import { Colors } from "../../../../configs";
import { ICustomerBill } from "../../../../typings";
import { formatCurrency, formatDate } from "../../../../util/common-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { getBillItemIdListAction } from "../../../../redux/actions/cartAction";
import useCart from "../../../../hooks/useCart";

export interface ICustomerHistoryTableRowProps {
  data: ICustomerBill;
  onClick(data: ICustomerBill): void;
  onViewClick(data: ICustomerBill): void;
  firstRow: boolean;
  lastRow: boolean;
  isSelected: boolean;
}

const CustomerHistoryTableRow: FC<ICustomerHistoryTableRowProps> = ({
  data,
  firstRow,
  lastRow,
  onClick,
  onViewClick,
  isSelected,
}) => {
  const { cartHistorySelectedRows } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const { totalDiscountAmount } = useCart();

  const selectedRow = cartHistorySelectedRows.some(
    (row) => row.billId === data.billId
  );

  const selectRow = () => {
    if (selectedRow) {
      dispatch(
        CartSliceAction.setCartHistorySelectedRows(
          cartHistorySelectedRows.filter((row) => row.billId !== data.billId)
        )
      );
      dispatch(
        CartSliceAction.setBillItemIdList(
          cartHistorySelectedRows.filter((row) => row.billId !== data.billId)
        )
      );
    } else {
      dispatch(
        CartSliceAction.setCartHistorySelectedRows([
          ...cartHistorySelectedRows,
          data,
        ])
      );
      dispatch(getBillItemIdListAction(data.id));
    }
  };

  let styles = {
    tableBodyCell: {
      background: selectedRow
        ? Colors.ACCENT_GRAY_DISABLED
        : Colors.ACCENT_GRAY,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.14rem 0.28rem",
      paddingBottom: "0rem",
      boxSizing: "border-box",
      gap: "1.07rem",
      color: isSelected ? Colors.SECONDARY : Colors.TEXT_GRAY_DARK,
      fontWeight: "bold",
      zIndex: 0,
    },
    descriptionCell: {
      alignItems: "center",
      zIndex: 0,
    },
    tableRow: {
      zIndex: 0,
    },
  };

  const handleOnClickRow = () => {
    onClick(data);
  };

  return (
    // <Box
    //   sx={{ display: "flex", gap: "0.15rem", flexWrap: "nowrap" }}
    //   onClick={handleOnClickRow}
    // >
    <Box
      sx={{
        display: "flex",
        gap: "0.15rem",
        flexWrap: "nowrap",
        marginBottom: "0.15rem",
        zIndex: 0,
      }}
      onClick={handleOnClickRow}
    >
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          ...(lastRow ? { borderBottomLeftRadius: "0.71rem" } : {}),
          flex: 1,
          minWidth: "2.92857142857rem",
          maxWidth: "2.92857142857rem !important",
          width: "3.246rem !important",
          boxSizing: "border-box !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Checkbox onChange={(e) => selectRow()} value={selectedRow} />
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          ...styles.descriptionCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "5.606rem",
          maxWidth: "5.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.billId}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {/* {data?.createdTime &&
              format(new Date(data.createdTime), "MM/dd/yyyy")} */}
            {formatDate(data.createdTime)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "5.606rem",
          maxWidth: "5.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        {/* cashier column */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.cashierName}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {/* {data?.createdTime &&
              format(new Date(data.createdTime), "MM/dd/yyyy")} */}
            {formatDate(data.createdTime)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "5.606rem",
          maxWidth: "5.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.totalItemCount ?? "N/A"}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "4.606rem",
          maxWidth: "4.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{data.rackNumber}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "5.606rem",
          maxWidth: "5.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {formatCurrency({
              amount:
                data.billAmount - (data.paidAmount ?? 0) - totalDiscountAmount,
              prefix: "$",
            })}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>{formatDate(data.lastPaymentDate)}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "7.826rem",
          maxWidth: "7.826rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography>
            {data.lastPickupDate ? formatDate(data.lastPickupDate) : "N/A"}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          fontSize: "1rem",
          marginLeft: "0.01rem",
          marginRight: "0.01rem",
          // marginTop: "-0.5rem",
          // marginBottom: "-0.5rem",
          flex: 1.5,
          minWidth: "5.246rem",
          maxWidth: "5.246rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // paddingBottom: "0.5rem",
            borderBottom: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginBottom: "-0.0714285715rem",
            zIndex: 1,
          }}
        >
          <Typography
            sx={{
              ...styles.tableBodyCell,
              margin: "0.01rem",
              ...(lastRow ? { borderBottomRightRadius: "0.71rem" } : {}),
              flex: 1.5,
              minWidth: "5.246rem",
              maxWidth: "5.246rem !important",
              height: "100%",
              padding: "0",
              backgroundColor: Colors.SECONDARY,
              color: "white",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: Colors.SECONDARY,
              },
            }}
            onClick={() => {
              // console.log(`View clicked for ID: ${data.billId}`);
              onViewClick(data);
            }}
          >
            View
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerHistoryTableRow;
