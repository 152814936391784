import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  CircularProgress,
} from "@mui/material";

import { Colors } from "../../../configs";

interface TableData {
  station: number;
  openingBalance: number;
  cash: number;
  check: number;
  creditCard: number;
  monthlyCharge: number;
  discounts: number;
  sales: number;
  registerTotal: number;
}
export interface IModifiedTableData extends TableData {
  difference: number;
}

interface RegisterBalancingTableProps {
  data: TableData[];
  editableFields: string[];
  isEditingEnabled: boolean;
  onChangeValues?: (data: IModifiedTableData[]) => void;
  isLoading?: boolean;
}

const RegisterBalancingTable: React.FC<RegisterBalancingTableProps> = ({
  data,
  editableFields,
  isEditingEnabled,
  onChangeValues,
  isLoading = false,
}) => {
  const initiallyPreparedData = data.map((row) => ({
    ...row,
    // (register total) - ((opening balance + cash + check + credit card + monthly charge) - (discounts + sales))
    difference:
      row.registerTotal -
      (row.openingBalance +
        row.cash +
        row.check +
        row.creditCard +
        row.monthlyCharge -
        (row.discounts + row.sales)),
  }));

  const [tempTableData, setTempTableData] = useState<IModifiedTableData[]>(
    initiallyPreparedData
  );

  // const handleInputChange = (
  //   rowIndex: number,
  //   field: string,
  //   value: string
  // ) => {
  //   const newData = [...tableData];
  //   newData[rowIndex][field as keyof TableData] = parseFloat(value) || 0;

  //   const table1Row = table1Data[rowIndex];
  //   const table1Total = table1Row.cash + table1Row.check + table1Row.creditCard;
  //   const table2Total =
  //     newData[rowIndex].cash +
  //     newData[rowIndex].check +
  //     newData[rowIndex].creditCard;

  //   newData[rowIndex].difference = table1Total - table2Total;

  //   setTableData(newData);
  // };

  const handleInputChange = (
    rowIndex: number,
    field: string,
    value: string
  ) => {
    const newData = [...tempTableData];
    newData[rowIndex][field as keyof IModifiedTableData] =
      parseFloat(value) || 0;

    const changingRow = newData[rowIndex];
    const difference =
      changingRow.registerTotal -
      (changingRow.openingBalance +
        changingRow.cash +
        changingRow.check +
        changingRow.creditCard +
        changingRow.monthlyCharge -
        (changingRow.discounts + changingRow.sales));
    newData[rowIndex].difference = difference;

    setTempTableData(newData);
    onChangeValues && onChangeValues(newData);
  };

  const commonCellStyles = {
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0.5rem",
    height: "3.714285714285714rem",
  };

  const tableStyles = {
    cell: commonCellStyles,
    headerCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.SECONDARY,
      ...commonCellStyles,
    },
    bodyCell: {
      color: Colors.MAIN_GRAY,
      backgroundColor: Colors.WHITE,
      ...commonCellStyles,
    },
    editableCell: {
      backgroundColor: Colors.WHITE,
      ...commonCellStyles,
    },
    totalRowCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.MAIN_GRAY,
      ...commonCellStyles,
    },
  };

  const totalRowData2 = {
    station: "Total",
    openingBalance: tempTableData.reduce(
      (acc, row) => acc + row.openingBalance,
      0
    ),
    cash: tempTableData.reduce((acc, row) => acc + row.cash, 0),
    check: tempTableData.reduce((acc, row) => acc + row.check, 0),
    creditCard: tempTableData.reduce((acc, row) => acc + row.creditCard, 0),
    monthlyCharge: tempTableData.reduce(
      (acc, row) => acc + row.monthlyCharge,
      0
    ),
    discounts: tempTableData.reduce((acc, row) => acc + row.discounts, 0),
    sales: tempTableData.reduce((acc, row) => acc + row.sales, 0),
    registerTotal: tempTableData.reduce(
      (acc, row) => acc + row.registerTotal,
      0
    ),
    difference: tempTableData.reduce((acc, row) => acc + row.difference, 0),
  };

  return (
    <TableContainer
      sx={{
        overflow: "auto",
        borderRadius: "0.714rem",
      }}
    >
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0.2rem",
          width: "100%",
        }}
      >
        <TableHead>
          <TableRow>
            {[
              "Station",
              "Opening Balance",
              "Cash",
              "Check",
              "Credit Card",
              "Monthly Charge",
              "Discounts",
              "Sales",
              "Register Total",
              "Difference",
            ].map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  ...tableStyles.headerCell,
                  ...(index === 0
                    ? { borderRadius: "0.357rem 0 0 0" }
                    : index === 9
                    ? { borderRadius: "0 0.357rem 0 0" }
                    : {}),
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={10} sx={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : null}
          {!isLoading &&
            tempTableData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {Object.keys(row).map((field, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      ...(editableFields.includes(field) && isEditingEnabled
                        ? tableStyles.editableCell
                        : tableStyles.bodyCell),
                      padding: 0,
                    }}
                  >
                    {editableFields.includes(field) && isEditingEnabled ? (
                      <TextField
                        value={row[field as keyof TableData]}
                        onChange={(e) =>
                          handleInputChange(rowIndex, field, e.target.value)
                        }
                        sx={{
                          width: "100%",
                          "& input": {
                            textAlign: "center",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor:
                                row.difference !== 0
                                  ? Colors.ERROR_RED
                                  : Colors.MAIN_GRAY,
                            },
                            "&:hover fieldset": {
                              borderColor:
                                row.difference !== 0
                                  ? Colors.ERROR_RED
                                  : Colors.MAIN_GRAY,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor:
                                row.difference !== 0
                                  ? Colors.ERROR_RED
                                  : Colors.MAIN_GRAY,
                            },
                          },
                        }}
                        inputProps={{
                          style: { padding: "1rem" },
                        }}
                      />
                    ) : field === "difference" ? (
                      row.difference.toFixed(2)
                    ) : typeof row[field as keyof IModifiedTableData] ===
                        "number" && colIndex > 0 ? (
                      (
                        row[field as keyof IModifiedTableData] as number
                      ).toFixed(2)
                    ) : (
                      row[field as keyof IModifiedTableData]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}

          <TableRow>
            {Object.values(totalRowData2).map((value, index) => (
              <TableCell
                key={index}
                sx={{
                  ...tableStyles.totalRowCell,
                  ...(index === 0
                    ? { borderRadius: "0 0 0 0.357rem" }
                    : index === 9
                    ? { borderRadius: "0 0 0.357rem 0" }
                    : {}),
                }}
              >
                {typeof value === "number" ? value.toFixed(2) : value}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RegisterBalancingTable;
