import { FC } from "react";
import { Box } from "@mui/material";

import usePricing from "../../../../hooks/usePricing";
import { CategoryResponse } from "../../../../typings";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import ServiceTabButton from "./service-tab-button";
import { serviceShortCodes } from "../../../../configs/serviceShortCodes";

export interface ICheckInTabMenuProps {
  currentTab: String;
}

const ServiceTabMenu: FC<ICheckInTabMenuProps> = () => {
  const { categoryList } = usePricing();
  const { selectedCartItem } = useAppSelector((state) => state.cart);

  const { selectedServiceType, previousServiceType, selectedSession } =
    useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  // const setPrev = () => {
  //   return promiseHooks();
  // }

  //create a function to return a promise and dispatch setPreviousServiceType
  // const setPrevService = () => {
  //   return new Promise((resolve, reject) => {
  //     dispatch(CartSliceAction.setPreviousServiceType(selectedServiceType));
  //     resolve("done");
  //   });
  // };

  const handleOnClick = async (service: CategoryResponse) => {
    // dispatch(CartSliceAction.setPreviousServiceType(selectedServiceType));
    // await setPrevService().then((res) => {
    //   if (res === "done") {
    dispatch(CartSliceAction.setSelectedServiceType(service));
    //   }
    // });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "0.57rem", flexWrap: "wrap" }}>
      {categoryList?.body.map((tab, i) => {
        if (tab.showInMenu) {
          return (
            // <Grid
            //   onClick={() => handleOnClick(tab)}
            //   style={{
            //     textDecoration: "none",
            //     width: "9.85rem",
            //     height: "5.14rem",
            //     display: "flex",
            //     flexDirection: "row",
            //     cursor:
            //       selectedServiceType?.categoryName == tab.categoryName
            //         ? "inherit"
            //         : "pointer",
            //     color: Colors.WHITE,
            //     pointerEvents:
            //       selectedServiceType?.categoryName == tab.categoryName
            //         ? "none"
            //         : "auto",
            //   }}
            //   key={i}
            // >
            //   <CustomButton
            //     sx={{
            //       background:
            //         selectedServiceType?.categoryName == tab.categoryName
            //           ? Colors.SECONDARY
            //           : Colors.PRIMARY,
            //       color: Colors.WHITE,
            //       width: "9.85rem",
            //       height: "5.14rem",
            //       borderRadius: "0.71rem",
            //       textTransform: "capitalize",
            //       fontSize: "1.42rem",
            //       lineHeight: "1.42rem",
            //       padding: "0.57rem",
            //       "&:hover": {
            //         background: `${Colors.PRIMARY} !important`,
            //       },
            //     }}
            //   >
            //     {tab.categoryName}
            //   </CustomButton>
            // </Grid>
            <ServiceTabButton
              name={tab.categoryName}
              isSelected={
                selectedServiceType?.categoryName === tab.categoryName
              }
              handleOnClick={() => handleOnClick(tab)}
              key={i}
              disabled={
                (selectedSession?.cartItems?.filter((item) => item.finalized)
                  .length || 0) > 0 &&
                selectedSession?.cartItems[0]?.serviceType?.categoryName !==
                  tab.categoryName
              }
            />
          );
        } else {
          return null;
        }
      })}
      <ServiceTabButton
        name={
          selectedServiceType?.categoryName === "Alteration"
            ? previousServiceType?.categoryName
              ? serviceShortCodes[
                  previousServiceType.categoryName as keyof typeof serviceShortCodes
                ] + " - Altr"
              : "Alteration"
            : selectedServiceType?.categoryName
            ? serviceShortCodes[
                selectedServiceType.categoryName as keyof typeof serviceShortCodes
              ] + " - Altr"
            : "Alteration"
        }
        isSelected={selectedServiceType?.categoryName === "Alteration"}
        handleOnClick={() => {
          handleOnClick({
            id: 100000,
            categoryName: "Alteration",
            deleted: false,
            showInMenu: true,
          });
        }}
        disabled={
          !(
            (selectedServiceType?.categoryName === "Dry Clean" ||
              selectedServiceType?.categoryName === "Household" ||
              selectedServiceType?.categoryName === "Alteration") &&
            ((selectedSession?.cartItems || []).length === 0 ||
              (selectedCartItem !== null && selectedCartItem.finalized))
          )
        }
      />
    </Box>
  );
};

export default ServiceTabMenu;
