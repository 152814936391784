import {
  CalendarIcon,
  LocalizationProvider,
  DatePicker,
  TimePicker,
  TimeIcon,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FC } from "react";
import { Colors } from "../../../configs";
import getCheckOutDate from "../../../configs/checkoutDateRules";
import dayjs, { Dayjs } from "dayjs";
import { pxToRem } from "../../../util";

export interface ICustomDateTimePickerProps {
  type: "date" | "time";
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  disabled?: boolean;
  sx?: any;
  name?: string;
  enableSelect?: "past" | "future" | "all";
}

const CustomDateTimePicker: FC<ICustomDateTimePickerProps> = ({
  type,
  value,
  onChange,
  disabled = false,
  sx = {},
  name,
  enableSelect = "future",
}) => {
  const pickerStyle = {
    padding: 0,
    width: "9.35714286rem",
    height: "2.6rem",
    borderRadius: "0.57142857rem",
    "& input": {
      padding: 0,
      width: "9.35714286rem",
      height: "2.6rem",
      px: "1rem",
      py: "0.214285714rem",
      boxSizing: "border-box",
      fontSize: "1.05rem",
      lineHeight: "1.2rem",
      fontWeight: 500,
      letterSpacing: "0.04rem",
    },
    "& button": {
      padding: "0.642857143rem",
    },
  };
  const iconStyle = {
    fontSize: "1.3rem",
    color: Colors.MAIN_GRAY,
    pointerEvents: "none",
    marginLeft: "-2.714285714rem",
    marginRight: "0.714285714rem",
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {type === "date" ? (
        <DatePicker
          sx={{
            pickerStyle,
            "& input": {
              py: pxToRem(7.5),
            },
            ...sx,
          }}
          value={value}
          onChange={onChange}
          minDate={
            enableSelect === "past" || enableSelect === "all"
              ? undefined
              : dayjs(new Date())
          }
          maxDate={
            enableSelect === "future" || enableSelect === "all"
              ? undefined
              : dayjs(new Date())
          }
          orientation="portrait"
          slotProps={{
            layout: {
              sx: {
                ".MuiPickersDay-root": {
                  // color: "red",
                  fontSize: "1.15rem",
                },
                ".MuiPickersCalendarHeader-label": {
                  // color: "blue",
                  fontSize: "1.5rem",
                },
                ".MuiPickersCalendarHeader-switchViewIcon": {
                  fontSize: "2.5rem",
                  marginTop: "-0.314285714rem",
                },
                ".MuiDayCalendar-weekDayLabel": {
                  fontSize: "1.15rem",
                },
                ".MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root":
                  {
                    height: "2rem",
                    width: "2rem",
                  },
                ".MuiPickersMonth-monthButton": {
                  fontSize: "1.2rem",
                },
                ".MuiPickersYear-yearButton": {
                  fontSize: "1.2rem",
                },
              },
            },
          }}
          disabled={disabled}
          name={name}
          disableOpenPicker
        />
      ) : (
        <TimePicker
          sx={{
            pickerStyle,
            "& input": {
              py: pxToRem(7.5),
            },
            ...sx,
          }}
          value={value}
          onChange={onChange}
          orientation="portrait"
          slotProps={{
            layout: {
              sx: {
                ".MuiClockNumber-root": {
                  // color: "red !important",
                  fontSize: "1.2rem",
                },
                ".MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root":
                  {
                    height: "2rem",
                    width: "2rem",
                  },
                ".MuiPickersArrowSwitcher-root": {
                  // background: "red !important",
                  marginTop: "-1rem",
                },
                ".MuiTypography-root.MuiTypography-subtitle2.MuiTimePickerToolbar-ampmLabel.MuiPickersToolbarText-root.css-7kirvq-MuiTypography-root-MuiPickersToolbarText-root":
                  {
                    // color: "red !important",
                    fontSize: "1.25rem",
                    marginLeft: "0.5rem",
                    letterSpacing: "0.125rem",
                  },
              },
            },
          }}
          disabled={disabled}
          name="name"
          disableOpenPicker
        />
      )}
      {type === "date" ? (
        <CalendarIcon sx={iconStyle} />
      ) : (
        <TimeIcon sx={iconStyle} />
      )}
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
