import React, { useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

interface ConveyorInputConfigTableProps {
  setRowCount: (count: number) => void;
}

const ConveyorInputConfigTable: React.FC<ConveyorInputConfigTableProps> = ({
  setRowCount,
}) => {
  const rows = [
    {
      no: 1,
      date: "2025/02/14",
      time: "12:30",
      cashierId: "xxxxx",
      billId: "xxxxx",
      rack: "01",
      location: "100",
    },
    {
      no: 2,
      date: "2025/12/12",
      time: "01:45",
      cashierId: "xxxxx",
      billId: "xxxxx",
      rack: "02",
      location: "200",
    },
    {
      no: 3,
      date: "2025/03/14",
      time: "02:15",
      cashierId: "xxxxx",
      billId: "xxxxx",
      rack: "01",
      location: "150",
    },
    {
      no: 4,
      date: "2025/02/20",
      time: "03:00",
      cashierId: "xxxxx",
      billId: "xxxxx",
      rack: "02",
      location: "140",
    },
    {
      no: 5,
      date: "2025/07/25",
      time: "04:20",
      cashierId: "xxxxx",
      billId: "xxxxx",
      rack: "01",
      location: "155",
    },
  ];

  useEffect(() => {
    setRowCount(rows.length);
  }, [rows.length, setRowCount]);

  return (
    <TableContainer
      component={Box}
      sx={{
        width: pxToRem(1630),
        maxHeight: pxToRem(225),
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: Colors.WHITE,
        borderRadius: pxToRem(10),
        "&::-webkit-scrollbar": { width: "0.5rem" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ADADAD",
          borderRadius: "0rem",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          borderRadius: "0rem",
        },
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow
            sx={{ backgroundColor: Colors.BACKSHADE_GRAY, height: pxToRem(45) }}
          >
            <TableCell
              sx={{
                width: pxToRem(50),
                height: pxToRem(45),
                minHeight: pxToRem(45),
                maxHeight: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            />
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              No:
            </TableCell>
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              Date
            </TableCell>
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              Time
            </TableCell>
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              Cashier ID
            </TableCell>
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              Bill ID
            </TableCell>
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              Rack
            </TableCell>
            <TableCell
              sx={{
                fontSize: pxToRem(16),
                color: Colors.MAIN_GRAY,
                fontWeight: "bold",
                height: pxToRem(45),
                padding: "0px",
                lineHeight: pxToRem(45),
              }}
            >
              Location
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} sx={{ height: pxToRem(45) }}>
              <TableCell
                sx={{
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                <Checkbox />
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.no}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.date}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.time}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.cashierId}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.billId}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.rack}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: pxToRem(16),
                  color: Colors.MAIN_GRAY,
                  height: pxToRem(45),
                  padding: "0px",
                  lineHeight: pxToRem(45),
                }}
              >
                {row.location}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConveyorInputConfigTable;
