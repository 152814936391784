import { createSlice } from "@reduxjs/toolkit";
import {
  BaseResponse,
  ICustomerBillsResponse,
  ICustomerResponse,
  IcustomerInsightResponse,
  IcustomerLatestBillDetailResponse,
  IcustomerServiceUsageResponse,
  IcustomerAvgSpendingResponse,
  ICustomerBill,
} from "../../typings";
import {
  addStoreCreditAction,
  addUserNoteAction,
  deleteUserNoteAction,
  getCustomerBillHistoryAction,
  getCustomerByIdAction,
  getCustomerCartInfoByIdAction,
  getStoreCreditHistoryAction,
  getUserNotesByIdAction,
  updateUserNoteAction,
  deleteStoreCreditAction,
  getInvoiceHistoryAction,
} from "../actions/customerAction";
import { CustomerCartInfoResponse } from "../../typings/interfaces/responses/customer-cart-info-response";
import { CustomerNoteResponse } from "../../typings/interfaces/responses/customer-note-response";
import { StoreCreditHistoryResponse } from "../../typings/interfaces/responses/store-credit-history-response";
import {
  getAllCustomerInsightsAction,
  getCustomerActivitiesByDayAction,
  getCustomerOutstandingAction,
} from "../actions/customerInsightsAction";
import { getAllCustomerLatestBillDetailsAction } from "../actions/customerLatestBillDetailsAction";
import {
  getAllCustomerCurrentYearServiceUsageAction,
  getAllCustomerAllTimeServiceUsageAction,
} from "../actions/customerServiceUsageAction";
import { getAllCustomerAvgSpendingsAction } from "../actions/customerAvgSpendingsAction";
import { ICustomerActivitiesByDayResponse } from "../../typings/interfaces/responses/customer-activities-by-day-response";
import { ICustomerOutstandingResponse } from "../../typings/interfaces/responses/customer-outstanding-response";
import { InvoiceHistoryResponse } from "../../typings/interfaces/responses/invoice-history-response";

export interface CustomerState {
  customer: ICustomerResponse | null;
  customerLoading: boolean;
  customerError: string | null;

  customerCartInfo: CustomerCartInfoResponse | null;
  customerCartInfoLoading: boolean;
  customerCartInfoError: string | null;

  createCustomerResponse: BaseResponse<ICustomerResponse> | null;
  createCustomerLoading: boolean;
  createCustomerError: string | null;

  updateCustomerResponse: BaseResponse<ICustomerResponse> | null;
  updateCustomerLoading: boolean;
  updateCustomerError: string | null;

  bills: ICustomerBill[] | null;
  billsLoading: boolean;
  billsError: string | null;

  addStoreCreditResponse: BaseResponse<any> | null;
  addStoreCreditLoading: boolean;
  addStoreCreditError: string | null;

  deleteStoreCreditResponse: BaseResponse<any> | null;
  deleteStoreCreditLoading: boolean;
  deleteStoreCreditError: string | null;

  storeCreditHistoryResponse: BaseResponse<StoreCreditHistoryResponse> | null;
  getStoreCreditHistoryLoading: boolean;
  getStoreCreditHistoryError: string | null;

  addUserNoteResponse: BaseResponse<any> | null;
  addUserNoteLoading: boolean;
  addUserNoteError: string | null;

  updateUserNoteResponse: BaseResponse<any> | null;
  updateUserNoteLoading: boolean;
  updateUserNoteError: string | null;

  customerNotes: Array<CustomerNoteResponse> | null;
  customerNotesLoading: boolean;
  customerNotesError: string | null;

  deleteCustomerNoteResponse: BaseResponse<any> | null;
  deleteCustomerNoteLoading: boolean;
  deleteCustomerNoteError: string | null;

  selectedBill: ICustomerBillsResponse | null;

  customerInsights: IcustomerInsightResponse | null;
  customerInsightsLoading: boolean;
  customerInsightsError: string | null;

  customerLatestBillDetails: IcustomerLatestBillDetailResponse | null;
  customerLatestBillDetailsLoading: boolean;
  customerLatestBillDetailsError: string | null;

  customerCurrentYearServiceUsage: IcustomerServiceUsageResponse[] | null;
  customerCurrentYearServiceUsageLoading: boolean;
  customerCurrentYearServiceUsageError: string | null;

  customerAllTimeServiceUsage: IcustomerServiceUsageResponse[] | null;
  customerAllTimeServiceUsageLoading: boolean;
  customerAllTimeServiceUsageError: string | null;

  customerAvgSpendings: IcustomerAvgSpendingResponse | null;
  customerAvgSpendingsLoading: boolean;
  customerAvgSpendingsError: string | null;

  customerActivitiesByDay: ICustomerActivitiesByDayResponse | null;
  customerActivitiesByDayLoading: boolean;
  customerActivitiesByDayError: string | null;

  customerOutstanding: ICustomerOutstandingResponse | null;
  customerOutstandingLoading: boolean;
  customerOutstandingError: string | null;

  invoiceHistory: InvoiceHistoryResponse[] | null;
  invoiceHistoryLoading: boolean;
  invoiceHistoryError: string | null;
}

const initialState: CustomerState = {
  customer: null,
  customerLoading: false,
  customerError: null,

  createCustomerResponse: null,
  createCustomerLoading: false,
  createCustomerError: null,

  updateCustomerResponse: null,
  updateCustomerLoading: false,
  updateCustomerError: null,

  customerCartInfo: null,
  customerCartInfoLoading: false,
  customerCartInfoError: null,

  bills: [],
  billsLoading: false,
  billsError: null,

  addStoreCreditResponse: null,
  addStoreCreditLoading: false,
  addStoreCreditError: null,

  deleteStoreCreditResponse: null,
  deleteStoreCreditLoading: false,
  deleteStoreCreditError: null,

  storeCreditHistoryResponse: null,
  getStoreCreditHistoryLoading: false,
  getStoreCreditHistoryError: null,

  addUserNoteResponse: null,
  addUserNoteLoading: false,
  addUserNoteError: null,

  updateUserNoteResponse: null,
  updateUserNoteLoading: false,
  updateUserNoteError: null,

  customerNotes: [],
  customerNotesLoading: false,
  customerNotesError: null,

  deleteCustomerNoteResponse: null,
  deleteCustomerNoteLoading: false,
  deleteCustomerNoteError: null,

  selectedBill: null,

  customerInsights: null,
  customerInsightsLoading: false,
  customerInsightsError: null,

  customerLatestBillDetails: null,
  customerLatestBillDetailsLoading: false,
  customerLatestBillDetailsError: null,

  customerCurrentYearServiceUsage: null,
  customerCurrentYearServiceUsageLoading: false,
  customerCurrentYearServiceUsageError: null,

  customerAllTimeServiceUsage: null,
  customerAllTimeServiceUsageLoading: false,
  customerAllTimeServiceUsageError: null,
  customerAvgSpendings: null,
  customerAvgSpendingsLoading: false,
  customerAvgSpendingsError: null,

  customerActivitiesByDay: null,
  customerActivitiesByDayLoading: false,
  customerActivitiesByDayError: null,

  customerOutstanding: null,
  customerOutstandingLoading: false,
  customerOutstandingError: null,

  invoiceHistory: null,
  invoiceHistoryLoading: false,
  invoiceHistoryError: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    clearCustomer: (state: CustomerState) => {
      state.customerCartInfo = null;
      state.customer = null;
      state.customerError = null;
      state.customerLoading = false;
    },
    clearCustomerCartInfo: (state: CustomerState) => {
      state.customerCartInfo = null;
      state.customerCartInfoError = null;
      state.customerCartInfoLoading = false;
    },
    setSelectedBill: (state, action) => {
      state.selectedBill = action.payload;
    },
    clearCustomerNotes: (state: CustomerState) => {
      state.customerNotes = null;
      state.customerNotesError = null;
      state.customerNotesLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerByIdAction.rejected, (state: CustomerState) => {
      state.customer = null;
      state.customerError = "Customer fetching error";
      state.customerLoading = false;
    });
    builder.addCase(getCustomerByIdAction.pending, (state: CustomerState) => {
      state.customer = null;
      state.customerError = null;
      state.customerLoading = true;
    });
    builder.addCase(
      getCustomerByIdAction.fulfilled,
      (state: CustomerState, action) => {
        state.customer = action.payload;
        state.customerError = null;
        state.customerLoading = false;
      }
    );

    builder.addCase(
      getCustomerCartInfoByIdAction.rejected,
      (state: CustomerState) => {
        state.customerCartInfo = null;
        state.customerCartInfoError = "Customer's cart info fetching error";
        state.customerCartInfoLoading = false;
      }
    );
    builder.addCase(
      getCustomerCartInfoByIdAction.pending,
      (state: CustomerState) => {
        state.customerCartInfo = null;
        state.customerCartInfoError = null;
        state.customerCartInfoLoading = true;
      }
    );
    builder.addCase(
      getCustomerCartInfoByIdAction.fulfilled,
      (state: CustomerState, action) => {
        state.customerCartInfo = action.payload;
        state.customerCartInfoError = null;
        state.customerCartInfoLoading = false;
      }
    );

    builder.addCase(
      getCustomerBillHistoryAction.rejected,
      (state: CustomerState) => {
        state.bills = null;
        state.billsError = "Customer's bills fetching error";
        state.billsLoading = false;
      }
    );
    builder.addCase(
      getCustomerBillHistoryAction.pending,
      (state: CustomerState) => {
        state.bills = null;
        state.billsError = null;
        state.billsLoading = true;
      }
    );
    builder.addCase(
      getCustomerBillHistoryAction.fulfilled,
      (state: CustomerState, action) => {
        state.bills = action.payload.content;
        state.billsError = null;
        state.billsLoading = false;
      }
    );

    builder.addCase(addStoreCreditAction.rejected, (state: CustomerState) => {
      state.addStoreCreditResponse = null;
      state.addStoreCreditError = "Store credit adding error";
      state.addStoreCreditLoading = false;
    });
    builder.addCase(addStoreCreditAction.pending, (state: CustomerState) => {
      state.addStoreCreditResponse = null;
      state.addStoreCreditError = null;
      state.addStoreCreditLoading = true;
    });
    builder.addCase(
      addStoreCreditAction.fulfilled,
      (state: CustomerState, action) => {
        state.addStoreCreditResponse = action.payload;
        state.addStoreCreditError = null;
        state.addStoreCreditLoading = false;
      }
    );

    builder.addCase(
      deleteStoreCreditAction.rejected,
      (state: CustomerState) => {
        state.deleteStoreCreditResponse = null;
        state.deleteStoreCreditError = "Store credit deleting error";
        state.deleteStoreCreditLoading = false;
      }
    );
    builder.addCase(deleteStoreCreditAction.pending, (state: CustomerState) => {
      state.deleteStoreCreditResponse = null;
      state.deleteStoreCreditError = null;
      state.deleteStoreCreditLoading = true;
    });
    builder.addCase(
      deleteStoreCreditAction.fulfilled,
      (state: CustomerState, action) => {
        state.deleteStoreCreditResponse = action.payload as any;
        state.deleteStoreCreditError = null;
        state.deleteStoreCreditLoading = false;
      }
    );

    builder.addCase(
      getStoreCreditHistoryAction.rejected,
      (state: CustomerState) => {
        state.storeCreditHistoryResponse = null;
        state.getStoreCreditHistoryError =
          "Store credit history fetching error";
        state.getStoreCreditHistoryLoading = false;
      }
    );
    builder.addCase(
      getStoreCreditHistoryAction.pending,
      (state: CustomerState) => {
        state.storeCreditHistoryResponse = null;
        state.getStoreCreditHistoryError = null;
        state.getStoreCreditHistoryLoading = true;
      }
    );
    builder.addCase(
      getStoreCreditHistoryAction.fulfilled,
      (state: CustomerState, action) => {
        state.storeCreditHistoryResponse = action.payload;
        state.getStoreCreditHistoryError = null;
        state.getStoreCreditHistoryLoading = false;
      }
    );

    builder.addCase(addUserNoteAction.rejected, (state: CustomerState) => {
      state.addUserNoteResponse = null;
      state.addUserNoteError = "User note adding error";
      state.addUserNoteLoading = false;
    });
    builder.addCase(addUserNoteAction.pending, (state: CustomerState) => {
      state.addUserNoteResponse = null;
      state.addUserNoteError = null;
      state.addUserNoteLoading = true;
    });
    builder.addCase(
      addUserNoteAction.fulfilled,
      (state: CustomerState, action) => {
        state.addUserNoteResponse = action.payload;
        state.addUserNoteError = null;
        state.addUserNoteLoading = false;
      }
    );

    builder.addCase(updateUserNoteAction.rejected, (state: CustomerState) => {
      state.updateUserNoteResponse = null;
      state.updateUserNoteError = "Update user note failed";
      state.updateUserNoteLoading = false;
    });
    builder.addCase(updateUserNoteAction.pending, (state: CustomerState) => {
      state.updateUserNoteResponse = null;
      state.updateUserNoteError = null;
      state.updateUserNoteLoading = true;
    });
    builder.addCase(
      updateUserNoteAction.fulfilled,
      (state: CustomerState, action) => {
        state.updateUserNoteResponse = action.payload;
        state.updateUserNoteError = null;
        state.updateUserNoteLoading = false;
      }
    );

    builder.addCase(getUserNotesByIdAction.rejected, (state: CustomerState) => {
      state.customerNotes = null;
      state.customerNotesError = "User notes fetching error";
      state.customerNotesLoading = false;
    });
    builder.addCase(getUserNotesByIdAction.pending, (state: CustomerState) => {
      state.customerNotes = null;
      state.customerNotesError = null;
      state.customerNotesLoading = true;
    });
    builder.addCase(
      getUserNotesByIdAction.fulfilled,
      (state: CustomerState, action) => {
        state.customerNotes = action.payload;
        state.customerNotesError = null;
        state.customerNotesLoading = false;
      }
    );

    builder.addCase(deleteUserNoteAction.rejected, (state: CustomerState) => {
      state.deleteCustomerNoteResponse = null;
      state.deleteCustomerNoteError = "Delete user note failed";
      state.deleteCustomerNoteLoading = false;
    });
    builder.addCase(deleteUserNoteAction.pending, (state: CustomerState) => {
      state.deleteCustomerNoteResponse = null;
      state.deleteCustomerNoteError = null;
      state.deleteCustomerNoteLoading = true;
    });
    builder.addCase(
      deleteUserNoteAction.fulfilled,
      (state: CustomerState, action) => {
        state.deleteCustomerNoteResponse = action.payload;
        state.deleteCustomerNoteError = null;
        state.deleteCustomerNoteLoading = false;
      }
    );

    builder
      .addCase(getAllCustomerInsightsAction.pending, (state) => {
        state.customerInsights = null;
        state.customerInsightsLoading = true;
        state.customerInsightsError = null;
      })
      .addCase(getAllCustomerInsightsAction.fulfilled, (state, action) => {
        state.customerInsightsError = null;
        state.customerInsightsLoading = false;
        state.customerInsights = (action.payload as any).body;
      })
      .addCase(getAllCustomerInsightsAction.rejected, (state, action) => {
        state.customerInsights = null;
        state.customerInsightsLoading = false;
        state.customerInsightsError =
          (action.payload as any)?.message || "something went wrong";
      });

    builder
      .addCase(getAllCustomerLatestBillDetailsAction.pending, (state) => {
        state.customerLatestBillDetails = null;
        state.customerLatestBillDetailsLoading = true;
        state.customerLatestBillDetailsError = null;
      })
      .addCase(
        getAllCustomerLatestBillDetailsAction.fulfilled,
        (state, action) => {
          state.customerLatestBillDetailsError = null;
          state.customerLatestBillDetailsLoading = false;
          state.customerLatestBillDetails = (action.payload as any).body;
        }
      )
      .addCase(
        getAllCustomerLatestBillDetailsAction.rejected,
        (state, action) => {
          state.customerLatestBillDetails = null;
          state.customerLatestBillDetailsLoading = false;
          state.customerLatestBillDetailsError =
            (action.payload as any)?.message || "something went wrong";
        }
      );

    builder
      .addCase(getAllCustomerCurrentYearServiceUsageAction.pending, (state) => {
        state.customerCurrentYearServiceUsage = null;
        state.customerCurrentYearServiceUsageLoading = true;
        state.customerCurrentYearServiceUsageError = null;
      })
      .addCase(
        getAllCustomerCurrentYearServiceUsageAction.fulfilled,
        (state, action) => {
          state.customerCurrentYearServiceUsageError = null;
          state.customerCurrentYearServiceUsageLoading = false;
          state.customerCurrentYearServiceUsage = (action.payload as any).body;
        }
      )
      .addCase(
        getAllCustomerCurrentYearServiceUsageAction.rejected,
        (state, action) => {
          state.customerCurrentYearServiceUsage = null;
          state.customerCurrentYearServiceUsageLoading = false;
          state.customerCurrentYearServiceUsageError =
            (action.payload as any)?.message || "something went wrong";
        }
      );

    builder
      .addCase(getAllCustomerAllTimeServiceUsageAction.pending, (state) => {
        state.customerAllTimeServiceUsage = null;
        state.customerAllTimeServiceUsageLoading = true;
        state.customerAllTimeServiceUsageError = null;
      })
      .addCase(
        getAllCustomerAllTimeServiceUsageAction.fulfilled,
        (state, action) => {
          state.customerAllTimeServiceUsageError = null;
          state.customerAllTimeServiceUsageLoading = false;
          state.customerAllTimeServiceUsage = (action.payload as any).body;
        }
      )
      .addCase(
        getAllCustomerAllTimeServiceUsageAction.rejected,
        (state, action) => {
          state.customerAllTimeServiceUsage = null;
          state.customerAllTimeServiceUsageLoading = false;
          state.customerAllTimeServiceUsageError =
            (action.payload as any)?.message || "something went wrong";
        }
      );

    builder
      .addCase(getAllCustomerAvgSpendingsAction.pending, (state) => {
        state.customerAvgSpendings = null;
        state.customerAvgSpendingsLoading = true;
        state.customerAvgSpendingsError = null;
      })
      .addCase(getAllCustomerAvgSpendingsAction.fulfilled, (state, action) => {
        state.customerAvgSpendingsError = null;
        state.customerAvgSpendingsLoading = false;
        state.customerAvgSpendings = (action.payload as any).body;
      })
      .addCase(getAllCustomerAvgSpendingsAction.rejected, (state, action) => {
        state.customerAvgSpendings = null;
        state.customerAvgSpendingsLoading = false;
        state.customerAvgSpendingsError =
          (action.payload as any)?.message || "something went wrong";
      });
    builder.addCase(getCustomerActivitiesByDayAction.pending, (state) => {
      state.customerActivitiesByDay = null;
      state.customerActivitiesByDayLoading = true;
      state.customerActivitiesByDayError = null;
    });
    builder.addCase(
      getCustomerActivitiesByDayAction.fulfilled,
      (state, action) => {
        state.customerActivitiesByDay = (action.payload as any).body;
        state.customerActivitiesByDayLoading = false;
        state.customerActivitiesByDayError = null;
      }
    );
    builder.addCase(
      getCustomerActivitiesByDayAction.rejected,
      (state, action) => {
        state.customerActivitiesByDay = null;
        state.customerActivitiesByDayLoading = false;
        state.customerActivitiesByDayError =
          (action.payload as any)?.message || "something went wrong";
      }
    );

    builder.addCase(getCustomerOutstandingAction.pending, (state) => {
      state.customerOutstanding = null;
      state.customerOutstandingLoading = true;
      state.customerOutstandingError = null;
    });
    builder.addCase(getCustomerOutstandingAction.fulfilled, (state, action) => {
      state.customerOutstanding = (action.payload as any).body;
      state.customerOutstandingLoading = false;
      state.customerOutstandingError = null;
    });
    builder.addCase(getCustomerOutstandingAction.rejected, (state, action) => {
      state.customerOutstanding = null;
      state.customerOutstandingLoading = false;
      state.customerOutstandingError =
        (action.payload as any)?.message || "something went wrong";
    });

    builder.addCase(getInvoiceHistoryAction.pending, (state) => {
      state.invoiceHistory = null;
      state.invoiceHistoryLoading = true;
      state.invoiceHistoryError = null;
    });

    builder.addCase(getInvoiceHistoryAction.fulfilled, (state, action) => {
      state.invoiceHistory = (
        action.payload as InvoiceHistoryResponse[][]
      ).flat();
      state.invoiceHistoryLoading = false;
      state.invoiceHistoryError = null;
    });

    builder.addCase(getInvoiceHistoryAction.rejected, (state) => {
      state.invoiceHistory = null;
      state.invoiceHistoryLoading = false;
      state.invoiceHistoryError = "Invoice history fetching error";
    });
  },
});

export default customerSlice.reducer;
export const CustomerSliceAction = customerSlice.actions;
