import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Colors } from "../../../configs";
import { CustomButton, PosLayout } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import { useTranslation } from "../../../util/translation-provider";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  ILocalStationCashier,
  INewCashierResponse,
  IOpenCashierResponse,
} from "../../../typings/interfaces/responses/cashierStationResponses";
import {
  closeStationCashierAction,
  getAllStationSummaryAction,
  getStationCashierSummaryAction,
  getStationSummaryAction,
} from "../../../redux/actions/cashierStationActions";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { setActiveStationCashier } from "../../../redux/slice/cashierStationSlice";
// import {
//   IStation,
//   updateStation,
// } from "../../../redux/slice/CashRegisterSlice";

const CloseOutPage = () => {
  const [temporaryStation, setTemporaryStation] = useState<any | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [activeStation, setActiveStation] = useState<any | null>(null);
  const [temporaryCloseOut, setTemporaryCloseOut] = useState<{
    billTotal: number;
    coins: {
      "1c": number;
      "5c": number;
      "10c": number;
      "25c": number;
    };
  } | null>(null);

  // const { stationList } = useAppSelector((state) => state.cashRegister);
  // const { activeStation: activeCashier } = useAppSelector(
  //   (state) => state.cashier
  // );
  const {
    getStationCashierSummaryResponse,
    getStationSummaryResponse,
    closeStationCashierLoading,
    closeStationCashierError,
    closeStationCashierResponse,
    getAllStationSummaryResponse,
  } = useAppSelector((state) => state.cashierStation);
  const navigate = useNavigate();
  const translations = useTranslation();
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const parsedCashier: INewCashierResponse | null = JSON.parse(
    localStorage.getItem("cashier") ?? "null"
  );
  const parsedCashierSession: ILocalStationCashier | null = JSON.parse(
    localStorage.getItem("activeCashierSession") ?? "null"
  );
  useEffect(() => {
    if (parsedCashierSession) {
      dispatch(
        getStationCashierSummaryAction(parsedCashierSession.cashierPointId)
      );
      dispatch(getStationSummaryAction(parsedCashierSession.cashierPointId));
      if (parsedCashierSession.status === "closed") {
        setIsSubmitted(true);
      }
    }
    if (!getAllStationSummaryResponse) {
      dispatch(getAllStationSummaryAction());
    }
  }, []);

  // useEffect(() => {
  //   const p = localStorage.getItem("activeStation");
  //   if (p) {
  //     setActiveStation(JSON.parse(p));
  //     if (activeStation?.status === "Closed") {
  //       setIsSubmitted(true);
  //     }
  //   } else {
  //     setActiveStation({
  //       id: activeCashier?.id ?? 1,
  //       name: activeCashier?.name ?? "Cashier 1",
  //       cash: null,
  //       closingCash: null,
  //       totalSales: 100,
  //       registerTotal: 200,
  //       refundTotal: 5,
  //       extraAdditions: [],
  //       extraDeductions: [],
  //       status: null,
  //     });
  //     localStorage.setItem(
  //       "activeStation",
  //       JSON.stringify({
  //         id: activeCashier?.id ?? 1,
  //         name: activeCashier?.name ?? "Cashier 1",
  //         cash: null,
  //         closingCash: null,
  //         totalSales: 100,
  //         registerTotal: 200,
  //         refundTotal: 5,
  //         extraAdditions: [],
  //         extraDeductions: [],
  //         status: null,
  //       })
  //     );
  //   }
  // }, [activeCashier]);

  const calculateTotalOpeningBalance = () => {
    if (getStationCashierSummaryResponse) {
      const total =
        getStationCashierSummaryResponse.openingOneDollarBills * 1 +
        getStationCashierSummaryResponse.openingFiveDollarBills * 5 +
        getStationCashierSummaryResponse.openingTenDollarBills * 10 +
        getStationCashierSummaryResponse.openingTwentyDollarBills * 20 +
        getStationCashierSummaryResponse.openingOneCent * 0.01 +
        getStationCashierSummaryResponse.openingFiveCents * 0.05 +
        getStationCashierSummaryResponse.openingTenCents * 0.1 +
        getStationCashierSummaryResponse.openingTwentyFiveCents * 0.25;
      return total;
    }
    return 0;
  };

  const calculateClosingCoinsTotal = () => {
    if (temporaryCloseOut) {
      const total =
        (temporaryCloseOut?.coins["1c"] ?? 0) * 1 +
        (temporaryCloseOut?.coins["5c"] ?? 0) * 5 +
        (temporaryCloseOut?.coins["10c"] ?? 0) * 10 +
        (temporaryCloseOut?.coins["25c"] ?? 0) * 25;
      return total;
    }
    return 0;
  };
  const calculateOpeningBillsTotal = () => {
    if (getStationCashierSummaryResponse) {
      const total =
        getStationCashierSummaryResponse.openingOneDollarBills * 1 +
        getStationCashierSummaryResponse.openingFiveDollarBills * 5 +
        getStationCashierSummaryResponse.openingTenDollarBills * 10 +
        getStationCashierSummaryResponse.openingTwentyDollarBills * 20;
      return total;
    }
    return 0;
  };
  const calculateOpeningCoinsTotal = () => {
    if (getStationCashierSummaryResponse) {
      const total =
        getStationCashierSummaryResponse.openingOneCent * 0.01 +
        getStationCashierSummaryResponse.openingFiveCents * 0.05 +
        getStationCashierSummaryResponse.openingTenCents * 0.1 +
        getStationCashierSummaryResponse.openingTwentyFiveCents * 0.25;
      return total;
    }
    return 0;
  };
  const onClosingCashChange = (
    cashType: "billTotal" | "1c" | "5c" | "10c" | "25c",
    amount: number
  ) => {
    // const coinValues = {
    //     ...(cashType.endsWith("c") ? { [cashType]: amount } : {}),
    //   };
    //   const billTotal =
    //     cashType === "billTotal"
    //       ? amount
    //       : temporaryStation?.closingCash?.billTotal ?? 0;

    //   const updatedCashValues = {
    //     billTotal: billTotal,
    //     coins: {
    //       ...(temporaryStation?.closingCash?.coins ?? {
    //         "1c": 0,
    //         "5c": 0,
    //         "10c": 0,
    //         "25c": 0,
    //       }),
    //       ...coinValues,
    //     },
    //   };
    //   const updatedStation: any = {
    //     ...temporaryStation,
    //     closingCash: updatedCashValues,
    //   };
    //   // dispatch(updateStation(updatedStation));
    //   setTemporaryStation(updatedStation);
    const tempTemporaryCloseOut = temporaryCloseOut
      ? temporaryCloseOut
      : {
          billTotal: 0,
          coins: {
            "1c": 0,
            "5c": 0,
            "10c": 0,
            "25c": 0,
          },
        };
    if (cashType === "billTotal") {
      setTemporaryCloseOut({
        ...tempTemporaryCloseOut,
        billTotal: amount,
      });
    } else {
      setTemporaryCloseOut({
        ...tempTemporaryCloseOut,
        coins: {
          ...tempTemporaryCloseOut.coins,
          [cashType]: amount,
        },
      });
    }
  };

  useEffect(() => {
    if (parsedCashierSession) {
      dispatch(getStationSummaryAction(parsedCashierSession.cashierPointId));
      dispatch(
        getStationCashierSummaryAction(parsedCashierSession.cashierPointId)
      );
    }
  }, []);

  const buttonList = [
    {
      label: "Opening Balance",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "Add Cash",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "Remove Cash",
      disabled: true,
      onClick: () => {},
    },
    {
      label: "Close Out",
      disabled: true,
      onClick: () => {},
    },
    {
      label: closeStationCashierLoading ? (
        <CircularProgress sx={{ color: Colors.WHITE }} />
      ) : (
        "SUBMIT"
      ),
      disabled:
        closeStationCashierLoading ||
        !(
          getStationSummaryResponse?.stationStatus === "open" &&
          calculateClosingCoinsTotal() + (temporaryCloseOut?.billTotal ?? 0) > 0
        ),
      onClick: () => {
        if (parsedCashierSession)
          dispatch(
            closeStationCashierAction({
              stationAccessId: parsedCashierSession.stationAccessId,
              stationBalanceDto: {
                billTotal: temporaryCloseOut?.billTotal ?? 0,
                oneCent: temporaryCloseOut?.coins["1c"] ?? 0,
                fiveCents: temporaryCloseOut?.coins["5c"] ?? 0,
                tenCents: temporaryCloseOut?.coins["10c"] ?? 0,
                twentyFiveCents: temporaryCloseOut?.coins["25c"] ?? 0,
              },
            })
          );
      },
    },
  ];

  useEffect(() => {
    if (closeStationCashierError) {
      snackbar.showSnackbar(closeStationCashierError, "error");
    }
  }, [closeStationCashierError]);

  useEffect(() => {
    if (closeStationCashierResponse) {
      setIsSubmitted(true);
      if (parsedCashier) {
        dispatch(getStationCashierSummaryAction(parsedCashier.id));
        dispatch(getStationSummaryAction(parsedCashier.id));
      }
      if (parsedCashierSession) {
        localStorage.setItem(
          "activeCashierSession",
          JSON.stringify({
            ...parsedCashierSession,
            status: "closed",
          })
        );
        dispatch(
          setActiveStationCashier({ ...parsedCashierSession, status: "closed" })
        );
      }
    }
  }, [closeStationCashierResponse]);

  return (
    <PosLayout>
      <>
        <PosInnerPageLayout title={""}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "2.85714286rem",
                    lineHeight: "3.35714286rem",
                    color: Colors.TEXT_GRAY_DARK,
                    fontWeight: "700",
                    marginLeft: "6rem",
                  }}
                >
                  {translations.companyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.71428571rem",
                    lineHeight: "1.92857143rem",
                    color: Colors.TEXT_GRAY_DARK,
                    marginLeft: "6rem",
                  }}
                >
                  Cash Register
                </Typography>
              </Box>

              <Box>
                <CustomButton
                  onClick={() => navigate("/pos/lobby")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                    marginRight: "1rem",
                  }}
                >
                  Lobby
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/view-all")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  View All
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/day-end")}
                  // disabled={stationList.some(
                  //   (station) => station.status !== "Closed"
                  // )}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    fontSize: "1.428571428571429rem",
                    borderRadius: "0.7142857142857143rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.PRIMARY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    marginTop: "6rem",
                    marginRight: "7.5rem",
                  }}
                  disabled={
                    getAllStationSummaryResponse?.some(
                      (station) => station.stationStatus !== "closed"
                    ) || !getAllStationSummaryResponse
                  }
                >
                  Day End
                </CustomButton>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                margin: "2rem",
                width: "120.7142857142857rem",
                height: "52.92857142857143rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "0.7142857142857143rem",
                padding: "2rem",
                marginTop: "-1rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: Colors.MAIN_GRAY,
                    fontSize: "2.285714285714286rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Close Out -{parsedCashier?.name ?? "Undefined Station"}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                  }}
                >
                  {/* State 01: Station 01 - Opening Table*/}
                  <Box
                    sx={{
                      width: "40rem",
                      padding: "0.3rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        height: "6rem",
                        textAlign: "center",
                        paddingTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        borderRadius:
                          "0.7142857142857143rem 0.7142857142857143rem 0 0",
                      }}
                    >
                      {parsedCashier?.name ?? "Undefined Station"} - Opening
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.SECONDARY,
                          color: Colors.WHITE,
                          padding: "1rem",
                          textAlign: "center",
                          paddingTop: "1.5rem",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        Bills
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.SECONDARY,
                          color: Colors.WHITE,
                          padding: "1rem",
                          textAlign: "center",
                          paddingTop: "1.5rem",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        Coins
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: "0.3rem",
                        maxHeight: "22rem",
                        padding: "0",
                        backgroundColor: Colors.BACKSHADE_GRAY,
                      }}
                    >
                      {/* {denominations.map((item, index) => (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            {item.dollar}
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            x {bills[item.keyDollar] || 0}
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            {item.cent}
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "1.428571428571429rem",
                            }}
                          >
                            x {coins[item.keyCent] || 0}
                          </Box>
                        </React.Fragment>
                      ))} */}
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $1
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingOneDollarBills ||
                            0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢1
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingOneCent ||
                            0}
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $5
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingFiveDollarBills ||
                            0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢5
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingFiveCents ||
                            0}
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $10
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingTenDollarBills ||
                            0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢10
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingTenCents ||
                            0}
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          $20
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingTwentyDollarBills ||
                            0}
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢25
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          x{" "}
                          {getStationCashierSummaryResponse?.openingTwentyFiveCents ||
                            0}
                        </Box>
                      </>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Total
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        $ {calculateOpeningBillsTotal()}
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.MAIN_GRAY,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Total
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                        }}
                      >
                        ¢ {calculateOpeningCoinsTotal()}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.MAIN_GRAY,
                        color: Colors.WHITE,
                        height: "5rem",
                        padding: "1rem",
                        textAlign: "left",
                        fontSize: "1.428571428571429rem",
                        borderRadius:
                          "0 0 0.7142857142857143rem 0.7142857142857143rem",
                        paddingTop: "1.5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Total Opening Balance:</span>
                      <span>
                        $
                        {calculateOpeningBillsTotal() +
                          calculateOpeningCoinsTotal()}
                      </span>
                    </Box>
                  </Box>

                  {/* State 01: Station 01 - Closing Table -------------------*/}
                  <Box
                    sx={{
                      width: "40rem",
                      padding: "0.3rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        height: "6rem",
                        textAlign: "center",
                        paddingTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        borderRadius:
                          "0.7142857142857143rem 0.7142857142857143rem 0 0",
                      }}
                    >
                      {parsedCashier?.name ?? "Undefined Station"} - Closing
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.BLACK,
                          padding: "1rem",
                          textAlign: "left",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Total Bill Amount:
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          border: isSubmitted
                            ? "none"
                            : `1px solid ${Colors.MAIN_GRAY}`,
                          color: Colors.BLACK,
                          height: "5rem",
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "3.2rem",
                          }}
                        >
                          $
                        </span>
                        <input
                          type="text"
                          style={{
                            border: "none",
                            outline: "none",
                            width: "100%",
                            textAlign: "center",
                            background: "transparent",
                            fontSize: "1.3rem",
                          }}
                          disabled={isSubmitted}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );

                            onClosingCashChange(
                              "billTotal",
                              Number(sanitizedValue)
                            );
                          }}
                          value={`${temporaryCloseOut?.billTotal ?? 0}`}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 2fr",
                        gap: "0.3rem",
                        marginTop: "0rem",
                      }}
                    >
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢1
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                            border: isSubmitted
                              ? "none"
                              : `1px solid ${Colors.MAIN_GRAY}`,
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                              fontSize: "1.3rem",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange("1c", Number(sanitizedValue));
                            }}
                            disabled={isSubmitted}
                            value={temporaryCloseOut?.coins["1c"] ?? 0}
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                              fontSize: "1.3rem",
                            }}
                            value={`${
                              (temporaryCloseOut?.coins["1c"] ?? 0) * 1
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢5
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                            border: isSubmitted
                              ? "none"
                              : `1px solid ${Colors.MAIN_GRAY}`,
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                              fontSize: "1.3rem",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange("5c", Number(sanitizedValue));
                            }}
                            disabled={isSubmitted}
                            value={temporaryCloseOut?.coins["5c"] ?? 0}
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                              fontSize: "1.3rem",
                            }}
                            value={`${
                              (temporaryCloseOut?.coins["5c"] ?? 0) * 5
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢10
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                            border: isSubmitted
                              ? "none"
                              : `1px solid ${Colors.MAIN_GRAY}`,
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                              fontSize: "1.3rem",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange(
                                "10c",
                                Number(sanitizedValue)
                              );
                            }}
                            disabled={isSubmitted}
                            value={temporaryCloseOut?.coins["10c"] ?? 0}
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              fontSize: "1.3rem",
                            }}
                            value={`${
                              (temporaryCloseOut?.coins["10c"] ?? 0) * 10
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                      <>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢25
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                            border: isSubmitted
                              ? "none"
                              : `1px solid ${Colors.MAIN_GRAY}`,
                          }}
                        >
                          x
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              background: "transparent",
                              fontSize: "1.3rem",
                            }}
                            onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              onClosingCashChange(
                                "25c",
                                Number(sanitizedValue)
                              );
                            }}
                            disabled={isSubmitted}
                            value={temporaryCloseOut?.coins["25c"] ?? 0}
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.WHITE,
                            height: "5rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.428571428571429rem",
                          }}
                        >
                          ¢
                          <input
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "50%",
                              textAlign: "center",
                              fontSize: "1.3rem",
                            }}
                            value={`${
                              (temporaryCloseOut?.coins["25c"] ?? 0) * 25
                            }`}
                            disabled={true}
                          />
                        </Box>
                      </>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        height: "5rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.BLACK,
                          height: "5rem",
                          padding: "1rem",
                          textAlign: "left",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Total Coins Amount:
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: Colors.WHITE,
                          color: Colors.BLACK,
                          padding: "1rem",
                          textAlign: "center",
                          fontSize: "1.428571428571429rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "3.2rem",
                          }}
                        >
                          ¢
                        </span>
                        <input
                          type="text"
                          style={{
                            border: "none",
                            outline: "none",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.3rem",
                          }}
                          // onChange={(e) => {
                          //   const sanitizedValue = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          //
                          //   handleClosingInputChange(e, "coins", "total");
                          // }}
                          disabled={true}
                          value={`${calculateClosingCoinsTotal()}`}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.MAIN_GRAY,
                        color: Colors.WHITE,
                        height: "5rem",
                        padding: "1rem",
                        textAlign: "left",
                        fontSize: "1.428571428571429rem",
                        borderRadius:
                          "0 0 0.7142857142857143rem 0.7142857142857143rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.1rem",
                      }}
                    >
                      <span>Total Closing Balance:</span>
                      <span>
                        $
                        {(temporaryCloseOut?.billTotal ?? 0) +
                          calculateClosingCoinsTotal() * 0.01}
                      </span>
                    </Box>
                  </Box>

                  {/* State 02: Balance Summary Table */}
                  {isSubmitted && (
                    <Box
                      sx={{
                        width: "40rem",
                        backgroundColor: Colors.BACKSHADE_GRAY,
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "0.7142857142857143rem",
                        overflow: "hidden",
                        marginTop: "0.3rem",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: Colors.SECONDARY,
                          color: Colors.WHITE,
                          height: "6rem",
                          textAlign: "center",
                          paddingTop: "2rem",
                          fontSize: "1.714285714285714rem",
                          borderRadius:
                            "0.7142857142857143rem 0.7142857142857143rem 0 0",
                        }}
                      >
                        Balance Summary
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "0.3rem",
                          padding: "0.3rem",
                        }}
                      >
                        {[
                          {
                            label: "Opening Balance",
                            value:
                              calculateOpeningBillsTotal() +
                              calculateOpeningCoinsTotal(),
                          },
                          {
                            label: "Cash",
                            value:
                              getStationCashierSummaryResponse?.cashPaymentsTotal ??
                              0,
                          },
                          {
                            label: "Credit Card",
                            value:
                              getStationCashierSummaryResponse?.cardPaymentsTotal ??
                              0,
                          },
                          {
                            label: "Check",
                            value:
                              getStationCashierSummaryResponse?.chequePaymentsTotal ??
                              0,
                          },
                          {
                            label: "Total Sales",
                            value:
                              getStationCashierSummaryResponse?.totalSales ?? 0,
                          },
                          {
                            label: "Register Total",
                            value:
                              getStationSummaryResponse?.registerTotal ?? 0,
                          },
                          {
                            label: "Closing Balance",
                            value:
                              getStationCashierSummaryResponse?.closingBalanceTotal ??
                              0,
                          },
                        ].map((row, index) => (
                          <React.Fragment key={index}>
                            <Box
                              sx={{
                                backgroundColor: Colors.WHITE,
                                color: Colors.MAIN_GRAY,
                                padding: "1rem",
                                textAlign: "left",
                                fontSize: "1.428571428571429rem",
                                display: "flex",
                                alignItems: "center",
                                height: "5rem",
                                paddingLeft: "3rem",
                                borderRadius:
                                  row.label === "Closing Balance"
                                    ? "0 0 0 0.7142857142857143rem"
                                    : "0",
                              }}
                            >
                              {row.label}
                            </Box>

                            <Box
                              sx={{
                                backgroundColor: Colors.WHITE,
                                height: "5rem",
                                fontSize: "1.428571428571429rem",
                                color: Colors.MAIN_GRAY,
                                textAlign: "right",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                paddingRight: "7rem",
                                borderRadius:
                                  row.label === "Closing Balance"
                                    ? "0 0 0.7142857142857143rem 0"
                                    : "0",
                              }}
                            >
                              {row.value}
                            </Box>
                          </React.Fragment>
                        ))}
                      </Box>
                    </Box>
                  )}

                  {/* Button Section */}
                  {!isSubmitted && (
                    <Box
                      sx={{
                        width: "30rem",
                        backgroundColor: Colors.BACKSHADE_GRAY,
                        display: "flex",
                        flexDirection: "column",
                        gap: "2.5rem",
                        borderRadius: "0.7142857142857143rem",
                        marginTop: "0.5rem",
                      }}
                    >
                      {buttonList.map((button, index) => (
                        <CustomButton
                          key={index}
                          sx={{
                            width: "30rem",
                            height: "6.571428571428571rem",
                            backgroundColor:
                              index === 4 ? Colors.PRIMARY : Colors.WHITE,
                            color: index === 4 ? Colors.WHITE : Colors.PRIMARY,
                            fontSize:
                              index === 4
                                ? "2.285714285714286rem"
                                : "1.428571428571429rem",
                            borderRadius: "0.7142857142857143rem",
                            border: "none",
                            textTransform: "none",
                            fontWeight: "bold",
                          }}
                          disabled={button.disabled}
                          onClick={button.onClick}
                        >
                          {button.label}
                        </CustomButton>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </PosInnerPageLayout>
      </>
    </PosLayout>
  );
};

export default CloseOutPage;
