import { FC, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Colors } from "../../../configs";
import { CustomButton, CustomTextBox } from "../../atoms";
import { CustomDrawer } from "../../atoms/custom-drawer/custom-drawer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  CreateInstructionAction,
  updateInstructionAction,
  getAllInstructionsAction,
} from "../../../redux/actions/instructionsAction";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

const schema = Yup.object().shape({
  instruction: Yup.string().required("Instruction description is required"),
});

type InstructionFormData = Yup.InferType<typeof schema>;

export interface ICreateInstructionsDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  populateFor?: { id?: number; instruction: string };
}

export const AddInstructionDrawer: FC<ICreateInstructionsDrawerProps> = ({
  open,
  toggleDrawer,
  populateFor,
}) => {
  const dispatch = useAppDispatch();
  const {
    addInstructionLoading,
    updateInstructionLoading,
    deleteInstructionLoading,
    instructionsLoading,
  } = useAppSelector((state) => state.instruction);

  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
  //   "success"
  // );

  const { control, handleSubmit, reset } = useForm<InstructionFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      instruction: "",
    },
  });
  const snackBar = useSnackbarContext();

  useEffect(() => {
    if (open) {
      reset({ instruction: populateFor?.instruction || "" });
    }
  }, [open, populateFor, reset]);

  const handleSaveInstruction = async (data: InstructionFormData) => {
    const payload = {
      storeId: 1,
      merchantId: 1,
      instruction: data.instruction,
    };

    try {
      if (populateFor?.id) {
        await dispatch(
          updateInstructionAction({ ...payload, id: populateFor.id })
        ).unwrap();
        snackBar.showSnackbar("Instruction updated successfully!", "success");
      } else {
        await dispatch(CreateInstructionAction(payload)).unwrap();
        snackBar.showSnackbar("Instruction added successfully!", "success");
      }
      dispatch(getAllInstructionsAction());
      toggleDrawer();
    } catch (error) {
      snackBar.showSnackbar(
        "Failed to save instruction. Please try again.",
        "error"
      );
    }
  };

  return (
    <CustomDrawer
      isOpen={open}
      toggleDrawer={toggleDrawer}
      sx={{ width: "28.65rem" }}
    >
      <form onSubmit={handleSubmit(handleSaveInstruction)}>
        <Box
          sx={{
            paddingInline: "1.857rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.4rem",
              lineHeight: "1.7rem",
              color: Colors.TEXT_GRAY_DARK,
            }}
          >
            {populateFor ? "Update Instruction" : "Add a New Instruction"}
          </Typography>

          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "0.85rem" }}
          >
            <Typography>Instruction Description</Typography>
            <Controller
              name="instruction"
              control={control}
              render={({ field, fieldState }) => (
                <CustomTextBox
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  sx={{ width: "100%", backgroundColor: Colors.WHITE }}
                  placeholder="Enter instruction details"
                />
              )}
            />
          </Box>

          <CustomButton
            sx={{ width: "10rem", height: "2.6rem", marginTop: "1rem" }}
            type="submit"
            disabled={
              addInstructionLoading ||
              updateInstructionLoading ||
              deleteInstructionLoading ||
              instructionsLoading
            }
          >
            {addInstructionLoading ||
            updateInstructionLoading ||
            deleteInstructionLoading ||
            instructionsLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : populateFor ? (
              "Update Instruction"
            ) : (
              "Add Instruction"
            )}
          </CustomButton>
        </Box>
      </form>
    </CustomDrawer>
  );
};
