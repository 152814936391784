import { FC, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton } from "../../../components";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

interface RegisterNoteModalProps {
  open: boolean;
  onClose: () => void;
  onAddReason: (reason: string) => void;
}

export const RegisterNoteModal: FC<RegisterNoteModalProps> = ({
  open,
  onClose,
  onAddReason,
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleAddNote = () => {
    if (inputValue) {
      onAddReason(inputValue);
      onClose();
    }
  };

  return (
    <CustomModal
      title="Register Note"
      openModal={open}
      onClose={onClose}
      width={pxToRem(564)}
      height={pxToRem(377)}
    >
      <Box
        sx={{
          marginTop: pxToRem(36),
          display: "flex",
          flexDirection: "column",
          gap: pxToRem(16),
        }}
      >
        <Typography
          sx={{
            fontSize: pxToRem(24),
            color: Colors.MAIN_GRAY,
            textAlign: "left",
          }}
        >
          Note about this register balancing:
        </Typography>

        <TextField
          placeholder="Add your note here..."
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              height: pxToRem(218),
            },
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
              fontSize: "1.428571428571429rem",
              color: Colors.MAIN_GRAY,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: Colors.MAIN_GRAY,
            },
          }}
          value={inputValue}
          multiline
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: pxToRem(75),
        }}
      >
        <CustomButton
          onClick={handleAddNote}
          sx={{
            width: pxToRem(467),
            height: pxToRem(64),
            fontSize: pxToRem(24),
            backgroundColor: Colors.PRIMARY,
            color: Colors.WHITE,
            borderRadius: pxToRem(5),
            whiteSpace: "nowrap",
          }}
          disabled={!inputValue}
        >
          Add Note
        </CustomButton>
      </Box>
    </CustomModal>
  );
};
