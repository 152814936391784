import { FC, useState } from "react";
import CustomModal from "../../custom-modal/custom-modal";
import { PinTagsSection } from "./pin-tags-section";
import { Box, Typography } from "@mui/material";
import { CustomButton, CustomTextBox } from "../../../atoms";
import { Colors } from "../../../../configs";
import { pxToRem } from "../../../../util";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { addSession } from "../../../../redux/actions/cartAction";

export interface IAssignPinTagModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceTypeId: number;
  itemsCount: number;
  sessionId: string;
  onSubmit: () => void;
}

export const AssignPinTagModal: FC<IAssignPinTagModalProps> = ({
  isOpen,
  onClose,
  serviceTypeId,
  itemsCount,
  sessionId,
  onSubmit,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const { cart } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const [newSession, setNewSession] = useState<any>(null);

  const handleAssignPinTag = (
    color: string | null,
    tags: string[],
    textBoxCount: number
  ) => {
    const targetTagList = tags.slice(0, textBoxCount);
    let isIncompleteTags =
      targetTagList.filter((tag) => tag.length !== 3).length > 0;

    if (
      color === null ||
      tags.length === 0 ||
      targetTagList.includes("") ||
      isIncompleteTags
    ) {
    } else {
      let newSessionClone = cloneDeep(
        cart.find((session) => session.sessionId === sessionId)
      );
      if (newSessionClone) {
        newSessionClone.pinTag = {
          color: color,
          tags: tags,
        };
        setNewSession(newSessionClone);
      }
    }
  };

  return (
    <CustomModal
      title="Assign Pintag"
      openModal={isOpen}
      onClose={onClose}
      width={pxToRem(665.87)}
      height={pxToRem(571.2)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: pxToRem(46.61),
          mt: { xs: pxToRem(-25), sm: pxToRem(33) },
        }}
      >
        <PinTagsSection
          serviceTypeId={serviceTypeId}
          itemsCount={itemsCount}
          sessionId={sessionId}
          isPintagModalSection={true}
          setIsCompleted={setIsCompleted}
          onChange={handleAssignPinTag}
        />
        <CustomButton
          sx={{
            width: pxToRem(467),
            height: pxToRem(64),
            py: pxToRem(20),
            fontSize: pxToRem(24),
            fontWeight: "bold",
            borderRadius: pxToRem(10),
            alignSelf: "center",
            mt: { xs: pxToRem(-10), sm: pxToRem(0) },
          }}
          onClick={() => {
            onSubmit();
            if (newSession) {
              dispatch(addSession(newSession));
            }
          }}
          disabled={!isCompleted}
        >
          Apply
        </CustomButton>
      </Box>
    </CustomModal>
  );
};
