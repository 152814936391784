import { FC, useEffect, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddUpchargesDrawer } from "../../../../components/admin/add-upcharges-drawer/AddUpchargesDrawer";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteUpchargeAction,
  getAllUpchargesAction,
} from "../../../../redux/actions/upchargesAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

interface IUpcharge {
  id: number;
  upcharge: string;
  upchargeVariants: { upchargeVariant: string; price: number }[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const UpchargesPage: FC = () => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const {
    upcharges,
    upchargesLoading,
    addUpchargeLoading,
    updateUpchargeLoading,
  } = useAppSelector((state) => state.upcharge);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUpcharge, setSelectedUpcharge] = useState<
    IUpcharge | undefined
  >(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    dispatch(getAllUpchargesAction());
  }, []);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    upcharge: IUpcharge
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUpcharge(upcharge);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteUpcharge = async () => {
    if (!selectedUpcharge) {
      return;
    }

    try {
      await dispatch(
        deleteUpchargeAction({
          merchantId: 1,
          storeId: 1,
          upChargeId: selectedUpcharge.id,
        })
      ).unwrap();

      snackbar.showSnackbar("Upcharge deleted successfully!", "success");
      dispatch(getAllUpchargesAction());
      handleClose();
    } catch (error) {
      snackbar.showSnackbar(
        "Failed to delete upcharge. Please try again.",
        "error"
      );
    }
  };

  return (
    <>
      <AdminLayout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            height: "57.5rem",
            marginTop: "4.357rem",
            paddingInline: "5rem",
            width: "100%",
          }}
        >
          <AddUpchargesDrawer
            open={openDrawer}
            toggleDrawer={() => setOpenDrawer(false)}
            populateFor={selectedUpcharge}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
              Upcharges
            </Typography>
            <CustomButton
              sx={{ width: "11rem", height: "3rem" }}
              onClick={() => {
                setOpenDrawer(true);
                setSelectedUpcharge(undefined);
              }}
              disabled={
                upchargesLoading || addUpchargeLoading || updateUpchargeLoading
              }
            >
              {upchargesLoading ||
              addUpchargeLoading ||
              updateUpchargeLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Add New"
              )}
            </CustomButton>
          </Box>

          <Box
            sx={{
              maxHeight: "51rem",
              width: "100%",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "0.5rem" },
              "&::-webkit-scrollbar-thumb": { background: "#888" },
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Upcharge</StyledTableCell>
                  <StyledTableCell>Variants</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {upchargesLoading && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={3} align="center">
                      <CircularProgress />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!upchargesLoading &&
                  upcharges?.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>{row.upcharge}</StyledTableCell>
                      <StyledTableCell>
                        {row.upchargeVariants
                          .map((item) => item.upchargeVariant)
                          .join(", ")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton onClick={(e) => handleClick(e, row)}>
                          <MoreHorizIcon
                            sx={{ fontSize: "2rem" }}
                            color="primary"
                          />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenDrawer(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit Upcharge</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={handleDeleteUpcharge}>
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete Upcharge</ListItemText>
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </AdminLayout>
    </>
  );
};
