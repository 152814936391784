import { FC } from "react";
import { CustomButton } from "../button/button";
import { getAccentColor } from "../../../util/common-functions";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

export interface IPinTagButtonProps {
  color: string;
  code: string;
  onClick: () => void;
  isSelected?: boolean;
  disabled?: boolean;
  isPintagModalButton?: boolean;
}

export const PinTagButton: FC<IPinTagButtonProps> = ({
  color,
  code,
  onClick,
  isSelected = false,
  disabled = false,
  isPintagModalButton = false,
}) => {
  return (
    <CustomButton
      sx={{
        background: color,
        color: disabled ? Colors.ACCENT_GRAY : getAccentColor(color),
        width: isPintagModalButton ? { xs: pxToRem(48), sm: pxToRem(49.8) } : { xs: pxToRem(34), sm: pxToRem(36.39) },
        height: { xs: pxToRem(34), sm: pxToRem(36.39) },
        maxWidth: isPintagModalButton ? { xs: pxToRem(48), sm: pxToRem(49.8) } : { xs: pxToRem(34), sm: pxToRem(36.39) },
        maxHeight: { xs: pxToRem(34), sm: pxToRem(36.39) },
        minWidth: isPintagModalButton ? { xs: pxToRem(48), sm: pxToRem(49.8) } : { xs: pxToRem(34), sm: pxToRem(36.39) },
        minHeight: { xs: pxToRem(34), sm: pxToRem(36.39) },
        border: isSelected
          ? `${pxToRem(3)} solid ${Colors.TEXT_GRAY_DARK}`
          : disabled && !isPintagModalButton
          ? `${pxToRem(1)} dashed ${Colors.ACCENT_GRAY_DISABLED}`
          : "none",
        fontWeight: "bold",
        padding: "0 !important",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {disabled && isPintagModalButton ? "" : code}
    </CustomButton>
  );
};
