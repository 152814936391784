import { createSlice } from "@reduxjs/toolkit";
import { ICartSession } from "../../typings/interfaces/pos/cart-session";
import {
  addBillNoteAction,
  addCartItem,
  addSession,
  attachAlteration,
  deleteBillNoteAction,
  getBillByIdAction,
  getBillItemIdListAction,
  getBillNoteByIdAction,
  newBillAction,
  newPaymentAction,
  pickUpItemAction,
  removeCartItem,
  removeSession,
  updateBillNoteAction,
  updateCartSessionItem,
} from "../actions/cartAction";
import { CartItem } from "../../typings/interfaces/pos/cart-item";
import { BaseResponse, CategoryResponse, ICustomerBill } from "../../typings";
import { CustomerData } from "../../interfaces";
import { IBillResponse } from "../../typings/interfaces/responses/new-bill-response";
import { BillNoteResponse } from "../../typings/interfaces/responses/bill-note-response";
import { temporaryBillNotesInterface } from "../../typings/interfaces/requests/bill-note-requests";
import { CustomizationTypes } from "../../typings/enum/customizationTypes";
import { PaymentCreateResponse } from "../../typings/interfaces/responses/paymentResponse";
import { IHalfPayment } from "../../typings/interfaces/payment/halfPayment";
import { IDraft } from "../../typings/interfaces/pos/draft";
import {
  IBillItemIdListResponse,
  IPickupItemResponse,
} from "../../typings/interfaces/responses/pickupResponses";
import { IBillPayment } from "../../components/pos/payment-modal/payment-modal";

export interface CartState {
  cart: ICartSession[];
  drafts: IDraft[];
  selectedSession: ICartSession | null;
  selectedCartItem: CartItem | null;
  selectedServiceType: CategoryResponse | null;
  selectedCustomizationType: CustomizationTypes;
  previousServiceType: CategoryResponse | null;
  selectedCustomer: CustomerData | null;
  alterationMergeItem: CartItem | null;
  bills: Array<IBillResponse> | null;
  billsLoading: boolean;
  billsError: string | null;
  temporaryBillNotes: Array<temporaryBillNotesInterface>;
  cartMode: "check-in" | "pickup";

  billById: IBillResponse | null;
  billByIdLoading: boolean;
  billByIdError: string | null;

  addBillNoteResponse: BaseResponse<any> | null;
  addBillNoteLoading: boolean;
  addBillNoteError: string | null;

  updateBillNoteResponse: BaseResponse<any> | null;
  updateBillNoteLoading: boolean;
  updateBillNoteError: string | null;

  billNotes: Array<BillNoteResponse> | null;
  billNotesLoading: boolean;
  billNotesError: string | null;

  deleteBillNoteResponse: BaseResponse<any> | null;
  deleteBillNoteLoading: boolean;
  deleteBillNoteError: string | null;

  paymentCreateResponse: BaseResponse<PaymentCreateResponse> | null;
  paymentCreateLoading: boolean;
  paymentCreateError: string | null;

  paymentHalfDetails: IHalfPayment[];

  cartHistorySelectedRows: ICustomerBill[];

  pickUpItemResponse: IPickupItemResponse | null;
  pickUpItemLoading: boolean;
  pickUpItemError: string | null;

  billItemIdListLoading: boolean;
  billItemIdListError: string | null;

  billItemIdList: {
    billId: number;
    billItemIdList: IBillItemIdListResponse[];
  }[];
}

const initialState: CartState = {
  cart: [],
  drafts: [],
  selectedSession: null,
  selectedCartItem: null,
  selectedServiceType: null,
  selectedCustomizationType: CustomizationTypes.INSTRUCTIONS,
  previousServiceType: null,
  selectedCustomer: null,
  alterationMergeItem: null,
  temporaryBillNotes: [],
  cartMode: "check-in",

  bills: null,
  billsLoading: false,
  billsError: null,

  billById: null,
  billByIdLoading: false,
  billByIdError: null,

  addBillNoteResponse: null,
  addBillNoteLoading: false,
  addBillNoteError: null,

  updateBillNoteResponse: null,
  updateBillNoteLoading: false,
  updateBillNoteError: null,

  billNotes: [],
  billNotesLoading: false,
  billNotesError: null,

  deleteBillNoteResponse: null,
  deleteBillNoteLoading: false,
  deleteBillNoteError: null,

  paymentHalfDetails: [],

  paymentCreateResponse: null,
  paymentCreateLoading: false,
  paymentCreateError: null,

  cartHistorySelectedRows: [],

  pickUpItemResponse: null,
  pickUpItemLoading: false,
  pickUpItemError: null,

  billItemIdListLoading: false,
  billItemIdListError: null,

  billItemIdList: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setSelectedSession: (state, action) => {
      state.selectedSession = action.payload;
      state.selectedCartItem = null;
    },
    setSelectedCartItem: (state, action) => {
      state.selectedCartItem = action.payload;
    },
    setSelectedServiceType: (state, action) => {
      state.previousServiceType = state.selectedServiceType;
      state.selectedServiceType = action.payload;
    },
    // setPreviousServiceType: (state, action) => {
    //   state.previousServiceType = action.payload;
    // },
    setSelectedCustomizationType: (state, action) => {
      state.selectedCustomizationType = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    clearSession: (state) => {
      state.selectedSession = null;
      state.selectedCartItem = null;
      state.cart = [];
      state.selectedCustomer = null;
      state.paymentHalfDetails = [];
    },
    setAlterationMergeItem: (state, action) => {
      state.alterationMergeItem = action.payload;
    },
    setCartMode: (state, action) => {
      state.cartMode = action.payload;
    },

    setDrafts: (state, action) => {
      state.drafts = action.payload;
    },
    discardCustomerDraft: (state, action) => {
      state.drafts = state.drafts.filter(
        (draft) => draft.customer.id !== action.payload
      );
    },
    clearDraftedSessions: (state) => {
      state.drafts = [];
    },
    setTemporaryBillNotes: (state, action) => {
      state.addBillNoteLoading = true;
      state.temporaryBillNotes = action.payload;
      state.addBillNoteLoading = false;
    },
    setHalfPayementDetails: (state, action) => {
      state.paymentHalfDetails = action.payload;
    },
    clearBill: (state) => {
      state.bills = null;
      state.billsLoading = false;
      state.billsError = null;
    },
    setCartHistorySelectedRows: (state, action) => {
      state.cartHistorySelectedRows = action.payload;
    },
    setBillItemIdList: (state, action) => {
      state.billItemIdList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSession.fulfilled, (state, action) => {
      state.cart = action.payload;
      state.selectedSession = action.payload[action.payload.length - 1];
      state.selectedCartItem = null;
    });
    builder.addCase(addCartItem.fulfilled, (state, action) => {
      state.cart = action.payload.copiedCartSessions;
      state.selectedSession = action.payload.cartSession;
      state.selectedCartItem = null;
    });
    builder.addCase(removeSession.fulfilled, (state, action) => {
      state.cart = action.payload;
      state.selectedSession = action.payload[action.payload.length - 1];
      state.selectedCartItem = null;
    });
    builder.addCase(updateCartSessionItem.fulfilled, (state, action) => {
      state.cart = action.payload.clonedCart;
      state.selectedCartItem = action.payload.cartItem;
      state.selectedSession = action.payload.session;
    });
    builder.addCase(removeCartItem.fulfilled, (state, action) => {
      state.cart = action.payload.clonedCart;
      state.selectedSession = action.payload.session;
      state.selectedCartItem = null;
    });

    builder
      .addCase(attachAlteration.fulfilled, (state, action) => {
        state.cart = action.payload.clonedCart;
        state.selectedSession = action.payload.session;
        state.selectedCartItem = null;
        state.alterationMergeItem = null;
      })
      .addCase(newBillAction.pending, (state) => {
        state.billsLoading = true;
        state.billsError = null;
        state.bills = null;
      })
      .addCase(newBillAction.fulfilled, (state, action) => {
        state.billsLoading = false;
        state.billsError = null;
        state.bills = action.payload;
      })
      .addCase(newBillAction.rejected, (state, action) => {
        state.billsLoading = false;
        state.billsError = action.error.message ?? "Something went wrong";
        state.bills = null;
      })

      .addCase(getBillByIdAction.pending, (state) => {
        state.billByIdLoading = true;
        state.billByIdError = null;
        state.billById = null;
      })
      .addCase(getBillByIdAction.fulfilled, (state, action) => {
        state.billByIdLoading = false;
        state.billByIdError = null;
        state.billById = action.payload;
      })
      .addCase(getBillByIdAction.rejected, (state, action) => {
        state.billByIdLoading = false;
        state.billByIdError = action.error.message ?? "Something went wrong";
        state.billById = null;
      })

      .addCase(getBillNoteByIdAction.pending, (state: CartState) => {
        state.billNotesLoading = true;
        state.billNotesError = null;
        state.billNotes = null;
      })
      .addCase(getBillNoteByIdAction.fulfilled, (state: CartState, action) => {
        state.billNotesLoading = false;
        state.billNotesError = null;
        state.billNotes = action.payload;
      })
      .addCase(getBillNoteByIdAction.rejected, (state: CartState, action) => {
        state.billNotesLoading = false;
        state.billNotesError = action.error.message ?? "Something went wrong";
        state.billNotes = null;
      })

      .addCase(addBillNoteAction.pending, (state) => {
        state.addBillNoteLoading = true;
        state.addBillNoteError = null;
        state.addBillNoteResponse = null;
      })
      .addCase(addBillNoteAction.fulfilled, (state, action) => {
        state.addBillNoteLoading = false;
        state.addBillNoteError = null;
        state.addBillNoteResponse = action.payload;
      })
      .addCase(addBillNoteAction.rejected, (state, action) => {
        state.addBillNoteLoading = false;
        state.addBillNoteError = action.error.message ?? "Something went wrong";
        state.addBillNoteResponse = null;
      })

      .addCase(updateBillNoteAction.pending, (state) => {
        state.updateBillNoteLoading = true;
        state.updateBillNoteError = null;
        state.updateBillNoteResponse = null;
      })
      .addCase(updateBillNoteAction.fulfilled, (state, action) => {
        state.updateBillNoteLoading = false;
        state.updateBillNoteError = null;
        state.updateBillNoteResponse = action.payload;
      })
      .addCase(updateBillNoteAction.rejected, (state, action) => {
        state.updateBillNoteLoading = false;
        state.updateBillNoteError =
          action.error.message ?? "Something went wrong";
        state.updateBillNoteResponse = null;
      })

      .addCase(deleteBillNoteAction.pending, (state) => {
        state.deleteBillNoteLoading = true;
        state.deleteBillNoteError = null;
        state.deleteBillNoteResponse = null;
      })
      .addCase(deleteBillNoteAction.fulfilled, (state, action) => {
        state.deleteBillNoteLoading = false;
        state.deleteBillNoteError = null;
        state.deleteBillNoteResponse = action.payload;
      })
      .addCase(deleteBillNoteAction.rejected, (state, action) => {
        state.deleteBillNoteLoading = false;
        state.deleteBillNoteError =
          action.error.message ?? "Something went wrong";
        state.deleteBillNoteResponse = null;
      })

      .addCase(newPaymentAction.pending, (state) => {
        state.paymentCreateLoading = true;
        state.paymentCreateError = null;
        state.paymentCreateResponse = null;
      })
      .addCase(newPaymentAction.fulfilled, (state, action) => {
        state.paymentCreateLoading = false;
        state.paymentCreateError = null;
        state.paymentCreateResponse = action.payload;
      })
      .addCase(newPaymentAction.rejected, (state, action) => {
        state.paymentCreateLoading = false;
        state.paymentCreateError =
          action.error.message ?? "Something went wrong";
        state.paymentCreateResponse = null;
      });

    builder
      .addCase(pickUpItemAction.pending, (state) => {
        state.pickUpItemLoading = true;
        state.pickUpItemError = null;
        state.pickUpItemResponse = null;
      })
      .addCase(pickUpItemAction.fulfilled, (state, action) => {
        state.pickUpItemLoading = false;
        state.pickUpItemError = null;
        state.pickUpItemResponse = action.payload;
      })
      .addCase(pickUpItemAction.rejected, (state, action) => {
        state.pickUpItemLoading = false;
        state.pickUpItemError = action.error.message ?? "Something went wrong";
        state.pickUpItemResponse = null;
      });

    builder
      .addCase(getBillItemIdListAction.pending, (state) => {
        state.billItemIdListLoading = true;
        state.billItemIdListError = null;
      })
      .addCase(getBillItemIdListAction.fulfilled, (state, action) => {
        state.billItemIdListLoading = false;
        state.billItemIdListError = null;
        state.billItemIdList = [...state.billItemIdList, action.payload];
      })
      .addCase(getBillItemIdListAction.rejected, (state, action) => {
        state.billItemIdListLoading = false;
        state.billItemIdListError =
          action.error.message ?? "Something went wrong";
      });
  },
});

export default cartSlice.reducer;
export const CartSliceAction = cartSlice.actions;
