import { FC, useEffect, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Colors } from "../../../configs/colors";
import CustomRadioButton from "../../atoms/radio-button/custom-radio-button";
import { CustomSelectBox } from "../../atoms/custom-select-box/custom-select-box";
import { CustomButton, CustomTextBox } from "../../atoms";
import PercentIcon from "@mui/icons-material/Percent";
import usePricing from "../../../hooks/usePricing";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { DiscountSliceAction } from "../../../redux/slice/discountSlice";

export interface IDiscountModalProps {
  open: boolean;
  onClose: () => void;
}

interface IModalSectionProps {
  label: string;
  children: JSX.Element;
}
const ModalSection: FC<IModalSectionProps> = ({ label, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "3rem",
      }}
    >
      <Typography
        sx={{
          width: "10rem",
          minWidth: "10rem",
          maxWidth: "10rem",
          fontSize: "1.42857143rem",
          fontWeight: 600,
          color: Colors.TEXT_GRAY_DARK,
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          width: "27.3571429rem",
          minWidth: "27.3571429rem",
          maxWidth: "27.3571429rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const DiscountModal: FC<IDiscountModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { categoryList } = usePricing();
  const { getDiscountByStoreResponse, selectedDiscount } = useAppSelector(
    (state) => state.discount
  );

  const customDiscounts = [
    {
      value: "percentage",
      label: "Percentage",
    },
    {
      value: "buy1getxfree",
      label: "Buy 1 get x free",
    },
    {
      value: "fixed",
      label: "Fixed cash reduction",
    },
    {
      value: "free",
      label: "Free Service",
    },
  ];
  const coverages = [
    {
      value: "whole",
      label: "Whole billing session",
    },
    {
      value: "bill",
      label: "This bill only",
    },
  ];
  const services: {
    id: number;
    categoryName: string;
    deleted: boolean;
    showInMenu: boolean;
  }[] = [
    { id: 999999, categoryName: "All", deleted: false, showInMenu: true },
    ...(categoryList?.body.filter((tab) => tab.showInMenu) || []),
    {
      id: 100000,
      categoryName: "Alteration",
      deleted: false,
      showInMenu: true,
    },
  ];

  const [discountType, setDiscountType] = useState<"defined" | "custom">(
    "defined"
  );
  const [definedDiscount, setDefinedDiscount] = useState<string>();
  const [customDiscount, setCustomDiscount] = useState<string>(
    customDiscounts[0].value
  );
  const [coverage, setCoverage] = useState<string>(coverages[0].value);
  const [amount, setAmount] = useState<number>(0);
  const [Service, setService] = useState<string>((services[0] as any).id);

  const handleDiscountTypeChange = (e: any) => {
    setDiscountType(e.target.value);
  };
  const handleDefinedDiscountChange = (e: any) => {
    setDefinedDiscount(e.target.value);
  };
  const handleCustomDiscountChange = (e: any) => {
    setCustomDiscount(e.target.value);
  };
  const handleCoverageChange = (e: any) => {
    setCoverage(e.target.value);
  };
  const handleAmountChange = (e: any) => {
    setAmount(Number(e.target.value));
  };
  const handleServiceChange = (e: any) => {
    setService(e.target.value);
  };

  useEffect(() => {
    if (selectedDiscount) setDefinedDiscount(selectedDiscount);
  }, [selectedDiscount]);

  useEffect(() => {
    if (customDiscount === "free") {
      setAmount(100);
    } else {
      setAmount(0);
    }
  }, [customDiscount]);
  useEffect(() => {
    if (customDiscount === "percentage" && amount >= 100) {
      //   setCustomDiscount("free");
      setAmount(100);
    } else if (amount <= 0) {
      setAmount(0);
    }
  }, [amount]);

  const onClickApply = () => {
    dispatch(DiscountSliceAction.setSelectedDiscount(definedDiscount));
    onClose();
  };

  return (
    <CustomModal
      title={"Discounts"}
      openModal={open}
      width="52rem"
      height="42rem"
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "29.98rem",
          paddingTop: "1rem",
        }}
      >
        <ModalSection label="Category">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "-0.7rem",
              "& .MuiRadio-root": {
                padding: { xs: "0.285rem", sm: "0.571rem" },
              },
            }}
          >
            <CustomRadioButton
              radioItems={[
                { value: "defined", label: "Defined Discount" },
                { value: "custom", label: "Custom Discount" },
              ]}
              defaultValue={"defined"}
              orientation="column"
              justify="flex-start"
              onChange={handleDiscountTypeChange}
            />
          </Box>
        </ModalSection>
        {discountType === "defined" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.14071429rem",
            }}
          >
            <ModalSection label="Discount">
              <CustomSelectBox
                width="24.5rem"
                value={definedDiscount}
                onChange={handleDefinedDiscountChange}
                formControlProps={{
                  fullWidth: true,
                  sx: {
                    width: "100%",
                  },
                }}
              >
                {getDiscountByStoreResponse?.body.map((discount, i) => {
                  return (
                    <MenuItem value={discount.id} key={i}>
                      {discount.discountName}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
            </ModalSection>
            <ModalSection label="Description">
              <CustomTextBox
                multiline
                styles={{
                  width: "23.25rem",
                  minWidth: "23.25rem",
                  maxWidth: "23.25rem",
                  height: "7.2rem",
                  lineHeight: "2rem",
                }}
                disabled
                // use selected defined discount's description
                value={
                  getDiscountByStoreResponse?.body.find(
                    (discount) => discount.id === definedDiscount
                  )?.description
                }
                sx={{
                  width: "100%",
                  height: "9.642857142857143rem",
                  padding: "0",
                  "& .MuiOutlinedInput-root": {
                    padding: { xs: "0.5rem 0.3rem", sm: "1.5rem 0.6rem"},
                  },
                }}
              />
            </ModalSection>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.14071429rem",
            }}
          >
            <ModalSection label="Discount Type">
              <CustomSelectBox
                width="24.5rem"
                value={customDiscount}
                onChange={handleCustomDiscountChange}
                formControlProps={{
                  fullWidth: true,
                  sx: {
                    width: "100%",
                  },
                }}
              >
                {customDiscounts.map((discount, i) => {
                  return (
                    <MenuItem value={discount.value} key={i}>
                      {discount.label}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
            </ModalSection>
            <ModalSection label="Coverage">
              <CustomSelectBox
                width="24.5rem"
                value={coverage}
                onChange={handleCoverageChange}
                formControlProps={{
                  fullWidth: true,
                  sx: {
                    width: "100%",
                  },
                }}
              >
                {coverages.map((coverage, i) => {
                  return (
                    <MenuItem value={coverage.value} key={i}>
                      {coverage.label}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
            </ModalSection>
            <ModalSection label="Amount">
              <Box sx={{ position: "relative" }}>
                <CustomTextBox
                  styles={{
                    width:
                      customDiscount === "free" ||
                      customDiscount === "percentage"
                        ? "24.5rem"
                        : "25.33rem",
                    minWidth:
                      customDiscount === "free" ||
                      customDiscount === "percentage"
                        ? "24.5rem"
                        : "25.33rem",
                    maxWidth:
                      customDiscount === "free" ||
                      customDiscount === "percentage"
                        ? "24.5rem"
                        : "25.33rem",
                    paddingRight:
                      customDiscount === "free" ||
                      customDiscount === "percentage"
                        ? "3rem"
                        : "1rem",
                  }}
                  type="number"
                  disabled={customDiscount === "free"}
                  value={amount}
                  onChange={handleAmountChange}
                  sx={{
                    width: "100%",
                  }}
                />
                <PercentIcon
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "50%",
                    bottom: "50%",
                    transform: "translateY(-50%)",
                    color: Colors.TEXT_GRAY_DARK,
                    pointerEvents: "none",
                    transition: "opacity 0.3s",
                    opacity:
                      customDiscount === "percentage" ||
                      customDiscount === "free"
                        ? 1
                        : 0,
                  }}
                />
              </Box>
            </ModalSection>
            <ModalSection label="Service">
              <CustomSelectBox
                width="24.5rem"
                value={Service}
                onChange={handleServiceChange}
                formControlProps={{
                  fullWidth: true,
                  sx: {
                    width: "100%",
                  },
                }}
              >
                {services.map((service, i) => {
                  return (
                    <MenuItem value={(service as any).id} key={i}>
                      {service.categoryName}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
            </ModalSection>
          </Box>
        )}
        <CustomButton
          sx={{
            alignSelf: "center",
            background: Colors.PRIMARY,
            color: Colors.WHITE,
            borderRadius: "0.714285714rem",
            width: "33.3571428571rem",
            height: "4.57142857143rem",
            textTransform: "capitalize",
            fontSize: "1.71428571429rem",
            fontWeight: 500,
            letterSpacing: "0.056rem",
            marginTop: "auto",
          }}
          onClick={onClickApply}
        >
          Apply
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default DiscountModal;
