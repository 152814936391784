import React from "react";
import { Grid, Box } from "@mui/material";

import { Colors } from "../../../configs";

export const OnboardingLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Grid
      sx={{
        height: "100%",
        width: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          height: "1.28571428571rem",
          width: "100%",
          backgroundColor: Colors.PRIMARY,
        }}
      />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {children}
      </Box>

      <Box
        sx={{
          height: "1.28571428571rem",
          width: "100%",
          backgroundColor: Colors.PRIMARY,
        }}
      />
    </Grid>
  );
};
