import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  IAddCashRequest,
  ICLoseCashierRequest,
  IDayEndRequest,
  INewCashierRequest,
  IOpenCashierRequest,
  IRemoveCashRequest,
} from "../../typings/interfaces/requests/cashierStationRequests";
import {
  GET_ALL_STATIONS,
  GET_STATION_BY_ID,
  GET_DENIED_STATIONS,
  GET_GRANTED_STATIONS,
  NEW_STATION,
  UPDATE_STATION_ACCESS,
  OPEN_STATION_CASHIER,
  GET_STATION_CASHIER_SUMMARY,
  GET_STATION_SUMMARY,
  ADD_CASH,
  REMOVE_CASH,
  GET_STORE_SUMMARY,
  CLOSE_STATION_CASHIER,
  GET_ALL_STATION_SUMMARY,
  GET_ALL_STATIONS_DAY_END_SUMMARY,
  END_DAY,
  LATEST_CASHIER_SESSION,
} from "../../configs";
import { BaseResponse, BaseResponseSingle } from "../../typings";
import {
  IAddCashResponse,
  ICloseCashierResponse,
  IDayEndStationSummaryResponse,
  IEndDayResponse,
  ILatestCashierSessionResponse,
  INewCashierResponse,
  IOpenCashierResponse,
  IRemoveCashResponse,
  IStationCashierSummaryResponse,
  IStationSummaryResponse,
  IStoreSummaryResponse,
} from "../../typings/interfaces/responses/cashierStationResponses";

export const requestNewStationAction = createAsyncThunk(
  "cashier-point/request-new",
  async (data: INewCashierRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<INewCashierResponse>
      >(NEW_STATION, data);
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllStationsAction = createAsyncThunk(
  "cashier-point/get-all",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<INewCashierResponse>>(
        GET_ALL_STATIONS
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllGrantedStationsAction = createAsyncThunk(
  "cashier-point/get-granted",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<INewCashierResponse>>(
        GET_GRANTED_STATIONS
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllRejectedStationsAction = createAsyncThunk(
  "cashier-point/get-rejected",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<INewCashierResponse>>(
        GET_DENIED_STATIONS
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStationByIdAction = createAsyncThunk(
  "cashier-point/get-by-id",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponseSingle<INewCashierResponse>>(
        GET_STATION_BY_ID(id)
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateStationAccessAction = createAsyncThunk(
  "cashier-point/update-access",
  async (
    {
      id,
      type,
    }: {
      id: number;
      type: "grant" | "deny";
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch<
        BaseResponseSingle<INewCashierResponse>
      >(UPDATE_STATION_ACCESS(id, type));
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const openStationCashierAction = createAsyncThunk(
  "cashier-point/open",
  async (data: IOpenCashierRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<IOpenCashierResponse>
      >(OPEN_STATION_CASHIER, data);
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStationCashierSummaryAction = createAsyncThunk(
  "cashier-point/cashier-summary",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<IStationCashierSummaryResponse>
      >(GET_STATION_CASHIER_SUMMARY(id));
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStationSummaryAction = createAsyncThunk(
  "cashier-point/station-summary",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<IStationSummaryResponse>
      >(GET_STATION_SUMMARY(id));
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCashAction = createAsyncThunk(
  "cashier-point/add-cash",
  async (data: IAddCashRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponse<IAddCashResponse>>(
        ADD_CASH,
        data
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCashAction = createAsyncThunk(
  "cashier-point/remove-cash",
  async (data: IRemoveCashRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponse<IRemoveCashResponse>>(
        REMOVE_CASH,
        data
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStoreSummaryAction = createAsyncThunk(
  "cashier-point/store-summary",
  async (storeId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<IStoreSummaryResponse>
      >(GET_STORE_SUMMARY(storeId));
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeStationCashierAction = createAsyncThunk(
  "cashier-point/close",
  async (data: ICLoseCashierRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<ICloseCashierResponse>
      >(CLOSE_STATION_CASHIER, data);
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllStationSummaryAction = createAsyncThunk(
  "cashier-point/all-station-summary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IStationSummaryResponse>>(
        GET_ALL_STATION_SUMMARY
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllDayEndStationSummaryAction = createAsyncThunk(
  "cashier-point/day-end-station-summary",
  async (
    { storeId, date }: { storeId: number; date?: Date },
    { rejectWithValue }
  ) => {
    try {
      // date format: yyyy-mm-dd
      const formattedDate = date ? date.toISOString().split("T")[0] : undefined;

      const response = await axios.get<
        BaseResponse<IDayEndStationSummaryResponse>
      >(GET_ALL_STATIONS_DAY_END_SUMMARY(storeId, formattedDate));
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const endDayAction = createAsyncThunk(
  "cashier-point/end-day",
  async (data: IDayEndRequest, { rejectWithValue }) => {
    try {
      const response = await axios.post<BaseResponseSingle<IEndDayResponse>>(
        END_DAY,
        data
      );
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCashierLatestSessionAction = createAsyncThunk(
  "cashier-point/latest-session",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<ILatestCashierSessionResponse>
      >(LATEST_CASHIER_SESSION(id));
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
