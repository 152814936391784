import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { FC, forwardRef } from "react";
// import { Colors } from "../../../configs";

export interface ICheckBoxProps {
  label?: FormControlLabelProps["label"];
  checked?: boolean;
}

type CustomCheckBoxProps = ICheckBoxProps & CheckboxProps;

export const CustomCheckBox: FC<CustomCheckBoxProps> = forwardRef(
  (props, ref) => {
    const { label = "", checked = false, ...otherProps } = props;
    const inputRef = ref as React.RefObject<HTMLInputElement>;
    return (
      <FormControlLabel
        label={label}
        sx={{
          maxHeight: "1rem",
          alignItems: "center",
          whiteSpace: "nowrap",
          "& .MuiTypography-root": {
            fontSize: "1rem",
            padding: "0 0",
          },
        }}
        control={
          <Checkbox
            sx={{
              height: "1rem",
              maxHeight: "1rem",
              whiteSpace: "nowrap",
              padding: "0",
              "& .MuiSvgIcon-root": {
                fontSize: "2rem",
                color: "secondary.main",
              },
            }}
            inputRef={inputRef}
            checked={checked}
            {...otherProps}
          />
        }
      />
    );
  }
);
