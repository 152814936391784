import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { Colors } from "../../../configs";
import { pxToRem } from "../../../util";

const tableHeaders = [
  "No.",
  "Date",
  "Time",
  "Cashier ID",
  "Bill ID",
  "Rack",
  "Location",
];

const sampleData = Array.from({ length: 13 }, (_, index) => ({
  no: (index + 1).toString().padStart(2, "0"),
  date: "2025/02/07",
  time: "10:45",
  cashierId: "XXXXXX",
  billId: "XXXXXX",
  rack: "01",
  location: "100",
}));

const ConveyorInputHistoryTable: React.FC = () => {
  return (
    <TableContainer
      sx={{
        width: pxToRem(1630),
        height: pxToRem(600),
        borderRadius: pxToRem(12),
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxHeight: pxToRem(600),
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": { width: "1.6rem" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ADADAD",
            border: "0.6rem solid transparent",
            backgroundClip: "padding-box",
            minHeight: "3rem",
          },
          "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
        }}
      >
        <Table
          stickyHeader
          sx={{ tableLayout: "fixed", borderSpacing: pxToRem(2) }}
        >
          <TableHead>
            <TableRow
              sx={{ backgroundColor: Colors.SECONDARY, height: pxToRem(41) }}
            >
              {tableHeaders.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontSize: pxToRem(18),
                    fontWeight: "bold",
                    color: Colors.WHITE,
                    height: pxToRem(41),
                    lineHeight: pxToRem(41),
                    backgroundColor: Colors.SECONDARY,
                    padding: "0px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sampleData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: Colors.ACCENT_GRAY,
                  height: pxToRem(41),
                }}
              >
                {Object.values(row).map((cell, cellIndex, arr) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      fontSize: pxToRem(18),
                      color: Colors.MAIN_GRAY,
                      height: pxToRem(41),
                      padding: "0px",
                      textAlign: "center",
                      borderBottomLeftRadius:
                        index === sampleData.length - 1 && cellIndex === 0
                          ? pxToRem(5)
                          : 0,
                      borderBottomRightRadius:
                        index === sampleData.length - 1 &&
                        cellIndex === arr.length - 1
                          ? pxToRem(5)
                          : 0,
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default ConveyorInputHistoryTable;
