import { FC, useEffect, useState } from "react";
import CustomModal from "../../common/custom-modal/custom-modal";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import {
  getBillNoteByIdAction,
  addBillNoteAction,
} from "../../../redux/actions/cartAction";
import { CustomButton, CustomTextBox } from "../../atoms";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import BillNotesCard from "./billNotesCard";
import { v4 as uuidv4 } from "uuid";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { IBillResponse } from "../../../typings/interfaces/responses/new-bill-response";

export interface INotesModalProps {
  open: boolean;
  onClose(): void;
}

const BillNotesModal: FC<INotesModalProps> = ({ open, onClose }) => {
  const [newNote, setNewNote] = useState("");
  const { customer } = useAppSelector((state) => state.customer);
  // const [selectedOccations, setSelectedOccasions] = useState<string[]>([]);
  const [mode, setMode] = useState<"temp" | "perm">("temp");

  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params.id;
  const {
    bills,
    billNotes,
    addBillNoteLoading,
    temporaryBillNotes,
    selectedSession,
  } = useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.auth);
  const snackbar = useSnackbarContext();
  const [targetBill, setTargetBill] = useState<IBillResponse | undefined>(
    bills?.find((bill) => bill.sessionId === selectedSession?.sessionId)
  );

  // const billId: number = Number(bill?.id ?? "1"); // change later

  useEffect(() => {
    setTargetBill(
      bills?.find((bill) => bill.sessionId === selectedSession?.sessionId)
    );
    if (targetBill && targetBill.id) {
      setMode("perm");
      dispatch(getBillNoteByIdAction({ billId: targetBill.id }));
    } else {
      setMode("temp");
    }
  }, [bills, customerId]);

  const handleNewNoteTextChange = (note: string) => {
    setNewNote(note);
  };

  const handleOnAddNote = async () => {
    if (newNote.length > 0) {
      if (customerId && user) {
        if (mode === "perm" && targetBill) {
          alert("adding perm note");
          await dispatch(
            addBillNoteAction({
              note: newNote,
              occasions: [] as NoteOccasion[],
              // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
              merchantUserId: 1,
              customerId: Number(customerId),
              billId: targetBill.id,
            })
          ).then(() => {
            dispatch(getBillNoteByIdAction({ billId: targetBill!.id }));
          });
        } else {
          const tempId = uuidv4();
          dispatch(
            CartSliceAction.setTemporaryBillNotes([
              {
                tempId: tempId,
                note: newNote,
                occasions: [] as NoteOccasion[],
                // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
                merchantUserId: 1,
                customerId: Number(customerId),
                cashierFirstName: user.firstName,
                cashierLastName: user.lastName,
                date: new Date().toISOString(),
              },
              ...temporaryBillNotes,
            ])
          );
        }
        setNewNote("");
      } else {
        snackbar.showSnackbar("Failed to detect customer/cashier", "error");
      }
    } else {
      snackbar.showSnackbar("Cannot add empty notes", "warning");
    }
  };

  // const handleOnAddNote = async () => {
  //   if (newNote.length > 0) {
  //     if (customerId && user) {
  //       const tempId = uuidv4();
  //       bill !== null && Number(bill.id) > 0
  //         ? await dispatch(
  //             addBillNoteAction({
  //               note: newNote,
  //               occasions: [] as NoteOccasion[],
  //               // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
  //               merchantUserId: 1,
  //               customerId: Number(customerId),
  //               billId: Number(bill.id),
  //             })
  //           ).then((response) => {
  //             // if (response) {
  //             //   console.log(JSON.stringify(response));
  //             // } else {
  //             // }
  //             dispatch(getBillNoteByIdAction({ billId: String(bill.id) }));
  //           })
  //         : dispatch(
  //             CartSliceAction.setTemporaryBillNotes([
  //               {
  //                 tempId: tempId,
  //                 note: newNote,
  //                 occasions: [] as NoteOccasion[],
  //                 // merchantUserId: Number(user.id), // This must be fixed. this is not the admin id which should used
  //                 merchantUserId: 1,
  //                 customerId: Number(customerId),
  //                 cashierFirstName: user.firstName,
  //                 cashierLastName: user.lastName,
  //                 date: new Date().toISOString(),
  //               },
  //               ...temporaryBillNotes,
  //             ])
  //           );
  //       setNewNote("");
  //     }
  //   } else {
  //     snackbar.showSnackbar("Cannot add empty notes", "warning");
  //   }
  // };

  return (
    <CustomModal
      title={"Bill Notes"}
      openModal={open}
      width="55.4464286rem"
      height="71.8571429rem"
      onClose={onClose}
    >
      <Box
        sx={{
          height: "34rem",
          overflowY: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          touchAction: "pan-y",
        }}
      >
        {addBillNoteLoading ? (
          <Box
            sx={{
              textAlign: "center",
              py: "14rem",
              fontSize: "1.8rem",
              opacity: 0.5,
            }}
          >
            <CircularProgress />
          </Box>
        ) : mode === "perm" ? (
          billNotes == null || billNotes.length === 0 ? (
            <Box
              sx={{
                textAlign: "center",
                py: "14rem",
                fontSize: "1.8rem",
                opacity: 0.5,
              }}
            >
              No notes for this bill
            </Box>
          ) : (
            billNotes.map((note, index) => (
              <BillNotesCard key={index} note={note} noteMode={"perm"} />
              /*
                <BillNotesCard
                key={note.id}
                description={note.note}
                noteBy={`${note.reportedUserFirstName} ${note.reportedUserLastName}`}
                date={note.createdTime ? new Date(note.createdTime) : null}
                noteId={note.id}
                noteMode={"perm"}
              />
              */
            ))
          )
        ) : temporaryBillNotes == null || temporaryBillNotes.length === 0 ? (
          <Box
            sx={{
              textAlign: "center",
              py: "14rem",
              fontSize: "1.8rem",
              opacity: 0.5,
            }}
          >
            No notes for this bill
          </Box>
        ) : (
          temporaryBillNotes.map((note, index) => (
            <BillNotesCard
              key={note.tempId}
              note={{
                note: note.note,
                reportedUserFirstName: note.cashierFirstName,
                reportedUserLastName: note.cashierLastName,
                customerId: note.customerId,
                noteShowOccasion: note.occasions,
                reportedUserId: note.merchantUserId,
                createdTime: new Date().toISOString(),
                customerFirstName: customer?.firstName ?? "",
                customerLastName: customer?.lastName ?? "",
                lastModifiedTime: new Date().toISOString(),
                billId: targetBill?.id ?? 0,
                id: note.tempId,
              }}
              noteMode={"temp"}
            />
            /*
            <BillNotesCard
              key={note.tempId}
              description={note.note}
              noteBy={`${note.cashierFirstName} ${note.cashierLastName}`}
              date={note.date ? new Date(note.date) : null}
              noteId={note.tempId}
              noteMode={"temp"}
            />
            */
          ))
        )}
      </Box>
      <Divider sx={{ marginTop: "1.42857143rem" }} />
      <Typography
        sx={{
          fontSize: "1.71428571rem",
          fontWeight: 600,
          color: Colors.TEXT_GRAY_DARK,
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        Add new note
      </Typography>
      <CustomTextBox
        multiline
        sx={{
          width: "100%",
          "& .MuiInputBase-input": {
            height: "6.14285714rem",
            minHeight: "6.14285714rem",
            maxHeight: "6.14285714rem",
          },
        }}
        value={newNote}
        onChange={(event) => handleNewNoteTextChange(event.target.value)}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem",
        }}
      >
        <CustomButton
          sx={{
            background: Colors.PRIMARY,
            color: Colors.WHITE,
            textTransform: "capitalize",
            fontSize: "1.42857143rem",
            height: "3.85714286rem",
            width: "14.2857143rem",
          }}
          disabled={newNote.length === 0 || addBillNoteLoading}
          onClick={handleOnAddNote}
        >
          Add New Note
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default BillNotesModal;
