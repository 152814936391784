import { FC, useEffect, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddDamagesDrawer } from "../../../../components/admin/add-damages-drawer/AddDamagesDrawer";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteDamageAction,
  getAllDamagesAction,
} from "../../../../redux/actions/damagesAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

interface IDamage {
  id: number;
  damage: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const DamagesPage: FC = () => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const { damages, damagesLoading, addDamageLoading, updateDamageLoading } =
    useAppSelector((state) => state.damage);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDamage, setSelectedDamage] = useState<IDamage | undefined>(
    undefined
  );
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    dispatch(getAllDamagesAction());
  }, []);

  // useEffect(() => {
  //   if (addDamageResponse) {
  //     snackbar.showSnackbar("Damage added successfully!", "success");
  //     dispatch(getAllDamagesAction());
  //   }
  // }, [addDamageResponse]);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    damage: IDamage
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDamage(damage);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteDamage = async () => {
    if (!selectedDamage) {
      return;
    }
    handleClose();

    try {
      await dispatch(
        deleteDamageAction({
          merchantId: 1, // todo: remove hardcoded values
          storeId: 1, // todo: remove hardcoded values
          damageId: selectedDamage.id,
        })
      ).unwrap();

      snackbar.showSnackbar("Damage deleted successfully!", "success");
      dispatch(getAllDamagesAction());
    } catch (error) {
      snackbar.showSnackbar(
        "Failed to delete damage. Please try again.",
        "error"
      );
    }
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.357rem",
          paddingInline: "5rem",
          width: "100%",
        }}
      >
        <AddDamagesDrawer
          open={openDrawer}
          toggleDrawer={() => setOpenDrawer(false)}
          populateFor={selectedDamage}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Damages
          </Typography>
          <CustomButton
            sx={{ width: "11rem", height: "3rem" }}
            onClick={() => {
              setSelectedDamage(undefined);
              setOpenDrawer(true);
            }}
            disabled={damagesLoading || addDamageLoading || updateDamageLoading}
          >
            {damagesLoading || addDamageLoading || updateDamageLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Add New"
            )}
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "51rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "0.5rem" },
            "&::-webkit-scrollbar-thumb": { background: "#888" },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Damage</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {damagesLoading && (
                <StyledTableRow>
                  <StyledTableCell colSpan={2} align="center">
                    <CircularProgress />
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {!damagesLoading &&
                damages?.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell sx={{ width: "80%" }}>
                      {row.damage}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "20%" }}>
                      <IconButton onClick={(e) => handleClick(e, row)}>
                        <MoreHorizIcon
                          sx={{ fontSize: "2rem" }}
                          color="primary"
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpenDrawer(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Damage</ListItemText>
                </MenuItem>

                <MenuItem onClick={handleDeleteDamage}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Damage</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};
