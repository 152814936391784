import { FC, useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { toLower, upperFirst } from "lodash";
import { a } from "@react-spring/web";

import BillingSessionNav from "../../../common/pos/billing-session-nav/billing-session-nav";
import CustomerDetailSection from "../../../common/pos/customer-detail-section/customer-detail-section";
import CartSection from "../../../common/pos/cart-section/cart-section";
import CustomMarquee from "../../../common/pos/marquee/marquee";
import OrderDetailSection from "../../../common/pos/order-detail-section/order-detail-section";
import CartQtyHandlerSection from "../../../common/pos/cart-qty-handler-section/cart-qty-handler-section";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  newBillAction,
  removeCartItem,
} from "../../../../redux/actions/cartAction";
import useThermalPrinter from "../../../../hooks/thermalPrinter";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PosSliceAction } from "../../../../redux/slice/posSlice";
import { Service } from "../../../../typings/enum/Service";
import PaymentModal from "../../payment-modal/payment-modal";
import useCart from "../../../../hooks/useCart";
import { getPricing } from "../../../../redux/actions/pricingAction";
import { PaymentModalContents } from "../../../../typings/enum/paymentModalContents";
import { monthNames } from "../../../../data";
import BillNotesModal from "../../notes-modal/billNotesModal";
import { getUserNotesByIdAction } from "../../../../redux/actions/customerAction";
import { useUserNote } from "../../../../providers/UserNoteProvider";
import { NoteOccasion } from "../../../../typings/enum/noteOccasions";
import { CustomButton } from "../../../atoms";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import { AssignPinTagModal } from "../../../common/pos/assign-pin-tag-modal/assign-pin-tag-modal";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";

export interface ICartProps {}

const Cart: FC<ICartProps> = ({}) => {
  const [openCheckInMdal, setOpenCheckInMdal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openAssignPinTagModal, setOpenAssignPinTagModal] = useState(false);

  const [openActionTileModal, setOpenActionTileModal] =
    useState<boolean>(false);
  const [isCheckInSuccessful, setIsCheckInSuccessful] = useState<boolean>(true);
  const {
    cart,
    selectedCartItem,
    selectedSession,
    selectedServiceType,
    cartMode,
  } = useAppSelector((state) => state.cart);
  const { cartTotal } = useCart();
  const { customer, customerNotes } = useAppSelector((state) => state.customer);
  const { activeServiceTab } = useAppSelector((state) => state.pos);
  const { configure, isConfigured } = useThermalPrinter();
  const { showNote } = useUserNote();
  const snackbar = useSnackbarContext();

  const navigate = useNavigate();
  // const [marqueeText, setMarqueeText] = useState("");
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();

  const customerId = params.id;
  const handleOnClickDelete = () => {
    dispatch(removeCartItem());
  };
  const loadNotes = async () => {
    if (customerId) {
      await dispatch(getUserNotesByIdAction(customerId as string)).then(
        (res: any) => {
          showNote(NoteOccasion.ON_CHECKIN, res.payload);
        }
      );
    }
  };

  const changeCartMode = (mode: "check-in" | "pickup") => {
    if (cartMode !== mode) {
      dispatch(CartSliceAction.setCartMode(mode));
    }
    dispatch(CartSliceAction.clearSession());
  };

  useEffect(() => {
    changeCartMode("check-in");
  }, [cartMode, location.pathname]);

  useEffect(() => {
    if (selectedServiceType?.id)
      dispatch(getPricing({ id: selectedServiceType?.id }));
  }, [selectedServiceType?.id]);

  const handleOnClickCustomization = () => {
    dispatch(
      PosSliceAction.setActiveServiceTab(
        activeServiceTab === Service.GARMENT_WASH_AND_CLEAN
          ? Service.CUSTOMIZATION
          : Service.GARMENT_WASH_AND_CLEAN
      )
    );
  };

  const handleOnCloseCheckInModal = () => {
    setOpenCheckInMdal(false);
  };
  const handleOnCloseNotesModal = () => {
    setOpenNotesModal(false);
  };

  const handleOnCheckIn = () => {
    let isEmptySession = true;
    cart.forEach((session) => {
      if (session.cartItems?.filter((item) => item.finalized).length > 0) {
        isEmptySession = false;
      } else {
        isEmptySession = true;
        return;
      }
    });

    if (isEmptySession) {
      snackbar.showSnackbar(
        "Please add items to the empty session before check in",
        "warning"
      );
    } else {
      if (
        cart[cart.length - 1]?.pinTag.color == null ||
        cart[cart.length - 1]?.pinTag.tags.length === 0
      ) {
        setOpenAssignPinTagModal(true);
      } else {
        setOpenCheckInMdal(true);
      }
    }
  };
  let isEmptySession = true;
  cart.forEach((session) => {
    if (session.cartItems?.filter((item) => item.finalized).length > 0) {
      isEmptySession = false;
    } else {
      isEmptySession = true;
      return;
    }
  });

  const handleOpenModal = () => {
    setOpenActionTileModal(true);
  };

  return (
    <Box
      sx={{
        flex: 4,
        display: "flex",
        flexDirection: "column",
        gap: "0.57rem",
        height: "100%",
        maxHeight: "100% !important",
        width: "100%",
        maxWidth: "100%",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <AssignPinTagModal
        isOpen={openAssignPinTagModal}
        onClose={() => setOpenAssignPinTagModal(false)}
        serviceTypeId={
          cart[cart.length - 1]?.cartItems[0]?.serviceType?.id ?? 0
        }
        itemsCount={
          cart[cart.length - 1]?.cartItems.reduce(
            (acc, item) => (item.finalized ? acc + item.qty : acc),
            0
          ) ?? 0
        }
        sessionId={cart[cart.length - 1]?.sessionId ?? ""}
        onSubmit={() => {
          setOpenCheckInMdal(true);
          setOpenAssignPinTagModal(false);
        }}
      />
      <PaymentModal
        total={cartTotal}
        discount={0}
        open={openCheckInMdal}
        onClose={handleOnCloseCheckInModal}
        // onCheckout={handleOnCheckout}
        content={PaymentModalContents.SUMMARY}
      />

      <BillingSessionNav />
      <CustomerDetailSection
        showAnalysisButton={true}
        showEditButton={true}
        showHistoryButton={true}
      />
      <CartSection onSelect={() => {}} />
      <CustomMarquee
        text={
          customerNotes == null || customerNotes.length === 0
            ? ""
            : customerNotes.map((note) => note.note).join(", ")
        }
      />
      {/* <Marquee>{marqueeText}</Marquee> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1.07142857rem",
          flexShrink: 0,
        }}
      >
        <OrderDetailSection />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.71rem",
            maxHeight: "16.07142857142857rem",
            maxWidth: "28.21428571428571rem",
          }}
        >
          <CartQtyHandlerSection />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "0.71rem",
              flexWrap: "nowrap",
              width: "100%",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <IconButton
              sx={{
                background: Colors.MAIN_GRAY,
                height: "3.714285714285714rem",
                width: "3.571428571428571rem",
                aspectRatio: "1/1",
                flexShrink: 0,
                borderRadius: "0.71rem",
                "&:hover": { background: `${Colors.MAIN_GRAY} !important` },
                "&:disabled": { background: `${Colors.MAIN_GRAY} !important` },
              }}
              disabled={selectedCartItem?.finalized === true ? false : true}
              onClick={handleOnClickDelete}
            >
              <Delete
                sx={{
                  color:
                    selectedCartItem?.finalized === true
                      ? Colors.WHITE
                      : "inherit",
                  fontSize: "1.78571429rem",
                }}
              />
            </IconButton>
            <BillNotesModal
              open={openNotesModal}
              onClose={handleOnCloseNotesModal}
            />
            <CustomButton
              sx={{
                background: Colors.MAIN_GRAY,
                color: Colors.WHITE,
                fontSize: "1.42rem",
                flex: 1,
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                width: "100%",
                minWidth: 0,
                height: "3.714285714285714rem",

                "&:hover": { background: `${Colors.MAIN_GRAY} !important` },
              }}
              onClick={() => setOpenNotesModal(true)}
              disabled={isEmptySession}
            >
              Notes
            </CustomButton>
            <CustomButton
              disabled={selectedCartItem?.finalized === true ? false : true}
              sx={{
                background: Colors.MAIN_GRAY,
                color: Colors.WHITE,
                fontSize: "1.42rem",
                flex: 1,
                borderRadius: "0.71rem",
                textTransform: "capitalize",
                width: "100%",
                minWidth: 0,
                height: "3.714285714285714rem",

                "&:hover": { background: `${Colors.MAIN_GRAY} !important` },
              }}
              onClick={handleOnClickCustomization}
            >
              {activeServiceTab === Service.GARMENT_WASH_AND_CLEAN
                ? "Customization"
                : "Add Items"}
            </CustomButton>
          </Box>

          <CustomButton
            sx={{
              width: "100%",
              height: "5.857142857142857rem",
              fontSize: "2.28rem",
              borderRadius: "0.71rem",
              background: Colors.PRIMARY,
              color: Colors.WHITE,
              textTransform: "capitalize",
              flexGrow: 1,
              "&:hover": { background: `${Colors.PRIMARY} !important` },
            }}
            onClick={handleOnCheckIn}
          >
            Check In
          </CustomButton>

          <CustomButton
            onClick={configure}
            disabled={isConfigured}
            sx={{ background: Colors.ACCENT_GRAY, height: "" }} //a minimum height is temporary assigned for the button
          >
            Config Printer
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Cart;
