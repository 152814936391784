import { createSlice } from "@reduxjs/toolkit";

import {
  IAddCashResponse,
  ICloseCashierResponse,
  IDayEndStationSummaryResponse,
  IEndDayResponse,
  ILatestCashierSessionResponse,
  INewCashierResponse,
  IOpenCashierResponse,
  IRemoveCashResponse,
  IStationCashierSummaryResponse,
  IStationSummaryResponse,
  IStoreSummaryResponse,
} from "../../typings/interfaces/responses/cashierStationResponses";
import {
  addCashAction,
  closeStationCashierAction,
  endDayAction,
  getAllDayEndStationSummaryAction,
  getAllStationsAction,
  getAllStationSummaryAction,
  getCashierLatestSessionAction,
  getStationByIdAction,
  getStationCashierSummaryAction,
  getStationSummaryAction,
  getStoreSummaryAction,
  openStationCashierAction,
  removeCashAction,
  requestNewStationAction,
  updateStationAccessAction,
} from "../actions/cashierStationActions";
import { IOpenCashierRequest } from "../../typings/interfaces/requests/cashierStationRequests";

export interface ICashierStationState {
  activeStation: INewCashierResponse | null;
  activeStationCashier:
    | (IOpenCashierResponse & { status?: "open" | "closed" | null })
    | null;

  requestNewCahierResponse: INewCashierResponse | null;
  requestNewCahierLoading: boolean;
  requestNewCahierError: string | null;

  allCashierStationRequests: INewCashierResponse[] | null;
  getAllCashierStationRequestsLoading: boolean;
  getAllCashierStationRequestsError: string | null;

  approvedCashierStationRequests: INewCashierResponse[] | null;
  getApprovedCashierStationRequestsLoading: boolean;
  getApprovedCashierStationRequestsError: string | null;

  rejectedCashierStationRequests: INewCashierResponse[] | null;
  getRejectedCashierStationRequestsLoading: boolean;
  getRejectedCashierStationRequestsError: string | null;

  getCashierStationByIdResponse: INewCashierResponse | null;
  getCashierStationByIdLoading: boolean;
  getCashierStationByIdError: string | null;

  updateCashierStationAccessResponse: INewCashierResponse | null;
  updateCashierStationAccessLoading: boolean;
  updateCashierStationAccessError: string | null;

  openStationCashierResponse: IOpenCashierResponse | null;
  openStationCashierLoading: boolean;
  openStationCashierError: string | null;

  getStationCashierSummaryResponse: IStationCashierSummaryResponse | null;
  getStationCashierSummaryLoading: boolean;
  getStationCashierSummaryError: string | null;

  getStationSummaryResponse: IStationSummaryResponse | null;
  getStationSummaryLoading: boolean;
  getStationSummaryError: string | null;

  getStoreSummaryResponse: IStoreSummaryResponse | null;
  getStoreSummaryLoading: boolean;
  getStoreSummaryError: string | null;

  addCashResponse: IAddCashResponse[] | null;
  addCashLoading: boolean;
  addCashError: string | null;

  removeCashResponse: IRemoveCashResponse[] | null;
  removeCashLoading: boolean;
  removeCashError: string | null;

  closeStationCashierResponse: ICloseCashierResponse | null;
  closeStationCashierLoading: boolean;
  closeStationCashierError: string | null;

  getAllStationSummaryResponse: IStationSummaryResponse[] | null;
  getAllStationSummaryLoading: boolean;
  getAllStationSummaryError: string | null;

  dayEndStationSummaryResponse: IDayEndStationSummaryResponse[] | null;
  dayEndStationSummaryLoading: boolean;
  dayEndStationSummaryError: string | null;

  isRegisterCleaned: boolean;

  endDayResponse: IEndDayResponse | null;
  endDayLoading: boolean;
  endDayError: string | null;

  cashierLatestSession: ILatestCashierSessionResponse | null;
  cashierLatestSessionLoading: boolean;
  cashierLatestSessionError: string | null;
}

const initialState: ICashierStationState = {
  activeStation: null,
  activeStationCashier: null,

  requestNewCahierResponse: null,
  requestNewCahierLoading: false,
  requestNewCahierError: null,

  allCashierStationRequests: null,
  getAllCashierStationRequestsLoading: false,
  getAllCashierStationRequestsError: null,

  approvedCashierStationRequests: null,
  getApprovedCashierStationRequestsLoading: false,
  getApprovedCashierStationRequestsError: null,

  rejectedCashierStationRequests: null,
  getRejectedCashierStationRequestsLoading: false,
  getRejectedCashierStationRequestsError: null,

  getCashierStationByIdResponse: null,
  getCashierStationByIdLoading: false,
  getCashierStationByIdError: null,

  updateCashierStationAccessResponse: null,
  updateCashierStationAccessLoading: false,
  updateCashierStationAccessError: null,

  openStationCashierResponse: null,
  openStationCashierLoading: false,
  openStationCashierError: null,

  getStationCashierSummaryResponse: null,
  getStationCashierSummaryLoading: false,
  getStationCashierSummaryError: null,

  getStationSummaryResponse: null,
  getStationSummaryLoading: false,
  getStationSummaryError: null,

  getStoreSummaryResponse: null,
  getStoreSummaryLoading: false,
  getStoreSummaryError: null,

  addCashResponse: null,
  addCashLoading: false,
  addCashError: null,

  removeCashResponse: null,
  removeCashLoading: false,
  removeCashError: null,

  closeStationCashierResponse: null,
  closeStationCashierLoading: false,
  closeStationCashierError: null,

  getAllStationSummaryResponse: null,
  getAllStationSummaryLoading: false,
  getAllStationSummaryError: null,

  dayEndStationSummaryResponse: null,
  dayEndStationSummaryLoading: false,
  dayEndStationSummaryError: null,

  isRegisterCleaned: false,

  endDayResponse: null,
  endDayLoading: false,
  endDayError: null,

  cashierLatestSession: null,
  cashierLatestSessionLoading: false,
  cashierLatestSessionError: null,
};

const cashierStationSlice = createSlice({
  name: "cashierStation",
  initialState,
  reducers: {
    setActiveStation: (state, action) => {
      state.activeStation = action.payload;
    },
    setActiveStationCashier: (state, action) => {
      state.activeStationCashier = action.payload;
    },
    clearOpenStationCashierResponse: (state) => {
      state.openStationCashierResponse = null;
    },
    setIsRegisterCleaned: (state) => {
      state.isRegisterCleaned = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestNewStationAction.pending, (state) => {
        state.requestNewCahierLoading = true;
        state.requestNewCahierError = null;
        state.requestNewCahierResponse = null;
      })
      .addCase(requestNewStationAction.fulfilled, (state, action) => {
        state.requestNewCahierLoading = false;
        state.requestNewCahierResponse = action.payload;
        state.requestNewCahierError = null;
      })
      .addCase(requestNewStationAction.rejected, (state, action) => {
        state.requestNewCahierLoading = false;
        state.requestNewCahierError = action.payload as string;
        state.requestNewCahierResponse = null;
      });

    builder
      .addCase(getAllStationsAction.pending, (state) => {
        state.getAllCashierStationRequestsLoading = true;
        state.getAllCashierStationRequestsError = null;
        state.allCashierStationRequests = null;
      })
      .addCase(getAllStationsAction.fulfilled, (state, action) => {
        state.getAllCashierStationRequestsLoading = false;
        state.allCashierStationRequests = action.payload;
        state.getAllCashierStationRequestsError = null;
      })
      .addCase(getAllStationsAction.rejected, (state, action) => {
        state.getAllCashierStationRequestsLoading = false;
        state.getAllCashierStationRequestsError = action.payload as string;
        state.allCashierStationRequests = null;
      });

    builder
      .addCase(getStationByIdAction.pending, (state) => {
        state.getCashierStationByIdLoading = true;
        state.getCashierStationByIdError = null;
        state.getCashierStationByIdResponse = null;
      })
      .addCase(getStationByIdAction.fulfilled, (state, action) => {
        state.getCashierStationByIdLoading = false;
        state.getCashierStationByIdResponse = action.payload;
        state.getCashierStationByIdError = null;
      })
      .addCase(getStationByIdAction.rejected, (state, action) => {
        state.getCashierStationByIdLoading = false;
        state.getCashierStationByIdError = action.payload as string;
        state.getCashierStationByIdResponse = null;
      });

    builder
      .addCase(updateStationAccessAction.pending, (state) => {
        state.updateCashierStationAccessLoading = true;
        state.updateCashierStationAccessError = null;
        state.updateCashierStationAccessResponse = null;
      })
      .addCase(updateStationAccessAction.fulfilled, (state, action) => {
        state.updateCashierStationAccessLoading = false;
        state.updateCashierStationAccessResponse = action.payload;
        state.updateCashierStationAccessError = null;
      })
      .addCase(updateStationAccessAction.rejected, (state, action) => {
        state.updateCashierStationAccessLoading = false;
        state.updateCashierStationAccessError = action.payload as string;
        state.updateCashierStationAccessResponse = null;
      });

    builder
      .addCase(openStationCashierAction.pending, (state) => {
        state.openStationCashierLoading = true;
        state.openStationCashierError = null;
        state.openStationCashierResponse = null;
      })
      .addCase(openStationCashierAction.fulfilled, (state, action) => {
        state.openStationCashierLoading = false;
        state.openStationCashierResponse = action.payload;
        state.openStationCashierError = null;
      })
      .addCase(openStationCashierAction.rejected, (state, action) => {
        state.openStationCashierLoading = false;
        state.openStationCashierError = action.payload as string;
        state.openStationCashierResponse = null;
      });

    builder
      .addCase(getStationCashierSummaryAction.pending, (state) => {
        state.getStationCashierSummaryLoading = true;
        state.getStationCashierSummaryError = null;
        state.getStationCashierSummaryResponse = null;
      })
      .addCase(getStationCashierSummaryAction.fulfilled, (state, action) => {
        state.getStationCashierSummaryLoading = false;
        state.getStationCashierSummaryResponse = action.payload[0];
        state.getStationCashierSummaryError = null;
      })
      .addCase(getStationCashierSummaryAction.rejected, (state, action) => {
        state.getStationCashierSummaryLoading = false;
        state.getStationCashierSummaryError = action.payload as string;
        state.getStationCashierSummaryResponse = null;
      });

    builder
      .addCase(getStationSummaryAction.pending, (state) => {
        state.getStationSummaryLoading = true;
        state.getStationSummaryError = null;
        state.getStationSummaryResponse = null;
      })
      .addCase(getStationSummaryAction.fulfilled, (state, action) => {
        state.getStationSummaryLoading = false;
        state.getStationSummaryResponse = action.payload;
        state.getStationSummaryError = null;
      })
      .addCase(getStationSummaryAction.rejected, (state, action) => {
        state.getStationSummaryLoading = false;
        state.getStationSummaryError = action.payload as string;
        state.getStationSummaryResponse = null;
      });

    builder
      .addCase(getStoreSummaryAction.pending, (state) => {
        state.getStoreSummaryLoading = true;
        state.getStoreSummaryError = null;
        state.getStoreSummaryResponse = null;
      })
      .addCase(getStoreSummaryAction.fulfilled, (state, action) => {
        state.getStoreSummaryLoading = false;
        state.getStoreSummaryResponse = action.payload;
        state.getStoreSummaryError = null;
      })
      .addCase(getStoreSummaryAction.rejected, (state, action) => {
        state.getStoreSummaryLoading = false;
        state.getStoreSummaryError = action.payload as string;
        state.getStoreSummaryResponse = null;
      });

    builder
      .addCase(addCashAction.pending, (state) => {
        state.addCashLoading = true;
        state.addCashError = null;
        state.addCashResponse = null;
      })
      .addCase(addCashAction.fulfilled, (state, action) => {
        state.addCashLoading = false;
        state.addCashResponse = action.payload;
        state.addCashError = null;
      })
      .addCase(addCashAction.rejected, (state, action) => {
        state.addCashLoading = false;
        state.addCashError = action.payload as string;
        state.addCashResponse = null;
      });

    builder
      .addCase(removeCashAction.pending, (state) => {
        state.removeCashLoading = true;
        state.removeCashError = null;
        state.removeCashResponse = null;
      })
      .addCase(removeCashAction.fulfilled, (state, action) => {
        state.removeCashLoading = false;
        state.removeCashResponse = action.payload;
        state.removeCashError = null;
      })
      .addCase(removeCashAction.rejected, (state, action) => {
        state.removeCashLoading = false;
        state.removeCashError = action.payload as string;
        state.removeCashResponse = null;
      });

    builder
      .addCase(closeStationCashierAction.pending, (state) => {
        state.closeStationCashierLoading = true;
        state.closeStationCashierError = null;
        state.closeStationCashierResponse = null;
      })
      .addCase(closeStationCashierAction.fulfilled, (state, action) => {
        state.closeStationCashierLoading = false;
        state.closeStationCashierResponse = action.payload;
        state.closeStationCashierError = null;
      })
      .addCase(closeStationCashierAction.rejected, (state, action) => {
        state.closeStationCashierLoading = false;
        state.closeStationCashierError = action.payload as string;
        state.closeStationCashierResponse = null;
      });

    builder
      .addCase(getAllStationSummaryAction.pending, (state) => {
        state.getAllStationSummaryLoading = true;
        state.getAllStationSummaryError = null;
        state.getAllStationSummaryResponse = null;
      })
      .addCase(getAllStationSummaryAction.fulfilled, (state, action) => {
        state.getAllStationSummaryLoading = false;
        state.getAllStationSummaryResponse = action.payload;
        state.getAllStationSummaryError = null;
      })
      .addCase(getAllStationSummaryAction.rejected, (state, action) => {
        state.getAllStationSummaryLoading = false;
        state.getAllStationSummaryError = action.payload as string;
        state.getAllStationSummaryResponse = null;
      });

    builder
      .addCase(getAllDayEndStationSummaryAction.pending, (state) => {
        state.dayEndStationSummaryLoading = true;
        state.dayEndStationSummaryError = null;
        state.dayEndStationSummaryResponse = null;
      })
      .addCase(getAllDayEndStationSummaryAction.fulfilled, (state, action) => {
        state.dayEndStationSummaryLoading = false;
        state.dayEndStationSummaryResponse = action.payload;
        state.dayEndStationSummaryError = null;
      })
      .addCase(getAllDayEndStationSummaryAction.rejected, (state, action) => {
        state.dayEndStationSummaryLoading = false;
        state.dayEndStationSummaryError = action.payload as string;
        state.dayEndStationSummaryResponse = null;
      });

    builder
      .addCase(endDayAction.pending, (state) => {
        state.endDayLoading = true;
        state.endDayError = null;
        state.endDayResponse = null;
      })
      .addCase(endDayAction.fulfilled, (state, action) => {
        state.endDayLoading = false;
        state.endDayResponse = action.payload;
        state.endDayError = null;
      })
      .addCase(endDayAction.rejected, (state, action) => {
        state.endDayLoading = false;
        state.endDayError = action.payload as string;
        state.endDayResponse = null;
      });

    builder
      .addCase(getCashierLatestSessionAction.pending, (state) => {
        state.cashierLatestSessionLoading = true;
        state.cashierLatestSessionError = null;
        state.cashierLatestSession = null;
      })
      .addCase(getCashierLatestSessionAction.fulfilled, (state, action) => {
        state.cashierLatestSessionLoading = false;
        state.cashierLatestSession = action.payload;
        state.cashierLatestSessionError = null;
      })
      .addCase(getCashierLatestSessionAction.rejected, (state, action) => {
        state.cashierLatestSessionLoading = false;
        state.cashierLatestSessionError = action.payload as string;
        state.cashierLatestSession = null;
      });
  },
});

export default cashierStationSlice.reducer;
export const {
  setActiveStation,
  setActiveStationCashier,
  clearOpenStationCashierResponse,
  setIsRegisterCleaned,
} = cashierStationSlice.actions;
