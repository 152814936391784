import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { DiscountSliceAction } from "../redux/slice/discountSlice";
import { DiscountMode, IDiscountResponse } from "../typings";
import { ICartSession } from "../typings/interfaces/pos/cart-session";

const useCart = () => {
  const dispatch = useAppDispatch();
  const { cart, selectedSession } = useAppSelector((state) => state.cart);
  const [selectedSessionDiscount, setSelectedSessionDiscount] = useState(0);
  const { customer } = useAppSelector((state) => state.customer);
  const { getDiscountByStoreResponse, eligibleDiscounts, selectedDiscount } =
    useAppSelector((state) => state.discount);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const sessionTotal =
    selectedSession?.cartItems.reduce(
      (acc, item) =>
        acc +
        item.unitPrice * item.qty +
        item.alterationItems.reduce(
          (altAcc, altItem) => altAcc + (altItem?.unitPrice ?? 0),
          0
        ) +
        item.upcharges.reduce(
          (upchargeAcc, upchargeItem) =>
            upchargeAcc + (upchargeItem?.subUpcharge.price ?? 0),
          0
        ) +
        item.spots.reduce(
          (spotAcc, spotItem) => spotAcc + (spotItem?.subSpot.price ?? 0),
          0
        ),
      0
    ) ?? 0;

  const cartTotal = useMemo(
    () =>
      cart.reduce((acc, session) => {
        return (
          acc +
          session.cartItems.reduce(
            (acc, item) =>
              acc +
              item.unitPrice * item.qty +
              item.alterationItems.reduce(
                (altAcc, altItem) => altAcc + (altItem?.unitPrice ?? 0),
                0
              ) +
              item.upcharges.reduce(
                (upchargeAcc, upchargeItem) =>
                  upchargeAcc + (upchargeItem?.subUpcharge.price ?? 0),
                0
              ) +
              item.spots.reduce(
                (spotAcc, spotItem) => spotAcc + (spotItem?.subSpot.price ?? 0),
                0
              ),
            0
          )
        );
      }, 0),
    [cart]
  );

  const getSessionTotalBySessionId = (sessionId: string) => {
    const session = cart.find((session) => session.sessionId === sessionId);
    return (
      session?.cartItems.reduce(
        (acc, item) =>
          acc +
          item.unitPrice * item.qty +
          item.alterationItems.reduce(
            (altAcc, altItem) => altAcc + (altItem?.unitPrice ?? 0),
            0
          ) +
          item.upcharges.reduce(
            (upchargeAcc, upchargeItem) =>
              upchargeAcc + (upchargeItem?.subUpcharge.price ?? 0),
            0
          ) +
          item.spots.reduce(
            (spotAcc, spotItem) => spotAcc + (spotItem?.subSpot.price ?? 0),
            0
          ),
        0
      ) ?? 0
    );
  };
  const getSessionTotalBySession = (session: ICartSession) => {
    return (
      session.cartItems.reduce(
        (acc, item) =>
          acc +
          item.unitPrice * item.qty +
          item.alterationItems.reduce(
            (altAcc, altItem) => altAcc + (altItem?.unitPrice ?? 0),
            0
          ) +
          item.upcharges.reduce(
            (upchargeAcc, upchargeItem) =>
              upchargeAcc + (upchargeItem?.subUpcharge.price ?? 0),
            0
          ) +
          item.spots.reduce(
            (spotAcc, spotItem) => spotAcc + (spotItem?.subSpot.price ?? 0),
            0
          ),
        0
      ) ?? 0
    );
  };

  const getSessionItemCount = (sessionId: string) => {
    return cart
      .find((session) => session.sessionId === sessionId)
      ?.cartItems.reduce((acc, item) => acc + item.qty, 0);
  };

  const getPieceCount = () => {
    let count = 0;
    selectedSession?.cartItems.forEach((item) => {
      count += item.qty;
    });
    return count.toString();
  };

  const getCartItemPrice = (sessionId: string, itemIndex: number) => {
    const session = cart.find((session) => session.sessionId === sessionId);
    const cartItem = session?.cartItems[itemIndex];
    if (cartItem)
      return (
        cartItem?.unitPrice * cartItem?.qty +
        cartItem.alterationItems.reduce(
          (acc, alt) => acc + (alt.unitPrice ?? 0),
          0
        ) +
        cartItem.upcharges.reduce(
          (acc, upc) => acc + upc.subUpcharge.price,
          0
        ) +
        cartItem.spots.reduce((acc, spt) => acc + (spt.subSpot.price ?? 0), 0)
      );
    else return 0;
  };

  // Assign eligible discount
  useEffect(() => {
    if (customer && getDiscountByStoreResponse && cartTotal) {
      const eligibleDiscount = getDiscountByStoreResponse.body.find(
        (discount) => {
          return (
            discount.minimumBillValue <= cartTotal &&
            discount.id === customer.discount
          );
        }
      );
      if (eligibleDiscount) {
        dispatch(DiscountSliceAction.setEligibleDiscount(eligibleDiscount));
      } else {
        dispatch(DiscountSliceAction.setEligibleDiscount(null));
      }
    }
  }, [cartTotal, getDiscountByStoreResponse, customer]);

  useEffect(() => {
    if (eligibleDiscounts) {
      const totalDiscountAmount =
        calculateTotalDiscountAmount(eligibleDiscounts);
      setTotalDiscountAmount(totalDiscountAmount);
    }
  }, [eligibleDiscounts]);

  useEffect(() => {
    const selectedSessionDiscount = calculateSelectedSessionDiscount();
    setSelectedSessionDiscount(selectedSessionDiscount);
  }, [eligibleDiscounts, selectedSession]);

  const calculateDiscountAmount = (
    total: number,
    type: DiscountMode,
    discountAmount: number
  ) => {
    if (type === DiscountMode.PERCENTAGE) {
      return Number(((total * discountAmount) / 100).toFixed(2));
    } else {
      return discountAmount;
    }
  };

  // Calculate discount amount
  const calculateTotalDiscountAmount = (discount: IDiscountResponse) => {
    const totalDiscountAmount = calculateDiscountAmount(
      cart
        .filter((session) => {
          return session.cartItems.some((item) =>
            discount.serviceIds.includes(item.serviceType?.id ?? 0)
          );
        })
        .reduce(
          (acc, session) => acc + getSessionTotalBySessionId(session.sessionId),
          0
        ),
      discount.discountMode,
      discount.discountAmount
    );
    return totalDiscountAmount;
  };

  const calculateSelectedSessionDiscount = () => {
    const isElegible = selectedSession?.cartItems.some((item) =>
      eligibleDiscounts?.serviceIds.includes(item.serviceType?.id ?? 0)
    );
    if (isElegible && eligibleDiscounts) {
      return calculateDiscountAmount(
        getSessionTotalBySessionId(selectedSession?.sessionId ?? ""),
        eligibleDiscounts.discountMode,
        eligibleDiscounts.discountAmount
      );
    } else {
      return 0;
    }
  };

  const getSessionDiscountById = (sessionId: string) => {
    const session = cart.find((session) => session.sessionId === sessionId);
    const isElegible = session?.cartItems.some((item) =>
      eligibleDiscounts?.serviceIds.includes(item.serviceType?.id ?? 0)
    );
    if (isElegible && eligibleDiscounts) {
      return calculateDiscountAmount(
        getSessionTotalBySessionId(sessionId),
        eligibleDiscounts.discountMode,
        eligibleDiscounts.discountAmount
      );
    }
    return 0;
  };
  const getSessionDiscountBySession = (session: ICartSession) => {
    const isElegible = session?.cartItems.some((item) =>
      eligibleDiscounts?.serviceIds.includes(item.serviceType?.id ?? 0)
    );
    if (isElegible && eligibleDiscounts) {
      return calculateDiscountAmount(
        getSessionTotalBySession(session),
        eligibleDiscounts.discountMode,
        eligibleDiscounts.discountAmount
      );
    }
    return 0;
  };

  return {
    getPieceCount,
    sessionTotal,
    cartTotal,
    getSessionTotalBySessionId,
    getSessionItemCount,
    getCartItemPrice,
    totalDiscountAmount,
    selectedSessionDiscount,
    getSessionDiscountById,
    selectedDiscount,
    getSessionTotalBySession,
    getSessionDiscountBySession,
  };
};

export default useCart;
