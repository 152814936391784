import { Button, Grid } from "@mui/material";
import { FC } from "react";
import { CategoryResponse } from "../../../../typings/interfaces/responses";
import { Colors } from "../../../../configs/colors";
import { CustomButton } from "../../../atoms";

export interface IServiceTabButtonProps {
  name: string;
  isSelected: boolean;
  handleOnClick(): void;
  disabled?: boolean;
}

const ServiceTabButton: FC<IServiceTabButtonProps> = ({
  name,
  isSelected,
  handleOnClick,
  disabled = false,
}) => {
  return (
    <Grid
      onClick={disabled ? () => {} : () => handleOnClick()}
      style={{
        textDecoration: "none",
        width: "9.85rem",
        height: "5.14rem",
        display: "inline-flex",
        flexShrink: 0,
        flexDirection: "row",
        cursor: isSelected ? "inherit" : "pointer",
        color: Colors.WHITE,
        pointerEvents: isSelected ? "none" : "auto",
      }}
    >
      <CustomButton
        sx={{
          maxWidth: "100%",
          whiteSpace: "nowrap",
          background: isSelected ? Colors.SECONDARY : Colors.PRIMARY,
          color: Colors.WHITE,
          minWidth: "9.85rem",
          height: "5.14rem",
          borderRadius: "0.71rem",
          textTransform: "capitalize",
          fontSize: "1.42rem",
          lineHeight: "1.42rem",
          padding: "0.57rem",
          "&:hover": {
            background: `${Colors.PRIMARY} !important`,
          },
          "&:disabled": {
            background: Colors.ACCENT_GRAY,
            color: Colors.WHITE,
          },
        }}
        disabled={disabled}
      >
        {name}
      </CustomButton>
    </Grid>
  );
};

export default ServiceTabButton;
