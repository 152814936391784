import React, { FC, useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { cloneDeep } from "lodash";

import { Colors } from "../../../../configs";
import { CustomButton } from "../../../atoms";
import SpotModal from "../../../pos/spot-modal/spotModal";
import { sortArray } from "../../../../util/array-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { getAllSpotsAction } from "../../../../redux/actions/spotsAction";
import { ISpotResponse } from "../../../../typings";
import { ISelectedSpot } from "../../../../typings/interfaces/pos/spot";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

export interface ISpotSectionProps {}

const SpotSection: FC<ISpotSectionProps> = () => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const { spots, spotsError, spotsLoading } = useAppSelector(
    (state) => state.spot
  );

  useEffect(() => {
    dispatch(getAllSpotsAction());
  }, []);

  useEffect(() => {
    if (spotsError) {
      snackbar.showSnackbar(spotsError, "error");
    }
  }, [spotsError]);

  const allSpots = [
    ...(spots || []),
    {
      id: 100000,
      merchantId: 1,
      storetId: 1,
      spot: "Custom",
      minorPrice: 100,
      majorPrice: 200,
      mediumPrice: 150,
      showNoFee: true,
      showCustomFee: true,
      deleted: false,
    },
  ];

  const spotPricesSorted = sortArray({
    array: allSpots,
    sortByKey: "spot",
    fixedFirstItem: "Custom",
  });

  const [spotModalOpen, setSpotModalOpen] = useState<{
    open: boolean;
    spot: ISpotResponse | null;
  }>({
    open: false,
    spot: null,
  });

  const handleCloseSpotModal = () => {
    setSpotModalOpen({ open: false, spot: null });
  };
  const handleOnClickSpot = (spot: ISpotResponse) => {
    setSpotModalOpen({ open: true, spot });
  };

  const handleAddSpot = (spot: ISelectedSpot) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      if (
        cartItem.spots.find((spotItem) => spotItem.id === spot.id) === undefined
      ) {
        // Add new spot
        cartItem.spots.push(spot);
      } else {
        // Update existing spot
        cartItem.spots = cartItem.spots.map((spotItem) =>
          spotItem.id === spot.id ? spot : spotItem
        );
      }
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };
  const handleRemoveSpot = (spot: ISelectedSpot) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.spots = cartItem.spots.filter(
        (spotItem) => spotItem.id !== spot.id
      );
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      {spotModalOpen.spot && (
        <SpotModal
          open={spotModalOpen.open}
          onClose={handleCloseSpotModal}
          spot={spotModalOpen.spot}
          onAdd={handleAddSpot}
          onRemove={handleRemoveSpot}
        />
      )}

      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Spot
      </Typography>
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(spotPricesSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {spotsLoading ? (
                  <CircularProgress sx={{ mx: "auto" }} />
                ) : (
                  spotPricesSorted
                    .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                    .map((spot, i) => (
                      <CustomButton
                        sx={{
                          width: "17.0064286rem",
                          background:
                            (selectedCartItem?.spots.filter(
                              (cartSpot) =>
                                cartSpot.id === (spot as ISpotResponse).id
                            ).length ?? 0) > 0
                              ? Colors.SECONDARY
                              : Colors.MAIN_GRAY,
                          borderRadius: "0.71rem",
                          textTransform: "capitalize",
                          color: Colors.WHITE,
                          fontSize: "1.14rem",
                          lineHeight: "1.33928571rem",
                          padding: "1.07142857rem 0.857142857rem",
                          "&&:hover": {
                            background: Colors.TEXT_GRAY_DARK,
                          },
                          height: "4.28571429rem",
                        }}
                        key={i}
                        onClick={() => handleOnClickSpot(spot as ISpotResponse)}
                      >
                        {(spot as ISpotResponse).spot}
                      </CustomButton>
                    ))
                )}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default SpotSection;
