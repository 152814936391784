import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import {
  BaseResponseSingle,
  IinstructionResponse,
} from "../../typings/interfaces/responses";
import {
  ADD_INSTRUCTIONS,
  DELETE_INSTRUCTION,
  GET_ALL_INSTRUCTIONS,
  UPDATE_INSTRUCTION,
} from "../../configs/apiConfig";
import { INewInstructionRequest } from "../../typings/interfaces/requests/instructionrequest";

export const getAllInstructionsAction = createAsyncThunk(
  "instructions/all-instructions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IinstructionResponse>>(
        GET_ALL_INSTRUCTIONS
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const CreateInstructionAction = createAsyncThunk(
  "instruction/create-instruction",
  async (
    {
      storeId,
      merchantId,
      instruction,
    }: { storeId: number; merchantId: number; instruction: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<
        BaseResponseSingle<IinstructionResponse>
      >(ADD_INSTRUCTIONS(merchantId, storeId), {
        merchantId,
        storeId,
        instruction,
      });
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteInstructionAction = createAsyncThunk<
  boolean,
  { merchantId: number; storeId: number; instructionId: number }
>(
  "instruction/delete-instruction",
  async ({ merchantId, storeId, instructionId }, { rejectWithValue }) => {
    try {
      await axios.delete(
        DELETE_INSTRUCTION(merchantId, storeId, instructionId)
      );
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const updateInstructionAction = createAsyncThunk<
  IinstructionResponse,
  {
    merchantId: number;
    storeId: number;
    id: number;
    instruction: string;
  } & INewInstructionRequest
>(
  "instruction/update-instruction",
  async ({ merchantId, storeId, id, instruction }, { rejectWithValue }) => {
    try {
      const response = await axios.put<
        BaseResponseSingle<IinstructionResponse>
      >(UPDATE_INSTRUCTION(merchantId, storeId), {
        id,
        merchantId,
        storeId,
        instruction,
      });

      return response.data.body;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
