import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import DayEndButtons from "./DayEndButtons";
import { DayEndLayout } from "./DayEndLayout";
import RefundModal from "./RefundModal";
import { Colors } from "../../../configs";
import {
  getAllDayEndStationSummaryAction,
  getStoreSummaryAction,
} from "../../../redux/actions/cashierStationActions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IcashierOnboardingResponse } from "../../../typings";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { pxToRem } from "../../../util";

const table1Data = [
  {
    station: 1,
    openingBalance: 56.0,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    monthlyCharge: 0.0,
    discounts: 0.0,
    sales: 56.0,
    registerTotal: 56.0,
    refunds: 56.0,
  },
  {
    station: 2,
    openingBalance: 138.0,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    monthlyCharge: 0.0,
    discounts: 0.0,
    sales: 138.0,
    registerTotal: 138.0,
    refunds: 156.0,
  },
  {
    station: 3,
    openingBalance: 138.0,
    cash: 0.0,
    check: 0.0,
    creditCard: 0.0,
    monthlyCharge: 0.0,
    discounts: 0.0,
    sales: 138.0,
    registerTotal: 138.0,
    refunds: 86.0,
  },
];

const transactionsRowData = {
  station: "Total no of Transactions",
  openingBalance: 0,
  cash: 0,
  check: 0,
  creditCard: 0,
  monthlyCharge: 0,
  discounts: 0,
  sales: 0,
  registerTotal: 0,
  refunds: 0,
};

const totalRowData = {
  station: "Total",
  openingBalance: 0,
  cash: 0.0,
  check: 0.0,
  creditCard: 0.0,
  monthlyCharge: 0.0,
  discounts: 0.0,
  sales: 0,
  registerTotal: 0,
  refunds: 0,
};

const table2Data = [
  {
    category: "Dry Cleaning",
    ticketsIn: 6,
    itemsIn: 1,
    ticketsOut: 1,
    itemsOut: 5,
  },
  {
    category: "Laundry",
    ticketsIn: 6,
    itemsIn: 30,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Shoe Repair",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Household",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Alterations",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Fur/Leather",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Costumes",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Bed Wash & Fold",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
  {
    category: "Miscellaneous",
    ticketsIn: 0,
    itemsIn: 0,
    ticketsOut: 0,
    itemsOut: 0,
  },
];

const total2RowData = { ticketsIn: 7, itemsIn: 36, ticketsOut: 1, itemsOut: 5 };

const DayEndPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [refundModalOpen, setRefundModalOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState<any>(null);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const {
    getStoreSummaryError,
    getStoreSummaryResponse,
    dayEndStationSummaryResponse,
    dayEndStationSummaryLoading,
    dayEndStationSummaryError,
  } = useAppSelector((state) => state.cashierStation);

  const handleOpenRefundModal = (rowData: any) => {
    setSelectedRowData(rowData);
    setRefundModalOpen(true);
  };

  const handleCloseRefundModal = () => {
    setRefundModalOpen(false);
    setSelectedRowData(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabTitles = ["Outgoing", "Orders-in"];

  const commonCellStyles = {
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0.5rem",
    height: "3.714285714285714rem",
  };

  const tableStyles = {
    cell: commonCellStyles,
    headerCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.SECONDARY,
      ...commonCellStyles,
    },
    bodyCell: {
      color: Colors.MAIN_GRAY,
      backgroundColor: Colors.ACCENT_GRAY,
      ...commonCellStyles,
    },
    totalRowCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.MAIN_GRAY,
      ...commonCellStyles,
    },
  };

  useEffect(() => {
    const localSession = localStorage.getItem("cashier");
    const parsedSession: IcashierOnboardingResponse | null = localSession
      ? JSON.parse(localSession)
      : null;

    parsedSession && dispatch(getStoreSummaryAction(parsedSession.storeId));
    parsedSession &&
      dispatch(
        getAllDayEndStationSummaryAction({ storeId: parsedSession.storeId })
      );
  }, []);

  useEffect(() => {
    if (getStoreSummaryError) {
      snackbar.showSnackbar(getStoreSummaryError, "error");
    }
  }, [getStoreSummaryError]);

  useEffect(() => {
    if (dayEndStationSummaryError) {
      snackbar.showSnackbar(dayEndStationSummaryError, "error");
    }
  }, [dayEndStationSummaryError]);

  const { isRegisterCleaned } = useAppSelector((state) => state.cashierStation);

  const preparedTableData = isRegisterCleaned
    ? dayEndStationSummaryResponse?.map((item) => ({
        station: item.stationName,
        openingBalance: 0,
        cash: 0,
        check: 0,
        creditCard: 0,
        monthlyCharge: 0,
        discounts: 0,
        sales: 0,
        registerTotal: 0,
        refunds: 0,
      }))
    : dayEndStationSummaryResponse?.map((item) => ({
        station: item.stationName,
        openingBalance: item.openingTotal,
        cash: item.cashPaymentsTotal,
        check: item.chequePaymentsTotal,
        creditCard: item.cardPaymentsTotal,
        monthlyCharge: item.monthlyPaymentTotal,
        discounts: item.discounts,
        sales: item.totalSales,
        registerTotal: item.registerTotal,
        refunds: item.refunds,
      }));

  return (
    <DayEndLayout isDayEndPage>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          marginLeft: "6rem",
          marginTop: "-3rem",
          "& .MuiTab-root": {
            background: Colors.BACKSHADE_GRAY,
            color: Colors.PRIMARY,
            height: "3.428571428571429rem",
            minHeight: "3.428571428571429rem",
            width: "10.78571428571429rem",
            padding: 0,
            textTransform: "none",
          },
          "& .Mui-selected": {
            background: Colors.PRIMARY,
            color: `${Colors.WHITE} !important`,
          },
          "& .MuiTab-root:first-of-type": {
            borderRadius: "0.71rem 0 0 0",
          },
          "& .MuiTab-root:last-of-type": {
            borderRadius: "0 0.71rem 0 0",
          },
        }}
      >
        {tabTitles.map((title, index) => (
          <Tab
            key={index}
            label={title}
            sx={{ fontWeight: "bold", fontSize: "1.428571428571429rem" }}
          />
        ))}
      </Tabs>

      {/* Main Content Section */}
      <Box
        sx={{
          backgroundColor: Colors.BACKSHADE_GRAY,
          margin: "2rem",
          width: "120.7142857142857rem",
          height: "52.92857142857143rem",
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
          gap: "2rem",
          borderRadius:
            "0 0.7142857142857143rem 0.7142857142857143rem 0.7142857142857143rem",
          padding: "2rem",
          marginTop: "-4.7rem",
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            padding: "1rem",
            marginTop: "3rem",
          }}
        >
          {selectedTab === 0 && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  overflow: "auto",
                  borderRadius: "0.714rem",
                  maxHeight: pxToRem(387.2),
                  // make scrollbar beautiful
                  "&::-webkit-scrollbar": {
                    width: "0.5rem",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: Colors.BACKSHADE_GRAY,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: Colors.MAIN_GRAY,
                    borderRadius: "0.357rem",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: Colors.SECONDARY,
                  },
                }}
              >
                <Table
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0.2rem",
                    width: "100%",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {[
                        "Station",
                        "Opening Balance",
                        "Cash",
                        "Check",
                        "Credit Card",
                        "Monthly Charge",
                        "Discounts",
                        "Sales",
                        "Register Total",
                        "Refunds",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            ...tableStyles.headerCell,
                            ...(index === 0
                              ? { borderRadius: "0.357rem 0 0 0" }
                              : index === 9
                              ? { borderRadius: "0 0.357rem 0 0" }
                              : {}),
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {preparedTableData?.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {Object.values(row).map((value, colIndex) => (
                          <TableCell
                            key={colIndex}
                            sx={
                              colIndex === 9
                                ? { padding: 0, display: "flex" }
                                : tableStyles.bodyCell
                            }
                          >
                            {colIndex === 9 ? (
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: Colors.ACCENT_GRAY,
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    textAlign: "center",
                                    padding: "0.5rem",
                                    backgroundColor: Colors.ACCENT_GRAY,
                                  }}
                                >
                                  {typeof value === "number"
                                    ? value.toFixed(2)
                                    : value}
                                </div>

                                <div
                                  style={{
                                    flex: 1,
                                    backgroundColor: Colors.SECONDARY,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "3.714285714285714rem",
                                    width: "3.428571428571429rem",
                                  }}
                                >
                                  <VisibilityIcon
                                    sx={{
                                      color: Colors.WHITE,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleOpenRefundModal(row)} // Pass the row data
                                  />
                                </div>
                              </div>
                            ) : colIndex === 9 ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>
                                  {typeof value === "number"
                                    ? value.toFixed(2)
                                    : value}
                                </span>

                                <VisibilityIcon
                                  sx={{
                                    color: Colors.SECONDARY,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleOpenRefundModal(row)}
                                />
                              </div>
                            ) : typeof value === "number" ? (
                              value.toFixed(2)
                            ) : (
                              value
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    {dayEndStationSummaryLoading ? (
                      <TableRow>
                        <TableCell sx={tableStyles.bodyCell} colSpan={10}>
                          <CircularProgress sx={{ mx: "auto" }} />
                        </TableCell>
                      </TableRow>
                    ) : null}

                    <TableRow>
                      {Object.values(transactionsRowData).map(
                        (value, index) => (
                          <TableCell
                            key={index}
                            sx={
                              index === 9
                                ? { padding: 0, display: "flex" }
                                : tableStyles.bodyCell
                            }
                          >
                            {index === 9 ? (
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    textAlign: "center",
                                    padding: "0.5rem",
                                    backgroundColor: Colors.ACCENT_GRAY,
                                  }}
                                >
                                  {value}
                                </div>

                                <div
                                  style={{
                                    flex: 1,
                                    backgroundColor: Colors.SECONDARY,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "3.714285714285714rem",
                                    width: "3.428571428571429rem",
                                  }}
                                >
                                  <VisibilityIcon
                                    sx={{
                                      color: Colors.WHITE,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleOpenRefundModal(transactionsRowData)
                                    } // Pass row data
                                  />
                                </div>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>

                    <TableRow>
                      {Object.values(totalRowData).map((value, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            ...tableStyles.totalRowCell,
                            ...(index === 0
                              ? { borderRadius: "0 0 0 0.357rem" }
                              : index === 9
                              ? { borderRadius: "0 0 0.357rem 0" }
                              : {}),
                          }}
                        >
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Summary Section */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "2.5rem",
                  border: "0.0714285714285714rem solid",
                  borderColor: Colors.MAIN_GRAY,
                  borderRadius: "0.7142857142857143rem",
                  padding: "3.5rem",
                  marginTop: "5rem",
                }}
              >
                {[
                  {
                    label: "Total number of Customers",
                    value: getStoreSummaryResponse?.totalCustomers ?? "N/A",
                  },
                  {
                    label: "Total number of New Customers",
                    value: getStoreSummaryResponse?.newCustomers ?? "N/A",
                  },
                  { label: "Total number of Pickups", value: "N/A" },
                  {
                    label: "Total number of Drop offs",
                    value: getStoreSummaryResponse?.["drop-offs"] ?? "N/A",
                  },
                ].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "1.428571428571429rem",
                      color: Colors.MAIN_GRAY,
                      fontWeight: "bold",
                      marginLeft: "3rem",
                      marginRight: "5rem",
                    }}
                  >
                    <span>{item.label}</span>
                    <span style={{ fontWeight: "bold" }}>: {item.value}</span>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowX: "auto",
              }}
            >
              <TableContainer
                sx={{
                  overflow: "auto",
                  borderRadius: "0.714rem",
                }}
              >
                <Table
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0.2rem",
                    width: "100%",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {[
                        "Category",
                        "Tickets In",
                        "Items In",
                        "Tickets Out",
                        "Items Out",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            ...tableStyles.headerCell,
                            ...(index === 0
                              ? { borderRadius: "0.357rem 0 0 0" }
                              : index === 4
                              ? { borderRadius: "0 0.357rem 0 0" }
                              : {}),
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {table2Data.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell sx={tableStyles.bodyCell}>
                          {row.category}
                        </TableCell>
                        <TableCell sx={tableStyles.bodyCell}>
                          {row.ticketsIn}
                        </TableCell>
                        <TableCell sx={tableStyles.bodyCell}>
                          {row.itemsIn}
                        </TableCell>
                        <TableCell sx={tableStyles.bodyCell}>
                          {row.ticketsOut}
                        </TableCell>
                        <TableCell sx={tableStyles.bodyCell}>
                          {row.itemsOut}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell
                        sx={{
                          ...tableStyles.totalRowCell,
                          borderRadius: "0 0 0 0.357rem",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell sx={tableStyles.totalRowCell}>
                        {total2RowData.ticketsIn}
                      </TableCell>
                      <TableCell sx={tableStyles.totalRowCell}>
                        {total2RowData.itemsIn}
                      </TableCell>
                      <TableCell sx={tableStyles.totalRowCell}>
                        {total2RowData.ticketsOut}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableStyles.totalRowCell,
                          borderRadius: "0 0 0.357rem 0",
                        }}
                      >
                        {total2RowData.itemsOut}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
        <DayEndButtons />
      </Box>

      <RefundModal open={refundModalOpen} onClose={handleCloseRefundModal} />
    </DayEndLayout>
  );
};

export default DayEndPage;
