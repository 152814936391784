import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import pricingSlice from "./slice/pricingSlice";
import cartSlice from "./slice/cartSlice";
import posSlice from "./slice/posSlice";
import customerSlice from "./slice/customerSlice";
import rackSlice from "./slice/rackSlice";
import discountSlice from "./slice/discountSlice";
import merchantSlice from "./slice/merchantSlice";
import masterSlice from "./slice/masterSlice";
import storeSlice from "./slice/storeSlice";
import pinTagSlice from "./slice/pintagSlice";
import instructionsSlice from "./slice/instructionSlice";
import damagesSlice from "./slice/damagesSlice";
import spotsSlice from "./slice/spotSlice";
import upchargeSliceActions from "./slice/upchargeSlice";
// import cashierSlice from "./slice/cashierSlice";
// import cashRegisterReducer from "./slice/CashRegisterSlice";
import cashierStationSlice from "./slice/cashierStationSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    pricing: pricingSlice,
    cart: cartSlice,
    pos: posSlice,
    customer: customerSlice,
    rack: rackSlice,
    pintag: pinTagSlice,
    discount: discountSlice,
    merchant: merchantSlice,
    master: masterSlice,
    store: storeSlice,
    instruction: instructionsSlice,
    damage: damagesSlice,
    spot: spotsSlice,
    upcharge: upchargeSliceActions,
    // cashier: cashierSlice,
    // cashRegister: cashRegisterReducer,
    cashierStation: cashierStationSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
