import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { OnboardingLayout } from "../../../components/layouts/onboarding-layout/OnboardingLayout";
import { CustomButton } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
// import { cashierOnboardingAction } from "../../../redux/actions/cashierOnbooardingActions";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import { Colors } from "../../../configs/colors";

import onboardingImg from "../../../assets/images/onboardingImg.svg";
import { IcashierOnboardingResponse } from "../../../typings";
import { StationTypes } from "../../../typings/enum/stationTypes";
import {
  getStationByIdAction,
  requestNewStationAction,
} from "../../../redux/actions/cashierStationActions";
import { INewCashierResponse } from "../../../typings/interfaces/responses/cashierStationResponses";
import { setActiveStation } from "../../../redux/slice/cashierStationSlice";
// import { getStationAccessAction } from "../../../redux/actions/cashierStationActions";
// import { setActiveStation } from "../../../redux/slice/cashierSlice";

const validationSchema = yup.object().shape({
  store: yup.number().required("Store selection is required"),
  deviceName: yup.string().required("Device name is required"),
  deviceType: yup.string().required("Device type selection is required"),
});

type FormData = yup.InferType<typeof validationSchema>;

export const OnboardingPage: React.FC = () => {
  const [selectedDevice, setSelectedDevice] = useState();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const navigate = useNavigate();

  const [isFormHidden, setIsFormHidden] = useState(false);
  const [checkStatus, setCheckStatus] = useState<"DENIED" | "PENDING" | null>(
    null
  );
  const [isInstalled, setIsInstalled] = useState(false);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const storeOptions = [{ label: "Presto Valet", value: 1 }];

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      store: 1,
      deviceType: "cashier",
    },
  });

  // const handleDeviceChange = (event: any) => {
  //   setSelectedDevice(event.target.value);
  // };

  const onSubmit = (data: FormData) => {
    dispatch(
      requestNewStationAction({
        storeId: Number(data.store),
        merchantId: 1,
        deviceName: data.deviceName,
        deviceType: data.deviceType as StationTypes,
      })
    );
  };

  const {
    requestNewCahierResponse,
    requestNewCahierError,
    requestNewCahierLoading,
    activeStation,
    getCashierStationByIdResponse,
    getCashierStationByIdLoading,
  } = useAppSelector((state) => state.cashierStation);

  useEffect(() => {
    if (requestNewCahierError) {
      snackbar.showSnackbar(requestNewCahierError, "error");
    }
  }, [requestNewCahierError]);

  useEffect(() => {
    if (requestNewCahierResponse) {
      setIsFormSubmitted(true);
      const dtJSON = JSON.stringify(requestNewCahierResponse);
      localStorage.setItem("cashier", dtJSON);

      setIsFormHidden(true);
    }
  }, [requestNewCahierResponse]);

  useEffect(() => {
    if (activeStation) {
      setIsFormSubmitted(true);
      setIsFormHidden(true);
      if (activeStation?.accessGranted === "GRANTED") {
        navigate("/pos/lobby");
      } else {
        setCheckStatus(
          activeStation?.accessGranted
            ? (activeStation.accessGranted as "DENIED" | "PENDING")
            : null
        );
      }
    }
  }, [activeStation]);

  useEffect(() => {
    if (getCashierStationByIdResponse) {
      localStorage.setItem(
        "cashier",
        JSON.stringify(getCashierStationByIdResponse)
      );
      setTimeout(() => {
        // To Do : this have to be removed its a bad practice. this is here to prevent the error of dispatching before the authorization token is set
        dispatch(setActiveStation(getCashierStationByIdResponse));
      }, 0);
    }
  }, [getCashierStationByIdResponse]);

  const checkIsAccessGranted = () => {
    const localData = localStorage.getItem("cashier");
    const parsedData: INewCashierResponse | null = localData
      ? JSON.parse(localData)
      : null;
    if (parsedData) {
      setIsFormHidden(true);
      setIsFormSubmitted(true);
      setTimeout(() => {
        // To Do : this have to be removed its a bad practice. this is here to prevent the error of dispatching before the authorization token is set
        dispatch(getStationByIdAction(parsedData.id));
      }, 10);
    }
  };

  useEffect(() => {
    checkIsAccessGranted();
  }, []);

  // useEffect(() => {
  //   if (isCashierOpen) {
  //     const savedStatus = localStorage.getItem("cashier");
  //     if (!savedStatus) {
  //       return;
  //     }
  //     const parsedStatus: IcashierOnboardingResponse = JSON.parse(savedStatus);
  //     localStorage.setItem(
  //       "cashier",
  //       JSON.stringify({
  //         ...parsedStatus,
  //         isAccessGranted: true,
  //       })
  //     );
  //     dispatch(
  //       setActiveStation(
  //         JSON.stringify({
  //           ...parsedStatus,
  //           isAccessGranted: true,
  //         })
  //       )
  //     );
  //     navigate("/pos/lobby");
  //   }
  // }, [isCashierOpen]);

  // useEffect(() => {
  //   if (isCashierOpenError)
  //     snackbar.showSnackbar(
  //       `Failed to check cashier status: ${isCashierOpenError}`,
  //       "error"
  //     );
  // }, [isCashierOpenError]);

  return (
    <OnboardingLayout>
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
        }}
        direction="row"
        wrap="nowrap"
      >
        <Grid
          item
          sx={{
            width: "55.85714285714286rem",
            backgroundColor: Colors.BACKSHADE_GRAY,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={onboardingImg}
            alt="Onboarding Illustration"
            sx={{
              height: "35.71428571428571rem",
              width: "35.71428571428571rem",
            }}
          />
        </Grid>

        <Grid
          item
          sx={{
            flex: 1,
            padding: "2rem 4rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "52.35714285714286rem",
              marginLeft: "10.5rem",
              marginTop: "8rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "0.1rem",
                  backgroundColor: Colors.PRIMARY,
                  width: "100%",
                  position: "absolute",
                  zIndex: 0,
                }}
              />

              <Box
                sx={{
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    backgroundColor: Colors.WHITE,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: Colors.PRIMARY,
                      fontSize: "3rem",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    backgroundColor: Colors.WHITE,
                  }}
                >
                  {isFormSubmitted ? (
                    <CheckCircleIcon
                      sx={{
                        color: Colors.PRIMARY,
                        fontSize: "3rem",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "2.6rem",
                        width: "2.6rem",
                        borderRadius: "50%",
                        backgroundColor: Colors.WHITE,
                        color: Colors.PRIMARY,
                        fontSize: "1rem",
                        border: "0.1rem solid",
                        borderColor: Colors.PRIMARY,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        position: "relative",
                      }}
                    >
                      2
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    backgroundColor: Colors.WHITE,
                  }}
                >
                  {isInstalled ? (
                    <CheckCircleIcon
                      sx={{
                        color: Colors.PRIMARY,
                        fontSize: "3rem",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "2.6rem",
                        width: "2.6rem",
                        borderRadius: "50%",
                        backgroundColor: Colors.WHITE,
                        color: Colors.PRIMARY,
                        fontSize: "1rem",
                        border: "0.1rem solid",
                        borderColor: Colors.PRIMARY,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        position: "relative",
                      }}
                    >
                      3
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Grid
            item
            sx={{
              flex: 1,
              padding: "2rem 4rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "52.35714285714286rem",
                height: "36.92857142857143rem",
                border: `0.0714285714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                borderRadius: "0.7142857142857143rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5rem",
                marginBottom: "4rem",
              }}
            >
              {isFormHidden ? (
                <>
                  <Typography
                    sx={{ fontSize: "2rem", color: Colors.MAIN_GRAY }}
                  >
                    {checkStatus === "DENIED"
                      ? "Access Denied"
                      : "Access Requested"}
                  </Typography>
                  <Typography variant="body2" sx={{ color: Colors.MAIN_GRAY }}>
                    {checkStatus === "DENIED"
                      ? "Your request has been rejected by the admin."
                      : "Your request has been sent to the admin for approval."}
                  </Typography>
                  {isInstalled && checkStatus !== "DENIED" && (
                    <CustomButton
                      sx={{
                        backgroundColor: Colors.PRIMARY,
                        marginTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        width: "28.85714285714286rem",
                        height: "4.571428571428571rem",
                      }}
                      onClick={checkIsAccessGranted}
                      disabled={
                        getCashierStationByIdLoading || requestNewCahierLoading
                      }
                    >
                      {getCashierStationByIdLoading ||
                      requestNewCahierLoading ? (
                        <CircularProgress sx={{ color: Colors.WHITE }} />
                      ) : (
                        "Check Approval Status"
                      )}
                    </CustomButton>
                  )}
                  {!isInstalled && checkStatus !== "DENIED" && (
                    <CustomButton
                      sx={{
                        backgroundColor: Colors.PRIMARY,
                        marginTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        width: "28.85714285714286rem",
                        height: "4.571428571428571rem",
                      }}
                      onClick={() => {
                        alert("Installing");
                        setIsInstalled(true);
                      }}
                      disabled={
                        getCashierStationByIdLoading || requestNewCahierLoading
                      }
                    >
                      {getCashierStationByIdLoading ||
                      requestNewCahierLoading ? (
                        <CircularProgress sx={{ color: Colors.WHITE }} />
                      ) : (
                        "Install App"
                      )}
                    </CustomButton>
                  )}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingX: "0",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2.285714285714286rem",
                        color: Colors.MAIN_GRAY,
                        textAlign: "center",
                        marginLeft: "10rem",
                        marginTop: "3rem",
                        marginBottom: "2rem",
                      }}
                    >
                      Presto Valet
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleSubmit(onSubmit)}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.6rem",
                        marginTop: "1rem",
                      }}
                    >
                      {/* Store Selection */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.428571428571429rem",
                            color: Colors.MAIN_GRAY,
                            width: "10.5rem",
                          }}
                        >
                          Store
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "28.857rem",
                          }}
                        >
                          <FormControl
                            sx={{
                              width: "28.85714285714286rem",
                              height: "3.428571428571429rem",
                              "& .MuiOutlinedInput-root": {
                                height: "3.428571428571429rem",
                              },
                            }}
                          >
                            <Controller
                              name="store"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  displayEmpty
                                  sx={{
                                    width: "28.85714285714286rem",
                                    height: "3.428571428571429rem",
                                  }}
                                >
                                  {storeOptions.map((store) => (
                                    <MenuItem
                                      key={store.value}
                                      value={store.value}
                                    >
                                      {store.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />

                            {errors.store && (
                              <Typography color="error">
                                {errors.store.message}
                              </Typography>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {/* Device Name Input */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.428571428571429rem",
                            color: Colors.MAIN_GRAY,
                            width: "10.5rem",
                          }}
                        >
                          Device Name
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "28.857rem",
                          }}
                        >
                          <Controller
                            name="deviceName"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                variant="outlined"
                                fullWidth
                                sx={{
                                  width: "28.85714285714286rem",
                                  height: "3.428571428571429rem",
                                  "& .MuiOutlinedInput-root": {
                                    height: "3.428571428571429rem",
                                  },
                                }}
                              />
                            )}
                          />
                          {errors.deviceName && (
                            <Typography
                              color="error"
                              sx={{ marginTop: "0.5rem" }}
                            >
                              {errors.deviceName.message}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      {/* Device type Selection*/}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.428571428571429rem",
                            color: Colors.MAIN_GRAY,
                            width: "10.5rem",
                          }}
                        >
                          Device Type
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "28.857rem",
                          }}
                        >
                          <FormControl
                            sx={{
                              width: "28.85714285714286rem",
                              height: "3.428571428571429rem",
                              "& .MuiOutlinedInput-root": {
                                height: "3.428571428571429rem",
                              },
                            }}
                          >
                            <Controller
                              name="deviceType"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  displayEmpty
                                  sx={{
                                    width: "28.85714285714286rem",
                                    height: "3.428571428571429rem",
                                  }}
                                >
                                  <MenuItem value="cashier">
                                    Cashier Device
                                  </MenuItem>
                                  <MenuItem value="non-cashier">
                                    Non-cashier Device
                                  </MenuItem>
                                </Select>
                              )}
                            />
                            {errors.deviceType && (
                              <Typography color="error">
                                {errors.deviceType.message}
                              </Typography>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {/* Submit Button */}
                      <CustomButton
                        type="submit"
                        sx={{
                          backgroundColor: Colors.PRIMARY,
                          marginLeft: "11.7rem",
                          marginTop: "2rem",
                          fontSize: "1.714285714285714rem",
                          width: "28.85714285714286rem",
                          minWidth: "28.85714285714286rem",
                          maxWidth: "28.85714285714286rem",
                          height: "4.571428571428571rem",
                          padding: "0",
                        }}
                        disabled={requestNewCahierLoading}
                      >
                        {requestNewCahierLoading ? (
                          <CircularProgress sx={{ color: Colors.WHITE }} />
                        ) : (
                          "Request Access"
                        )}
                      </CustomButton>
                    </Box>
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: "52.35714285714286rem",
                height: "6.071428571428571rem",
                border: `0.0714285714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                borderRadius: "0.7142857142857143rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "1rem",
                fontSize: "0.8571428571428571rem",
                color: Colors.MAIN_GRAY,
                paddingLeft: "3rem",
                paddingRight: "3rem",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </OnboardingLayout>
  );
};
