import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { TopBar } from "../../common/top-bar/topBar";

export interface IPostLayoutProps {
  children: JSX.Element;
}

const PosLayout: FC<IPostLayoutProps> = ({ children }) => {
  return (
    <Grid
      sx={{
        height: "100%",
        width: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <TopBar />
      <Box sx={{ padding: "2.14285714rem", flex: 1 }}>{children}</Box>
    </Grid>
  );
};

export { PosLayout };
