import { FC, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import TouchAppIcon from "@mui/icons-material/TouchApp";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCustomerBillHistoryAction } from "../../../redux/actions/customerAction";
import { ICustomerBill } from "../../../typings";
import { getBillByIdAction } from "../../../redux/actions/cartAction";
import PintagTableRow from "./pintag-table-row";
import { Colors } from "../../../configs";

export interface IPintagTableProps {
  contentHeight?: string;
}

const PintagTable: FC<IPintagTableProps> = ({ contentHeight = "9.07rem" }) => {
  const params = useParams();
  const customerId = params.id;
  const queryParams = new URLSearchParams(window.location.search);
  const billId = queryParams.get("billId");
  const dispatch = useAppDispatch();
  const { bills, billsLoading } = useAppSelector((state) => state.customer);
  const [clickedRow, setClickedRow] = useState<ICustomerBill | null>(null);
  const rows = Array(20).fill(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<ICustomerBill | null>(
    null
  );

  // Log fetched bills
  useEffect(() => {
    console.log("Bills fetched: ", bills);
  }, [bills]);

  // useEffect(() => {
  //   if (billId) {
  //     setClickedRow({
  //       billId: billId,
  //       id: Number(billId),
  //       date: "12/12/2023",
  //       status: "Paid",
  //       cashier: "Cashier 01",
  //       details: "Sample description of the invoice.",
  //       amount: 150.75,
  //     });
  //   }
  // }, [billId]);

  // useEffect(() => {
  //   if (clickedRow?.id)
  //     dispatch(getBillByIdAction({ billId: String(clickedRow?.id) }));
  // }, [clickedRow]);

  // useEffect(() => {
  //   getCustommerBillList();
  // }, [customerId]);

  // const getCustommerBillList = () => {
  //   if (customerId) dispatch(getCustomerBillHistoryAction(customerId));
  // };

  const styles = {
    tableHeadingCell: {
      borderRadius: "0",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
      height: "2.92857142857rem",
    },
  };

  const handleOnClickRow = (data: ICustomerBill) => {
    setClickedRow(data);
  };

  const handleViewClick = (data: ICustomerBill) => {
    setSelectedInvoice(data);
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKSHADE_GRAY,
        borderRadius: "0",
        padding: "0.71rem",
        gap: "1.14rem",
        height: "44rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "0.21rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid container sx={{ gap: "0.15rem", flexWrap: "nowrap" }}>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "2.92857142857rem",
                    maxWidth: "2.92857142857rem !important",
                    borderTopLeftRadius: "0.71rem",
                  }}
                >
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "6.606rem",
                    maxWidth: "6.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pintag
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "6.606rem",
                    maxWidth: "6.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Invoice
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "15.606rem",
                    maxWidth: "15.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Customer
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "9.826rem",
                    maxWidth: "9.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Date In
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "12.606rem",
                    maxWidth: "12.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pickup Cashier
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "12.606rem",
                    maxWidth: "12.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Drop Cashier
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "9.826rem",
                    maxWidth: "9.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Due date
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.606rem",
                    maxWidth: "7.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pieces
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.606rem",
                    maxWidth: "7.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Rack
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "9.826rem",
                    maxWidth: "9.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pickup Date
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "20.826rem",
                    maxWidth: "20.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Description
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "2.92857142857rem",
                    maxWidth: "2.92857142857rem !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: "0.71rem",
                  }}
                >
                  <TouchAppIcon sx={{ color: "white" }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: contentHeight }}>
            <Box
              sx={{
                maxHeight: "40rem",
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: "1.6rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                  border: "0.6rem solid transparent",
                  backgroundClip: "padding-box",
                  minHeight: "3rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
              }}
            >
              {rows.map((_, index) => (
                <PintagTableRow
                  key={index}
                  firstRow={index === 0}
                  lastRow={index === rows.length - 1}
                  isSelected={false}
                  onClick={() => console.log(`Row ${index + 1} clicked`)}
                  onViewClick={() =>
                    console.log(`View button clicked for row ${index + 1}`)
                  }
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PintagTable;
