import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
} from "@mui/material";

import DayEndButtons from "./DayEndButtons";
import { DayEndLayout } from "./DayEndLayout";
import CustomDateTimePicker from "../../../components/atoms/custom-date-time-picker/custom-date-time-picker";
import RegisterBalancingTable, {
  IModifiedTableData,
} from "./RegisterBalancingTable";
import { Colors } from "../../../configs";
import { formatDate, pxToRem } from "../../../util";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import {
  endDayAction,
  getAllDayEndStationSummaryAction,
} from "../../../redux/actions/cashierStationActions";
import {
  IDayEndRequest,
  IStationBalancing,
} from "../../../typings/interfaces/requests/cashierStationRequests";
import { useNavigate } from "react-router-dom";
import { ICashierPoint } from "../../../typings";
import { RegisterNoteModal } from "./RegisterNoteModal";

interface IUpdatedData extends IModifiedTableData {
  imbalanceReason: string;
}

const RegisterBalancingPage: React.FC = () => {
  const [isRegisterBalancingClicked, setIsRegisterBalancingClicked] =
    useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [updatedData, setUpdatedData] = useState<IUpdatedData[]>([]);
  const [registerNoteModalOpen, setRegisterNoteModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const navigate = useNavigate();
  const {
    dayEndStationSummaryError,
    dayEndStationSummaryResponse,
    dayEndStationSummaryLoading,
    endDayError,
    endDayResponse,
  } = useAppSelector((state) => state.cashierStation);

  const commonCellStyles = {
    textAlign: "center",
    verticalAlign: "middle",
    padding: "0.5rem",
    height: "3.714285714285714rem",
  };

  const tableStyles = {
    cell: commonCellStyles,
    headerCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.SECONDARY,
      ...commonCellStyles,
    },
    bodyCell: {
      color: Colors.MAIN_GRAY,
      backgroundColor: Colors.WHITE,
      ...commonCellStyles,
    },
    totalRowCell: {
      color: Colors.WHITE,
      backgroundColor: Colors.MAIN_GRAY,
      ...commonCellStyles,
    },
  };

  useEffect(() => {
    dispatch(getAllDayEndStationSummaryAction({ storeId: 1, date }));
  }, [date]);

  useEffect(() => {
    if (dayEndStationSummaryError) {
      snackbar.showSnackbar(dayEndStationSummaryError, "error");
    }
  }, [dayEndStationSummaryError]);

  const preparedTable1Data = dayEndStationSummaryResponse?.map((station) => ({
    station: station.cashierPointId,
    openingBalance: station.openingTotal,
    cash: station.cashPaymentsTotal,
    check: station.chequePaymentsTotal,
    creditCard: station.cardPaymentsTotal,
    monthlyCharge: station.monthlyPaymentTotal,
    discounts: station.discounts,
    sales: station.totalSales,
    registerTotal: station.registerTotal,
  }));

  const totalRowData = {
    station: "Total",
    openingBalance: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.openingTotal,
      0
    ),
    cash: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.cashPaymentsTotal,
      0
    ),
    check: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.chequePaymentsTotal,
      0
    ),
    creditCard: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.cardPaymentsTotal,
      0
    ),
    monthlyCharge: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.monthlyPaymentTotal,
      0
    ),
    discounts: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.discounts,
      0
    ),
    sales: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.totalSales,
      0
    ),
    registerTotal: [...(dayEndStationSummaryResponse ?? [])].reduce(
      (acc, curr) => acc + curr.registerTotal,
      0
    ),
  };

  const handleSave = () => {
    const parsedActiveCashier: ICashierPoint = JSON.parse(
      localStorage.getItem("cashier") ?? "null"
    );

    if (parsedActiveCashier) {
      const preparedBalancing: IStationBalancing[] = [];
      updatedData.forEach((data) => {
        if (data.imbalanceReason == "" && data.difference !== 0) {
          const reason = prompt(
            `Please enter the reason for the imbalance in station ${data.station}`
          );
          if (reason) {
            if (
              preparedBalancing.findIndex(
                (item) => item.cashierPointId === data.station
              ) === -1
            ) {
              preparedBalancing.push({
                cashierPointId: data.station,
                cashPaymentsTotal: data.cash,
                chequePaymentsTotal: data.check,
                cardPaymentsTotal: data.creditCard,
                inbalanceReason: reason,
              });
            } else {
              preparedBalancing[
                preparedBalancing.findIndex(
                  (item) => item.cashierPointId === data.station
                )
              ].inbalanceReason = reason;
            }
          } else {
            snackbar.showSnackbar("Please enter the reason", "error");
            return;
          }
        } else {
          preparedBalancing.push({
            cashierPointId: data.station,
            cashPaymentsTotal: data.cash,
            chequePaymentsTotal: data.check,
            cardPaymentsTotal: data.creditCard,
            inbalanceReason: data.imbalanceReason,
          });
        }
      });
      if (preparedBalancing.length !== updatedData.length) {
        return;
      }

      const preparedRequest: IDayEndRequest = {
        storeId: parsedActiveCashier.storeId,
        isDayFinished: true,
        balancing: preparedBalancing,
      };

      dispatch(endDayAction(preparedRequest));
    } else {
      snackbar.showSnackbar("Active cashier not found", "error");
    }
  };

  useEffect(() => {
    if (endDayError) {
      snackbar.showSnackbar(endDayError, "error");
    }
  }, [endDayError]);
  useEffect(() => {
    if (endDayResponse) {
      snackbar.showSnackbar("Day ended successfully", "success");
      localStorage.removeItem("activeCashierSession");
      navigate("/pos/lobby");
    }
  }, [endDayResponse]);

  return (
    <DayEndLayout>
      <Box
        sx={{
          backgroundColor: Colors.BACKSHADE_GRAY,
          margin: "2rem",
          width: "120.7142857142857rem",
          height: "52.92857142857143rem",
          display: "flex",
          flexDirection: "row",
          gap: "2rem",
          borderRadius: " 0.7142857142857143rem",
          padding: "2rem",
          marginTop: "0.2rem",
        }}
      >
        {/* Left Section */}
        <Box sx={{ flex: 3, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "2.285714285714286rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Register Balancing - {formatDate(date.toISOString())}
            </Typography>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <CustomDateTimePicker
                type="date"
                value={dayjs(date)}
                onChange={(date) => {
                  if (date) {
                    setDate(date.toDate());
                  } else {
                    setDate(new Date());
                  }
                }}
                enableSelect="past"
                sx={{
                  width: "19.14285714285714rem",
                  height: "3rem",
                }}
              />
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                overflowY: "auto",
                maxHeight: pxToRem(600),
                // make scrollbar beautiful
                "&::-webkit-scrollbar": {
                  width: "0.357rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: Colors.ACCENT_GRAY,
                  borderRadius: "0.357rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: Colors.BACKSHADE_GRAY,
                  borderRadius: "0.357rem",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflowX: "auto",
                }}
              >
                <TableContainer
                  sx={{
                    overflow: "auto",
                    borderRadius: "0.714rem",
                  }}
                >
                  <Table
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {[
                          "Station",
                          "Opening Balance",
                          "Cash",
                          "Check",
                          "Credit Card",
                          "Monthly Charge",
                          "Discounts",
                          "Sales",
                          "Register Total",
                        ].map((header, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              ...tableStyles.headerCell,
                              ...(index === 0
                                ? { borderRadius: "0.357rem 0 0 0" }
                                : index === 8
                                ? { borderRadius: "0 0.357rem 0 0" }
                                : {}),
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dayEndStationSummaryLoading ? (
                        <TableRow>
                          <TableCell colSpan={9} sx={{ textAlign: "center" }}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {preparedTable1Data?.map((row, index) => (
                        <TableRow key={index}>
                          {Object.values(row).map((value, colIndex) => (
                            <TableCell key={colIndex} sx={tableStyles.bodyCell}>
                              {typeof value === "number" && colIndex > 0
                                ? value.toFixed(2)
                                : value}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}

                      <TableRow>
                        {Object.values(totalRowData).map((value, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              ...tableStyles.totalRowCell,
                              ...(index === 0
                                ? { borderRadius: "0 0 0 0.357rem" }
                                : index === 8
                                ? { borderRadius: "0 0 0.357rem 0" }
                                : {}),
                            }}
                          >
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box>
                  <Typography
                    sx={{
                      color: Colors.MAIN_GRAY,
                      fontSize: "1.714285714285714rem",
                      fontWeight: "bold",
                      marginBottom: "-2rem",
                      marginTop: "2rem",
                    }}
                  >
                    Enter the Count
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: "2rem",
                    width: "100%",
                    overflowX: "auto",
                  }}
                >
                  <RegisterBalancingTable
                    data={preparedTable1Data ?? []}
                    editableFields={["cash", "check"]}
                    isEditingEnabled={isRegisterBalancingClicked}
                    onChangeValues={(newData) => {
                      setUpdatedData(
                        newData.map((item) => ({
                          ...item,
                          imbalanceReason: "",
                        }))
                      );
                    }}
                    isLoading={dayEndStationSummaryLoading}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Right Section */}
        <Box sx={{ flex: 1 }}>
          <DayEndButtons
            isRegisterBalancingPage={true}
            onRegisterBalancingClick={() => setIsRegisterBalancingClicked(true)}
            onSaveClick={handleSave}
          />
        </Box>
      </Box>
      <RegisterNoteModal
        open={registerNoteModalOpen}
        onClose={() => setRegisterNoteModalOpen(false)}
        onAddReason={(bla) => {}}
      />
    </DayEndLayout>
  );
};

export default RegisterBalancingPage;
