import { createSlice } from "@reduxjs/toolkit";

import { IupchargeResponse } from "../../typings/interfaces/responses";
import {
  CreateUpchargeAction,
  deleteUpchargeAction,
  deleteUpchargeVariantAction,
  getAllUpchargesAction,
  updateUpchargeAction,
} from "../actions/upchargesAction";

export interface Upchargestate {
  upcharges: IupchargeResponse[] | null;
  upchargesLoading: boolean;
  upchargesError: string | null;

  addUpchargeResponse: IupchargeResponse | null;
  addUpchargeLoading: boolean;
  addUpchargeError: string | null;

  updateUpchargeResponse: IupchargeResponse | null;
  updateUpchargeLoading: boolean;
  updateUpchargeError: string | null;
}

const initialState: Upchargestate = {
  upcharges: null,
  upchargesLoading: false,
  upchargesError: null,

  addUpchargeResponse: null,
  addUpchargeLoading: false,
  addUpchargeError: null,

  updateUpchargeResponse: null,
  updateUpchargeLoading: false,
  updateUpchargeError: null,
};

const upchargesSlice = createSlice({
  name: "upcharges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUpchargesAction.pending, (state) => {
        state.upcharges = null;
        state.upchargesLoading = true;
        state.upchargesError = null;
      })
      .addCase(getAllUpchargesAction.fulfilled, (state, action) => {
        state.upchargesError = null;
        state.upchargesLoading = false;
        state.upcharges = (action.payload as any).body;
      })
      .addCase(getAllUpchargesAction.rejected, (state, action) => {
        state.upcharges = null;
        state.upchargesLoading = false;
        state.upchargesError = (action.payload as any).message;
      });

    builder
      .addCase(CreateUpchargeAction.pending, (state) => {
        console.log("Upcharge API Request Started...");
        state.addUpchargeLoading = true;
        state.addUpchargeResponse = null;
        state.addUpchargeError = null;
      })
      .addCase(CreateUpchargeAction.fulfilled, (state, action) => {
        console.log("Upcharge API Request Successful:", action.payload);
        state.addUpchargeLoading = false;
        state.addUpchargeResponse = (action.payload as any).body;
        state.addUpchargeError = null;
      })
      .addCase(CreateUpchargeAction.rejected, (state, action) => {
        console.error("Upcharge API Request Failed:", action.error);
        state.addUpchargeLoading = false;
        state.addUpchargeError = (action.payload as any).message;
        state.addUpchargeResponse = null;
      });

    builder
      .addCase(updateUpchargeAction.pending, (state) => {
        console.log("Upcharge API Request Started...");
        state.updateUpchargeLoading = true;
        state.updateUpchargeResponse = null;
        state.updateUpchargeError = null;
      })
      .addCase(updateUpchargeAction.fulfilled, (state, action) => {
        console.log("Upcharge API Request Successful:", action.payload);
        state.updateUpchargeLoading = false;
        state.updateUpchargeResponse = (action.payload as any).body;
        state.updateUpchargeError = null;

        if (state.upcharges) {
          const index = state.upcharges.findIndex(
            (upcharge) => upcharge.id === action.payload.id
          );

          if (index !== -1) {
            state.upcharges[index] = action.payload;
          }
        }
      })
      .addCase(updateUpchargeAction.rejected, (state, action) => {
        console.error("Upcharge API Request Failed:", action.error);

        state.updateUpchargeLoading = false;

        state.updateUpchargeError = action.payload
          ? (action.payload as any).message
          : "Unknown error occurred while updating upcharge.";

        state.updateUpchargeResponse = null;
      });

    builder
      .addCase(deleteUpchargeAction.pending, (state) => {
        console.log("Deleting Upcharge...");
      })
      .addCase(deleteUpchargeAction.fulfilled, (state, action) => {
        console.log("Upcharge Deleted Successfully!");
        state.upcharges =
          state.upcharges?.filter(
            (upcharge) => upcharge.id !== action.meta.arg.upChargeId
          ) || null;
      })
      .addCase(deleteUpchargeAction.rejected, (state, action) => {
        console.error("Failed to delete upcharge:", action.error);
      });

    builder
      .addCase(deleteUpchargeVariantAction.pending, (state) => {
        console.log("Deleting Variant...");
      })
      .addCase(deleteUpchargeVariantAction.fulfilled, (state, action) => {
        console.log("Variant Deleted Successfully!");
        const { upChargeId, variantId } = action.meta.arg;
        state.upcharges =
          state.upcharges?.map((upcharge) =>
            upcharge.id === upChargeId
              ? {
                  ...upcharge,
                  upchargeVariants: upcharge.upchargeVariants.filter(
                    (variant) => variant.id !== variantId
                  ),
                }
              : upcharge
          ) || null;
      })
      .addCase(deleteUpchargeVariantAction.rejected, (state, action) => {
        console.error("Failed to delete variant:", action.error);
      });
  },
});

export default upchargesSlice.reducer;
export const upchargeSliceActions = upchargesSlice.actions;
