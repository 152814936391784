import { Navigate, useMatch } from "react-router-dom";
import { connect } from "react-redux";
import { IUserResponse } from "../../interfaces";
import { IcashierOnboardingResponse } from "../../typings";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect } from "react";
import { getStationByIdAction } from "../../redux/actions/cashierStationActions";
import { setActiveStation } from "../../redux/slice/cashierStationSlice";
// import { setActiveStation } from "../../redux/slice/cashierSlice";
// import { useEffect } from "react";
// import { getCashierStationListAction } from "../../redux/actions/cashierAction";
// import { useSnackbarContext } from "../../providers/SnackbarProvider";
// import { IStation, setStationList } from "../../redux/slice/CashRegisterSlice";

const PrivateRoute = (props: {
  token?: string;
  children: JSX.Element;
  user?: IUserResponse;
  authDetailsInitialized?: boolean;
  disableAdminGuard?: boolean;
  disablePosGuard?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isOnboardingPage = useMatch("/pos/onboarding");

  const { activeStation, getCashierStationByIdResponse } = useAppSelector(
    (state) => state.cashierStation
  );

  const cashier = localStorage.getItem("cashier");
  const parsedCashier: IcashierOnboardingResponse = cashier
    ? JSON.parse(cashier)
    : null;

  if (!getCashierStationByIdResponse && parsedCashier) {
    setTimeout(() => {
      // to do : remove settimeout
      dispatch(getStationByIdAction(parsedCashier.id));
    }, 10);
  }

  useEffect(() => {
    if (getCashierStationByIdResponse) {
      dispatch(setActiveStation(getCashierStationByIdResponse));
    }
  }, [getCashierStationByIdResponse]);

  if (props.user) {
    if (
      (props.user.role.name === "ADMIN" ||
        props.user.role.name === "MERCHANT_ADMIN") &&
      !props.disableAdminGuard
    ) {
      return <Navigate to={"/admin/dashboard"} />;
    } else if (
      props.user.role.name.includes("CASHIER") &&
      !props.disablePosGuard
    ) {
      return <Navigate to={"/pos/lobby"} />;
    }
  }
  if (!props.authDetailsInitialized) {
    return <p>Loading...</p>;
  }

  // if (!(!parsedCashier || !parsedCashier?.isAccessGranted)) {
  //   dispatch(setActiveStation(parsedCashier));
  // }

  const children =
    (!activeStation ||
      activeStation.accessGranted === "DENIED" ||
      activeStation.accessGranted === "PENDING") &&
    props.disableAdminGuard === undefined &&
    !isOnboardingPage ? (
      <Navigate to="/pos/onboarding" />
    ) : (
      props.children
    );

  return props.token !== null && props.user !== null ? (
    children
  ) : (
    <Navigate to={"/auth/login"} />
  );
};

const mapStateToProps = (state: any) => state.auth;
export default connect(mapStateToProps, null)(PrivateRoute);
