import { createSlice } from "@reduxjs/toolkit";

import { ISpotResponse } from "../../typings/interfaces/responses";
import {
  CreateSpotAction,
  deleteSpotAction,
  getAllSpotsAction,
  updateSpotAction,
} from "../actions/spotsAction";

export interface Spotstate {
  spots: ISpotResponse[] | null;
  spotsLoading: boolean;
  spotsError: string | null;

  addSpotResponse: ISpotResponse[] | null;
  addSpotLoading: boolean;
  addSpotError: string | null;

  updateSpotResponse: ISpotResponse[] | null;
  updateSpotLoading: boolean;
  updateSpotError: string | null;

  deleteSpotResponse: ISpotResponse[] | null;
  deleteSpotLoading: boolean;
  deleteSpotError: string | null;
}

const initialState: Spotstate = {
  spots: null,
  spotsLoading: false,
  spotsError: null,

  addSpotResponse: null,
  addSpotLoading: false,
  addSpotError: null,

  updateSpotResponse: null,
  updateSpotLoading: false,
  updateSpotError: null,

  deleteSpotResponse: null,
  deleteSpotLoading: false,
  deleteSpotError: null,
};

const spotsSlice = createSlice({
  name: "spots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSpotsAction.pending, (state) => {
        state.spots = null;
        state.spotsLoading = true;
        state.spotsError = null;
      })
      .addCase(getAllSpotsAction.fulfilled, (state, action) => {
        state.spotsError = null;
        state.spotsLoading = false;
        state.spots = (action.payload as any).body;
      })
      .addCase(getAllSpotsAction.rejected, (state, action) => {
        state.spots = null;
        state.spotsLoading = false;
        state.spotsError = (action.payload as any).message;
      });

    builder
      .addCase(CreateSpotAction.pending, (state) => {
        console.log("API Request Started...");
        state.addSpotResponse = null;
        state.addSpotLoading = true;
        state.addSpotError = null;
      })
      .addCase(CreateSpotAction.fulfilled, (state, action) => {
        console.log("API Request Successful:", action.payload);
        state.addSpotResponse = (action.payload as any).body;
        state.addSpotLoading = false;
        state.addSpotError = null;
      })
      .addCase(CreateSpotAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.addSpotResponse = null;
        state.addSpotLoading = false;
        state.addSpotError = (action.payload as any).message;
      });

    builder
      .addCase(updateSpotAction.pending, (state) => {
        console.log("API Request Started...");
        state.updateSpotResponse = null;
        state.updateSpotLoading = true;
        state.updateSpotError = null;
      })
      .addCase(updateSpotAction.fulfilled, (state, action) => {
        console.log("API Request Successful:", action.payload);
        state.updateSpotResponse = (action.payload as any).body;
        state.updateSpotLoading = false;
        state.updateSpotError = null;
      })
      .addCase(updateSpotAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.updateSpotResponse = null;
        state.updateSpotLoading = false;
        state.updateSpotError = (action.payload as any).message;
      });

    builder
      .addCase(deleteSpotAction.pending, (state) => {
        console.log("API Request Started...");
        state.updateSpotResponse = null;
        state.updateSpotLoading = true;
        state.updateSpotError = null;
      })
      .addCase(deleteSpotAction.fulfilled, (state, action) => {
        console.log("API Request Successful:");
        state.updateSpotResponse = (action.payload as any).body;
        state.updateSpotLoading = false;
        state.updateSpotError = null;
      })
      .addCase(deleteSpotAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.updateSpotResponse = null;
        state.updateSpotLoading = false;
        state.updateSpotError = (action.payload as any).message;
      });
  },
});

export default spotsSlice.reducer;
export const spotSliceActions = spotsSlice.actions;
