import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import { useTranslation } from "../../../util/translation-provider";
import { PosLayout, CustomButton } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import ManagerCodeModal from "../../../components/common/manager-code-modal/ManagerCodeModal";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
// import { getCashierStationListAction } from "../../../redux/actions/cashierStationActions";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
// import { IStation } from "../../../redux/slice/CashRegisterSlice";
import { formatCurrency } from "../../../util";
import {
  getAllStationSummaryAction,
  getStationSummaryAction,
} from "../../../redux/actions/cashierStationActions";
import { ILocalStationCashier } from "../../../typings/interfaces/responses/cashierStationResponses";

const ViewAllPage: React.FC = () => {
  // const { stationList } = useAppSelector((state) => state.cashRegister);
  // const [isManagerCodeModalOpen, setIsManagerCodeModalOpen] = useState(false);
  const [activeButtonType, setActiveButtonType] = useState<
    "viewAll" | "dayEnd" | null
  >(null);
  // const [activeStation, setActiveStation] = useState<any | null>(null);

  const translations = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  // const { stationList, stationListError, stationListLoading } = useAppSelector(
  //   (state) => state.cashierStation
  // );
  const {
    getAllStationSummaryError,
    getAllStationSummaryLoading,
    getAllStationSummaryResponse,
  } = useAppSelector((state) => state.cashierStation);

  useEffect(() => {
    // dispatch(getCashierStationListAction());
    // const savedActiveStation = localStorage.getItem("activeCashierSession");
    // const parsedActiveStation: ILocalStationCashier | null = savedActiveStation
    //   ? JSON.parse(savedActiveStation)
    //   : null;
    // if (parsedActiveStation) {
    //   setActiveStation(parsedActiveStation);
    // } else {
    //   snackbar.showSnackbar("No active station found", "error");
    // }

    dispatch(getAllStationSummaryAction());
  }, []);

  useEffect(() => {
    if (getAllStationSummaryError) {
      snackbar.showSnackbar(getAllStationSummaryError, "error");
    }
  }, [getAllStationSummaryError]);

  // useEffect(() => {
  //   if (stationListError) {
  //     snackbar.showSnackbar(stationListError, "error");
  //   }
  // }, [stationListError]);

  // const preparedStationList: IStation[] = stationList
  // .filter((station) => {
  //   return station.isAccessGranted == true;
  // })
  // .map((station) => ({
  //   id: station?.id ?? 1,
  //   name: station?.name ?? "N/A",
  //   cash: null,
  //   closingCash: null,
  //   totalSales: 100,
  //   registerTotal: 200,
  //   refundTotal: 5,
  //   extraAdditions: [],
  //   extraDeductions: [],
  //   status: null,
  // }));
  // const filteredStationList = preparedStationList.filter(
  //   (station) => station.id !== activeStation?.id
  // );

  // const handleManagerCodeClick = (type: "viewAll" | "dayEnd") => {
  //   setActiveButtonType(type);
  //   setModalType(type);

  //   const config = modalConfig[type];
  //   if (config) {
  //     setModalTitle(config.title);
  //     setModalTask(config.task);
  //   }

  //   setIsManagerCodeModalOpen(true);
  // };

  // const handleModalClose = () => {
  //   setActiveButtonType(null);
  //   setModalType(null);
  //   setModalTitle("");
  //   setModalTask("");
  //   setIsManagerCodeModalOpen(false);
  // };

  const handleBackClick = () => {
    navigate("/pos/cash-register");
  };

  const calculateCoinTotal = (station: any) => {
    if (!station?.cash) return 0;
    const total =
      station?.cash?.coins["1c"] * 0.01 +
      station?.cash?.coins["5c"] * 0.05 +
      station?.cash?.coins["10c"] * 0.1 +
      station?.cash?.coins["25c"] * 0.25;
    return total;
  };
  const calculateBillTotal = (station: any) => {
    if (!station?.cash) return 0;
    const total =
      station?.cash?.bills["1"] * 1 +
      station?.cash?.bills["5"] * 5 +
      station?.cash?.bills["10"] * 10 +
      station?.cash?.bills["20"] * 20;
    return total;
  };

  return (
    <PosLayout>
      <>
        <PosInnerPageLayout onClickBack={handleBackClick} title={""}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "2.85714286rem",
                    lineHeight: "3.35714286rem",
                    color: Colors.TEXT_GRAY_DARK,
                    fontWeight: "700",
                    marginLeft: "6rem",
                  }}
                >
                  {translations.companyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.71428571rem",
                    lineHeight: "1.92857143rem",
                    color: Colors.TEXT_GRAY_DARK,
                    marginLeft: "6rem",
                  }}
                >
                  Cash Register
                </Typography>
              </Box>

              <Box>
                <CustomButton
                  onClick={() => navigate("/pos/lobby")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    backgroundColor: Colors.BACKSHADE_GRAY,
                    color: Colors.MAIN_GRAY,
                    border: `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                    marginRight: "1rem",
                  }}
                >
                  Lobby
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/view-all")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    marginRight: "1rem",
                    backgroundColor: Colors.PRIMARY,
                    color: Colors.WHITE,
                    border: "none",
                    borderRadius: "0.7142857142857143rem",
                    fontSize: "1.428571428571429rem",
                    marginTop: "6rem",
                  }}
                >
                  View All
                </CustomButton>
                <CustomButton
                  onClick={() => navigate("/pos/cash-register/day-end")}
                  sx={{
                    width: "14.64285714285714rem",
                    height: "4.071428571428571rem",
                    fontSize: "1.428571428571429rem",
                    borderRadius: "0.7142857142857143rem",
                    backgroundColor:
                      activeButtonType === "dayEnd"
                        ? Colors.PRIMARY
                        : Colors.BACKSHADE_GRAY,
                    color:
                      activeButtonType === "dayEnd"
                        ? Colors.WHITE
                        : Colors.PRIMARY,
                    border:
                      activeButtonType === "dayEnd"
                        ? "none"
                        : `0.0714285714285714rem solid ${Colors.PRIMARY}`,
                    marginTop: "6rem",
                    marginRight: "7.5rem",
                  }}
                  disabled={
                    getAllStationSummaryResponse?.some(
                      (station) => station.stationStatus !== "closed"
                    ) || !getAllStationSummaryResponse
                  }
                >
                  Day End
                </CustomButton>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                margin: "2rem",
                width: "120.7142857142857rem",
                height: "52.92857142857143rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "0.7142857142857143rem",
                padding: "2rem",
                marginTop: "-1rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: Colors.MAIN_GRAY,
                    fontSize: "2.285714285714286rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  Daily Summary of All Stations
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                  flexWrap: "wrap",
                  overflowY: "auto",
                  // make scrollbar beautiful
                  "&::-webkit-scrollbar": {
                    width: "0.5rem",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: Colors.BACKSHADE_GRAY,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: Colors.MAIN_GRAY,
                    borderRadius: "0.5rem",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: Colors.SECONDARY,
                  },
                }}
              >
                {getAllStationSummaryLoading && (
                  <CircularProgress sx={{ mx: "auto" }} />
                )}
                {/* {getStationSummaryResponse && (
                  <Box
                    key={getStationSummaryResponse.cashierPointId}
                    sx={{
                      width: "37rem",
                      backgroundColor: Colors.BACKSHADE_GRAY,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "0.7142857142857143rem",
                      overflow: "hidden",
                      marginTop: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        height: "6rem",
                        textAlign: "center",
                        paddingTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        borderRadius:
                          "0.7142857142857143rem 0.7142857142857143rem 0 0",
                      }}
                    >
                      {getStationSummaryResponse.cashierPointName} -{" "}
                      {getStationSummaryResponse.stationStatus === null
                        ? "Pending Start"
                        : getStationSummaryResponse.stationStatus}
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        padding: "0.3rem",
                      }}
                    >
                      {[
                        {
                          label: "Opening Balance",
                          value: formatCurrency({
                            amount: getStationSummaryResponse.totalOpenBalance,
                            prefix: "$",
                          }),
                        },
                        {
                          label: "Cash Added",
                          value: formatCurrency({
                            amount: getStationSummaryResponse.totalAddCash,
                            prefix: "$",
                          }),
                        },
                        {
                          label: "Cash Removed",
                          value: formatCurrency({
                            amount: getStationSummaryResponse.totalRemoveCash,
                            prefix: "$",
                          }),
                        },
                        { label: "Total Sales", value: "$0.00" },
                        {
                          label: "Register Total",
                          value: formatCurrency({
                            amount: getStationSummaryResponse.registerTotal,
                            prefix: "$",
                          }),
                        },
                      ].map((row, index) => (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              color: Colors.MAIN_GRAY,
                              padding: "1rem",
                              textAlign: "left",
                              fontSize: "1.428571428571429rem",
                              display: "flex",
                              alignItems: "center",
                              height: "5rem",
                              paddingLeft: "3rem",
                              borderRadius:
                                row.label === "Closing Balance"
                                  ? "0 0 0 0.7142857142857143rem"
                                  : "0",
                            }}
                          >
                            {row.label}
                          </Box>

                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              fontSize: "1.428571428571429rem",
                              color: Colors.MAIN_GRAY,
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              paddingRight: "7rem",
                              borderRadius:
                                row.label === "Closing Balance"
                                  ? "0 0 0.7142857142857143rem 0"
                                  : "0",
                            }}
                          >
                            {row.value}
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: Colors.MAIN_GRAY,
                        color: Colors.WHITE,
                        height: "5rem",
                        padding: "1rem",
                        fontSize: "1.428571428571429rem",
                        borderRadius:
                          "0 0 0.7142857142857143rem 0.7142857142857143rem",
                        paddingTop: "1.5rem",
                        paddingLeft: "3rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Day End Balance :</span>
                      <span style={{ marginRight: "6rem" }}>
                        {formatCurrency({
                          amount:
                            getStationSummaryResponse.registerTotal -
                            getStationSummaryResponse.totalOpenBalance,
                          prefix: "$",
                        })}
                      </span>
                    </Box>
                  </Box>
                )} */}

                {getAllStationSummaryResponse?.map((station) => (
                  <Box
                    key={station.cashierPointId}
                    sx={{
                      width: "37rem",
                      backgroundColor: Colors.BACKSHADE_GRAY,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "0.7142857142857143rem",
                      overflow: "hidden",
                      marginTop: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: Colors.SECONDARY,
                        color: Colors.WHITE,
                        height: "6rem",
                        textAlign: "center",
                        paddingTop: "2rem",
                        fontSize: "1.714285714285714rem",
                        borderRadius:
                          "0.7142857142857143rem 0.7142857142857143rem 0 0",
                      }}
                    >
                      {station.cashierPointName} -{" "}
                      {station.stationStatus === null
                        ? "Pending Start"
                        : station.stationStatus}
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.3rem",
                        padding: "0.3rem",
                      }}
                    >
                      {[
                        {
                          label: "Opening Balance",
                          value: formatCurrency({
                            amount: station.totalOpenBalance,
                            prefix: "$",
                          }),
                        },
                        {
                          label: "Cash Added",
                          value: formatCurrency({
                            amount: station.totalAddCash,
                            prefix: "$",
                          }),
                        },
                        {
                          label: "Cash Removed",
                          value: formatCurrency({
                            amount: station.totalRemoveCash,
                            prefix: "$",
                          }),
                        },
                        { label: "Total Sales", value: "$0.00" },
                        {
                          label: "Register Total",
                          value: formatCurrency({
                            amount: station.registerTotal,
                            prefix: "$",
                          }),
                        },
                      ].map((row, index) => (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              color: Colors.MAIN_GRAY,
                              padding: "1rem",
                              textAlign: "left",
                              fontSize: "1.428571428571429rem",
                              display: "flex",
                              alignItems: "center",
                              height: "5rem",
                              paddingLeft: "3rem",
                              borderRadius:
                                row.label === "Closing Balance"
                                  ? "0 0 0 0.7142857142857143rem"
                                  : "0",
                            }}
                          >
                            {row.label}
                          </Box>

                          <Box
                            sx={{
                              backgroundColor: Colors.WHITE,
                              height: "5rem",
                              fontSize: "1.428571428571429rem",
                              color: Colors.MAIN_GRAY,
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              paddingRight: "7rem",
                              borderRadius:
                                row.label === "Closing Balance"
                                  ? "0 0 0.7142857142857143rem 0"
                                  : "0",
                            }}
                          >
                            {row.value}
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: Colors.MAIN_GRAY,
                        color: Colors.WHITE,
                        height: "5rem",
                        padding: "1rem",
                        fontSize: "1.428571428571429rem",
                        borderRadius:
                          "0 0 0.7142857142857143rem 0.7142857142857143rem",
                        paddingTop: "1.5rem",
                        paddingLeft: "3rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Day End Balance :</span>
                      <span style={{ marginRight: "6rem" }}>
                        {formatCurrency({
                          amount:
                            station.registerTotal - station.totalOpenBalance,
                          prefix: "$",
                        })}
                      </span>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </PosInnerPageLayout>
      </>
    </PosLayout>
  );
};

export default ViewAllPage;
