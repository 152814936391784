import React, { FC, useState, MouseEvent, useEffect } from "react";
import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import { formatDateTime } from "../../../../util";
import {
  getAllStationsAction,
  updateStationAccessAction,
} from "../../../../redux/actions/cashierStationActions";

interface ICashiersPageProps {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CashiersPage: FC<ICashiersPageProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openContextMenu = Boolean(anchorEl);
  const [contextMenuStation, setContextMenuStation] = useState<null | number>(
    null
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();
  const {
    getAllCashierStationRequestsError,
    allCashierStationRequests,
    getAllCashierStationRequestsLoading,
    updateCashierStationAccessError,
    updateCashierStationAccessResponse,
    updateCashierStationAccessLoading,
  } = useAppSelector((state) => state.cashierStation);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    station: number
  ) => {
    setAnchorEl(event.currentTarget);
    setContextMenuStation(station);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContextMenuStation(null);
  };

  const grantAccess = () => {
    contextMenuStation &&
      dispatch(
        updateStationAccessAction({
          id: contextMenuStation,
          type: "grant",
        })
      );
    handleClose();
  };
  const revokeAccess = () => {
    contextMenuStation &&
      dispatch(
        updateStationAccessAction({
          id: contextMenuStation,
          type: "deny",
        })
      );
    handleClose();
  };

  useEffect(() => {
    if (updateCashierStationAccessError) {
      snackbar.showSnackbar(updateCashierStationAccessError, "error");
      handleClose();
    }
  }, [updateCashierStationAccessError]);

  useEffect(() => {
    if (updateCashierStationAccessResponse) {
      snackbar.showSnackbar("Access updated successfully", "success");
      handleClose();
      dispatch(getAllStationsAction());
    }
  }, [updateCashierStationAccessResponse]);

  useEffect(() => {
    dispatch(getAllStationsAction());
    handleClose();
  }, []);

  useEffect(() => {
    if (getAllCashierStationRequestsError) {
      snackbar.showSnackbar(getAllCashierStationRequestsError, "error");
    }
  }, [getAllCashierStationRequestsError]);

  // station.accessGranted=="PENDING" at the first, then "GRANTED", then "DENIED"
  const sortedPoints = allCashierStationRequests
    ? [...allCashierStationRequests].sort((a, b) => {
        if (a.accessGranted === "PENDING") return -1;
        if (b.accessGranted === "PENDING") return 1;
        if (a.accessGranted === "GRANTED") return -1;
        if (b.accessGranted === "GRANTED") return 1;
        if (a.accessGranted === "DENIED") return 1;
        if (b.accessGranted === "DENIED") return -1;
        return 0;
      })
    : [];

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.35714286rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Cashiers
          </Typography>
          {/* <Link to="/admin/config/merchants/1/stores/1/cashiers/create">
            <CustomButton
              sx={{
                width: "11.0714286rem",
                height: "3.07142857rem",
              }}
            >
              Add New
            </CustomButton>
          </Link> */}
        </Box>

        <Box
          sx={{
            maxHeight: "51.42857143rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Cashier Name</StyledTableCell>
                <StyledTableCell>IP Address</StyledTableCell>
                <StyledTableCell>Access Granted</StyledTableCell>
                <StyledTableCell>Updated Time</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {getAllCashierStationRequestsLoading ||
              updateCashierStationAccessLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <CircularProgress sx={{ mx: "auto" }} />
                  </StyledTableCell>
                </StyledTableRow>
              ) : null}
              {!(
                getAllCashierStationRequestsLoading ||
                updateCashierStationAccessLoading
              ) &&
                sortedPoints?.map((station) => (
                  <StyledTableRow key={station.id}>
                    <StyledTableCell>{station.name}</StyledTableCell>
                    <StyledTableCell>{station.ipAddress}</StyledTableCell>
                    <StyledTableCell>{station.accessGranted}</StyledTableCell>
                    <StyledTableCell>
                      {station.updatedTime
                        ? formatDateTime(station.updatedTime)
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleClick(e, station.id)}>
                        <MoreHorizIcon
                          sx={{ fontSize: "2rem" }}
                          color="primary"
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={grantAccess}>
                  <ListItemIcon>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Grant Access</ListItemText>
                </MenuItem>
                <MenuItem onClick={revokeAccess}>
                  <ListItemIcon>
                    <CloseIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Revoke Access</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CashiersPage;
