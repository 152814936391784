import { createSlice } from "@reduxjs/toolkit";

import { IinstructionResponse } from "../../typings/interfaces/responses";
import {
  CreateInstructionAction,
  deleteInstructionAction,
  getAllInstructionsAction,
  updateInstructionAction,
} from "../actions/instructionsAction";

export interface Instructionstate {
  instructions: IinstructionResponse[] | null;
  instructionsLoading: boolean;
  instructionsError: string | null;

  addInstructionResponse: IinstructionResponse[] | null;
  addInstructionLoading: boolean;
  addInstructionError: string | null;

  deleteInstructionResponse: IinstructionResponse[] | null;
  deleteInstructionLoading: boolean;
  deleteInstructionError: string | null;

  updateInstructionResponse: IinstructionResponse[] | null;
  updateInstructionLoading: boolean;
  updateInstructionError: string | null;
}

const initialState: Instructionstate = {
  instructions: null,
  instructionsLoading: false,
  instructionsError: null,

  addInstructionResponse: null,
  addInstructionLoading: false,
  addInstructionError: null,

  deleteInstructionResponse: null,
  deleteInstructionLoading: false,
  deleteInstructionError: null,

  updateInstructionResponse: null,
  updateInstructionLoading: false,
  updateInstructionError: null,
};

const instructionsSlice = createSlice({
  name: "instructions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllInstructionsAction.pending, (state) => {
        state.instructions = null;
        state.instructionsLoading = true;
        state.instructionsError = null;
      })
      .addCase(getAllInstructionsAction.fulfilled, (state, action) => {
        state.instructionsError = null;
        state.instructionsLoading = false;
        state.instructions = (action.payload as any).body;
      })
      .addCase(getAllInstructionsAction.rejected, (state, action) => {
        state.instructions = null;
        state.instructionsLoading = false;
        state.instructionsError = (action.payload as any).message;
      });
    builder
      .addCase(CreateInstructionAction.pending, (state) => {
        console.log("API Request Started...");
        state.addInstructionResponse = null;
        state.addInstructionLoading = true;
        state.addInstructionError = null;
      })
      .addCase(CreateInstructionAction.fulfilled, (state, action) => {
        console.log("API Request Successful:", action.payload);
        state.addInstructionResponse = (action.payload as any).body;
        state.addInstructionLoading = false;
        state.addInstructionError = null;
      })
      .addCase(CreateInstructionAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.addInstructionResponse = null;
        state.addInstructionLoading = false;
        state.addInstructionError = (action.payload as any).message;
      });

    builder
      .addCase(deleteInstructionAction.pending, (state) => {
        console.log("API Request Started...");
        state.deleteInstructionResponse = null;
        state.deleteInstructionLoading = true;
        state.deleteInstructionError = null;
      })
      .addCase(deleteInstructionAction.fulfilled, (state, action) => {
        console.log("API Request Successful:", action.payload);
        state.deleteInstructionResponse = (action.payload as any).body;
        state.deleteInstructionLoading = false;
        state.deleteInstructionError = null;
      })
      .addCase(deleteInstructionAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.deleteInstructionResponse = null;
        state.deleteInstructionLoading = false;
        state.deleteInstructionError = (action.payload as any).message;
      });

    builder
      .addCase(updateInstructionAction.pending, (state) => {
        console.log("API Request Started...");
        state.updateInstructionResponse = null;
        state.updateInstructionLoading = true;
        state.updateInstructionError = null;
      })
      .addCase(updateInstructionAction.fulfilled, (state, action) => {
        console.log("API Request Successful:", action.payload);
        state.updateInstructionResponse = (action.payload as any).body;
        state.updateInstructionLoading = false;
        state.updateInstructionError = null;
      })
      .addCase(updateInstructionAction.rejected, (state, action) => {
        console.error("API Request Failed:", action.error);
        state.updateInstructionResponse = null;
        state.updateInstructionLoading = false;
        state.updateInstructionError = (action.payload as any).message;
      });
  },
});

export default instructionsSlice.reducer;
export const instructionSliceActions = instructionsSlice.actions;
