import { FC, useEffect, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddInstructionDrawer } from "../../../../components/admin/add-instructions-drawer/AddInstructionsDrawer";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteInstructionAction,
  getAllInstructionsAction,
} from "../../../../redux/actions/instructionsAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

interface IInstruction {
  id: number;
  instruction: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const InstructionsPage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    instructions,
    instructionsLoading,
    addInstructionLoading,
    updateInstructionLoading,
    deleteInstructionLoading,
  } = useAppSelector((state) => state.instruction);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedInstruction, setSelectedInstruction] = useState<
    IInstruction | undefined
  >(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);

  const snackBar = useSnackbarContext();

  useEffect(() => {
    dispatch(getAllInstructionsAction());
  }, []);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    instruction: IInstruction
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedInstruction(instruction);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteInstruction = async () => {
    if (!selectedInstruction) {
      console.error("No Instruction ID selected for deletion.");
      return;
    }

    try {
      await dispatch(
        deleteInstructionAction({
          merchantId: 1,
          storeId: 1,
          instructionId: selectedInstruction.id,
        })
      ).unwrap();

      snackBar.showSnackbar("Instruction deleted successfully!", "success");

      dispatch(getAllInstructionsAction());
      handleClose();
    } catch (error) {
      snackBar.showSnackbar(
        "Failed to delete instruction. Please try again.",
        "error"
      );
    }
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.357rem",
          paddingInline: "5rem",
          width: "100%",
        }}
      >
        <AddInstructionDrawer
          open={openDrawer}
          toggleDrawer={() => setOpenDrawer(false)}
          populateFor={selectedInstruction}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Instructions
          </Typography>
          <CustomButton
            sx={{ width: "11rem", height: "3rem" }}
            onClick={() => {
              setSelectedInstruction(undefined);
              setOpenDrawer(true);
            }}
            disabled={
              instructionsLoading ||
              addInstructionLoading ||
              updateInstructionLoading ||
              deleteInstructionLoading
            }
          >
            {instructionsLoading ||
            addInstructionLoading ||
            deleteInstructionLoading ||
            updateInstructionLoading ? (
              <CircularProgress size={27} color="inherit" />
            ) : (
              "Add Instruction"
            )}
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "51rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "0.5rem" },
            "&::-webkit-scrollbar-thumb": { background: "#888" },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Instruction</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(instructionsLoading ||
                addInstructionLoading ||
                updateInstructionLoading ||
                deleteInstructionLoading) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={2} align="center">
                    <CircularProgress size={27} color="inherit" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {!(
                instructionsLoading ||
                addInstructionLoading ||
                updateInstructionLoading ||
                deleteInstructionLoading
              ) &&
                instructions?.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell sx={{ width: "80%" }}>
                      {row.instruction}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "20%" }}>
                      <IconButton onClick={(e) => handleClick(e, row)}>
                        <MoreHorizIcon
                          sx={{ fontSize: "2rem" }}
                          color="primary"
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpenDrawer(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Instruction</ListItemText>
                </MenuItem>

                <MenuItem onClick={handleDeleteInstruction}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Instruction</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};
