import { FC, useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import TouchAppIcon from "@mui/icons-material/TouchApp";

import CustomerHistoryTableRow from "./customer-history-table-row";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getCustomerBillHistoryAction } from "../../../../redux/actions/customerAction";
import InvoiceHistoryModal from "../../../pos/invoice-history-modal/invoice-history-modal";
import { ICustomerBill, ICustomerBillsResponse } from "../../../../typings";
import {
  addSession,
  getBillByIdAction,
  updateCartSessionItem,
} from "../../../../redux/actions/cartAction";
import { Colors } from "../../../../configs";
import { ICartSession } from "../../../../typings/interfaces/pos/cart-session";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import {
  BillFilterDateType,
  BillPaymentStatus,
  BillPickupStatus,
} from "../../../../typings/enum/paymentTypes";

export interface ICustomerHistoryTableProps {
  contentHeight?: string;
  paymentFilterStatus: BillPaymentStatus;
  filterDateType: BillFilterDateType;
  startDate: string | undefined;
  endDate: string | undefined;
  pickingFilterStatus?: BillPickupStatus;
  searchQuery?: string;
}

const CustomerHistoryTable: FC<ICustomerHistoryTableProps> = ({
  contentHeight = "39.07rem",
  paymentFilterStatus,
  filterDateType,
  startDate,
  endDate,
  pickingFilterStatus,
  searchQuery,
}) => {
  const params = useParams();
  const customerId = params.id;
  const queryParams = new URLSearchParams(window.location.search);
  const billId = queryParams.get("billId");
  const dispatch = useAppDispatch();
  const { bills, billsLoading } = useAppSelector((state) => state.customer);
  const [clickedRow, setClickedRow] = useState<ICustomerBill | null>(null);
  const snackbar = useSnackbarContext();

  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<ICustomerBill | null>(
    null
  );

  console.log(filterDateType, startDate, endDate);

  const handleOnClickRow = (data: ICustomerBill) => {
    setClickedRow(data);
    dispatch(CartSliceAction.clearSession());
    if (data.billString) {
      dispatch(addSession(JSON.parse(data.billString)));
    } else {
      snackbar.showSnackbar("cart not found for the bill", "error");
    }
  };

  useEffect(() => {
    if (billId) {
      // setClickedRow({
      //   billId: billId,
      //   id: Number(billId),
      // });
      setClickedRow(bills?.find((bill) => bill.billId === billId) || null);
    }
  }, [billId]);

  useEffect(() => {
    if (clickedRow?.id) {
      dispatch(getBillByIdAction({ billId: String(clickedRow?.id) }));
    }
  }, [clickedRow]);

  useEffect(() => {
    getCustommerBillList();
  }, [
    customerId,
    paymentFilterStatus,
    pickingFilterStatus,
    filterDateType,
    startDate,
    endDate,
  ]);

  const getCustommerBillList = async () => {
    if (customerId) {
      await dispatch(CartSliceAction.setCartHistorySelectedRows([]));
      dispatch(CartSliceAction.clearSession());
      setClickedRow(null);
      dispatch(
        getCustomerBillHistoryAction({
          customerId: Number(customerId),
          paymentFilterStatus: paymentFilterStatus,
          pickingFilterStatus: pickingFilterStatus,
          filterDateType: filterDateType,
          startDate: startDate
            ? new Date(startDate).toISOString().split("T")[0]
            : undefined,
          endDate: endDate
            ? new Date(endDate).toISOString().split("T")[0]
            : undefined,
          page: 0,
          size: 10,
        })
      );
    }
  };
  const styles = {
    tableHeadingCell: {
      // borderRadius: "0.71rem",
      borderRadius: "0",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
      height: "2.92857142857rem",
    },
  };

  const handleViewClick = (data: ICustomerBill) => {
    setSelectedInvoice(data);
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0",
        padding: "0.71rem",
        gap: "1.14rem",
        height: "28.42rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "0.21rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid container sx={{ gap: "0.15rem", flexWrap: "nowrap" }}>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "2.92857142857rem",
                    maxWidth: "2.92857142857rem !important",
                    borderTopLeftRadius: "0.71rem",
                  }}
                >
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Invoice
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Date In
                </Grid>
                {/* cashier column */}
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Cashier
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Due date
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pieces
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "4.606rem",
                    maxWidth: "4.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Rack
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Balance
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pay Date
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pickup Date
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.246rem",
                    maxWidth: "5.246rem !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: "0.71rem",
                  }}
                >
                  <TouchAppIcon sx={{ color: "white" }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: contentHeight }}>
            <Box
              sx={{
                height: contentHeight,
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": { width: "0.5rem" },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
              }}
            >
              {[...(bills || [])]
                .filter((item) => {
                  return (
                    !searchQuery ||
                    item.billId
                      ?.toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  );
                })
                .reverse()
                .map((item, index) => (
                  <CustomerHistoryTableRow
                    isSelected={clickedRow?.id === item.id}
                    data={item}
                    firstRow={index === 0}
                    lastRow={index === (bills || []).length - 1}
                    onClick={handleOnClickRow}
                    onViewClick={handleViewClick}
                  />
                ))}
              {!billsLoading &&
                [...(bills || [])].filter((item) => {
                  return (
                    !searchQuery ||
                    item.billId
                      ?.toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  );
                }).length === 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      py: "1rem",
                      color: Colors.SECONDARY,
                    }}
                  >
                    No bills found
                  </Box>
                )}
              {billsLoading && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      mx: "auto",
                      mt: "7rem",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Render InvoiceHistoryModal */}
      {selectedInvoice && (
        <InvoiceHistoryModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          // invoiceData={selectedInvoice}
          selectedId={selectedInvoice.id}
          billId={selectedInvoice.billId ?? ""}
        />
      )}
    </Box>
  );
};

export default CustomerHistoryTable;
