import Box from "@mui/material/Box";
import { FC, useEffect, useState } from "react";
import { Colors } from "../../../configs";
import { Button } from "@mui/material";
import { CustomButton } from "../button/button";

export interface IItem {
  value: string;
  label: string;
  tabIndex?: number;
  ref?: any;
  onKeyDown?: (event: any) => void;
}

export interface IMultiTabSelectorProps {
  items: Array<IItem>;
  value: Array<string>;
  onChange?(values: Array<string>): void;
  layout: "vertical" | "horizontal";
  mode?: "single" | "multiple";
  error?: boolean;
}

export const MultiTabSelector: FC<IMultiTabSelectorProps> = ({
  items,
  onChange,
  value,
  layout,
  mode = "multiple",
  error = false,
}) => {
  const [selectedItems, setSelectedItems] = useState<Array<string>>(value);

  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  const handleOnItemClick = (value: string) => {
    if (mode === "single") {
      if (selectedItems === undefined) {
        setSelectedItems([value]);
      } else if (!selectedItems.includes(value)) {
        setSelectedItems([value]);
      } else if (selectedItems.includes(value)) {
        setSelectedItems(selectedItems.filter((item) => item !== value));
      }
    } else {
      if (selectedItems === undefined) {
        setSelectedItems([value]);
      } else if (!selectedItems.includes(value)) {
        setSelectedItems([...selectedItems, value]);
      } else if (selectedItems.includes(value)) {
        setSelectedItems(selectedItems.filter((item) => item !== value));
      }
    }
  };

  const isItemSelected = (value: string) => {
    if (selectedItems) {
      const selected = selectedItems.find((item) => item === value);
      return selected !== undefined ? true : false;
    }
    return false;
  };

  useEffect(() => {
    onChange && selectedItems !== value && onChange(selectedItems);
  }, [selectedItems]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: layout === "vertical" ? "column" : "row",
        gap: layout === "vertical" ? "0.571rem" : "0.285rem",
      }}
    >
      {items.map((item, i) => {
        return (
          <CustomButton
            key={i}
            sx={{
              backgroundColor: isItemSelected(item.value)
                ? "secondary.main"
                : Colors.WHITE,
              minHeight: "2.14285714rem",
              maxHeight: "3.6rem",
              height: "3.6rem",
              color: isItemSelected(item.value) ? Colors.WHITE : Colors.BLACK,
              fontSize: "1.14rem",
              borderRadius: "0.57rem",
              cursor: "pointer",
              p: "0.71rem",
              mr: "0.285714286rem",
              mb: "0.285714286rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 0.5,
              minWidth: "6rem", 
              textTransform: "none",
              border: error
                ? `0.0714285714rem solid ${Colors.ERROR_RED}`
                : `0.0714285714rem solid ${Colors.ACCENT_GRAY}`,
            }}
            tabIndex={item.tabIndex}
            ref={item.ref}
            onClick={() => handleOnItemClick(item.value)}
            onKeyDown={item.onKeyDown || (() => {})}
          >
            {item.label}
          </CustomButton>
        );
      })}
    </Box>
  );
};
