import { FC, useEffect, useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { CustomButton } from "../../../../components";
import { Colors } from "../../../../configs";
import { AddSpotsDrawer } from "../../../../components/admin/add-spots-drawer/AddSpotsDrawer";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  deleteSpotAction,
  getAllSpotsAction,
} from "../../../../redux/actions/spotsAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

// interface ISpot {
//   id: number;
//   spot: string;
//   showNoFee: boolean;
// }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const SpotsPage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    spots,
    spotsLoading,
    addSpotLoading,
    updateSpotLoading,
    deleteSpotLoading,
  } = useAppSelector((state) => state.spot);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSpotId, setSelectedSpotId] = useState<number | undefined>(
    undefined
  );
  const [openDrawer, setOpenDrawer] = useState(false);

  const snackBar = useSnackbarContext();

  useEffect(() => {
    dispatch(getAllSpotsAction());
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedSpotId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleDeleteSpot = async () => {
    if (!selectedSpotId) {
      console.error("No Spot ID selected for deletion.");
      return;
    }

    try {
      await dispatch(
        deleteSpotAction({
          spotId: selectedSpotId,
          merchantId: 1,
          storeId: 1,
        })
      ).unwrap();
      snackBar.showSnackbar("Spot deleted successfully!", "success");

      dispatch(getAllSpotsAction());
    } catch (error) {
      snackBar.showSnackbar(
        "Failed to delete spot. Please try again.",
        "error"
      );
    } finally {
      handleClose();
    }
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          height: "57.5rem",
          marginTop: "4.357rem",
          paddingInline: "5rem",
          width: "100%",
          maxHeight: "57.5rem",
        }}
      >
        <AddSpotsDrawer
          open={openDrawer}
          toggleDrawer={handleOnDrawerClose}
          populateFor={
            selectedSpotId !== undefined
              ? spots?.find((spot) => spot.id === selectedSpotId)
              : undefined
          }
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: Colors.TEXT_GRAY_DARK }}>
            Spots
          </Typography>
          <CustomButton
            sx={{
              width: "11rem",
              height: "3rem",
            }}
            disabled={
              spotsLoading ||
              addSpotLoading ||
              updateSpotLoading ||
              deleteSpotLoading
            }
            onClick={() => {
              setOpenDrawer(true);
              setSelectedSpotId(undefined);
            }}
          >
            {spotsLoading ||
            addSpotLoading ||
            updateSpotLoading ||
            deleteSpotLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Add New"
            )}
          </CustomButton>
        </Box>

        <Box
          sx={{
            maxHeight: "51rem",
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.5rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Spot</StyledTableCell>
                <StyledTableCell>Has no-fee option</StyledTableCell>
                <StyledTableCell>Has custom-fee option</StyledTableCell>
                <StyledTableCell>Minor price (USD)</StyledTableCell>
                <StyledTableCell>Medium price (USD)</StyledTableCell>
                <StyledTableCell>Major price (USD)</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(spotsLoading ||
                addSpotLoading ||
                updateSpotLoading ||
                deleteSpotLoading) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7} sx={{ textAlign: "center" }}>
                    <CircularProgress size={24} color="inherit" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {!(
                spotsLoading ||
                addSpotLoading ||
                updateSpotLoading ||
                deleteSpotLoading
              ) &&
                spots?.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell sx={{ width: "25%" }}>
                      {row.spot}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.showNoFee ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.showCustomFee ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.minorPrice.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.mediumPrice.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.majorPrice.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleClick(e, row.id)}>
                        <MoreHorizIcon
                          sx={{ fontSize: "2rem" }}
                          color="primary"
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenDrawer(true);
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Spot</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteSpot}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete Spot</ListItemText>
                </MenuItem>
              </Menu>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </AdminLayout>
  );
};
