import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import {
  BaseResponseSingle,
  ISpotResponse,
} from "../../typings/interfaces/responses";
import {
  ADD_SPOT,
  DELETE_SPOT,
  GET_ALL_SPOTS,
  UPDATE_SPOTS,
} from "../../configs/apiConfig";
import { INewSpotRequest } from "../../typings/interfaces/requests/spots-requests";

export const getAllSpotsAction = createAsyncThunk(
  "spots/all-spots",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<ISpotResponse>>(
        GET_ALL_SPOTS
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const CreateSpotAction = createAsyncThunk(
  "spot/create-spot",
  async (
    {
      storeId,
      merchantId,
      spot,
      minorPrice,
      majorPrice,
      mediumPrice,
      showNoFee,
    }: {
      storeId: number;
      merchantId: number;
      spot: string;
      minorPrice: number;
      majorPrice: number;
      mediumPrice: number;
      showNoFee: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponseSingle<ISpotResponse>>(
        ADD_SPOT(merchantId, storeId),
        {
          merchantId,
          storeId,
          spot,
          minorPrice,
          majorPrice,
          mediumPrice,
          showNoFee,
        }
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const updateSpotAction = createAsyncThunk<
  ISpotResponse,
  { merchantId: number; storeId: number; id: number } & INewSpotRequest
>(
  "spot/update-spot",
  async ({ merchantId, storeId, id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axios.put<BaseResponseSingle<ISpotResponse>>(
        UPDATE_SPOTS(merchantId, storeId),
        { id, merchantId, storeId, ...payload }
      );

      return response.data.body;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const deleteSpotAction = createAsyncThunk<
  boolean,
  { merchantId: number; storeId: number; spotId: number }
>(
  "spot/delete-spot",
  async ({ merchantId, storeId, spotId }, { rejectWithValue }) => {
    try {
      await axios.delete(DELETE_SPOT(spotId, storeId, merchantId));
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
