export enum PaymentTypeEnum {
  CASH = "CASH",
  CHECK = "CHEQUE",
  CARD = "CARD",
  STORE_CREDIT = "STORE_CREDIT",
  MONTHLY = "MONTHLY",
  LATER = "LATER",
}
const paymentTypeLabelsMap: Record<PaymentTypeEnum, string> = {
  [PaymentTypeEnum.CASH]: "Cash",
  [PaymentTypeEnum.CHECK]: "Check",
  [PaymentTypeEnum.CARD]: "Card",
  [PaymentTypeEnum.STORE_CREDIT]: "Store Credit",
  [PaymentTypeEnum.MONTHLY]: "Monthly",
  [PaymentTypeEnum.LATER]: "Later",
};

export const paymentTypeLabels = (paymentType: string): string => {
  return paymentTypeLabelsMap[paymentType as PaymentTypeEnum] || "";
};

export enum BillPaymentStatus {
  FULLY_PAID = "FULLY_PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  NOT_PAID = "NOT_PAID",
}
export enum BillPickupStatus {
  PARTIALLY_PICKED_UP = "PARTIALLY_PICKED_UP",
  PICKED_UP = "PICKED_UP",
  NOT_PICKED_UP = "NOT_PICKED_UP",
}
export enum BillFilterDateType {
  DATE_IN = "DATE_IN",
  DUE_DATE = "DUE_DATE",
  PICKUP_DATE = "PICKUP_DATE",
  PAY_DATE = "PAY_DATE",
}
