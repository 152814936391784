import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Colors } from "../../../configs";
import { CustomButton } from "../../../components";
import ConfirmationModal from "./ConfirmationModal";
import ClearRegisterModal from "./ClearRegisterModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setIsRegisterCleaned } from "../../../redux/slice/cashierStationSlice";

interface DayEndButtonsProps {
  isRegisterBalancingPage?: boolean;
  onRegisterBalancingClick?: () => void;
  onSaveClick?: () => void;
}

const DayEndButtons: React.FC<DayEndButtonsProps> = ({
  isRegisterBalancingPage,
  onRegisterBalancingClick,
  onSaveClick,
}) => {
  const [isPrintClicked, setIsPrintClicked] = useState(false);
  const [isClearRegisterEnabled, setIsClearRegisterEnabled] = useState(false);
  const [isRegisterBalancingEnabled, setIsRegisterBalancingEnabled] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClearRegisterModal, setShowClearRegisterModal] = useState(false);
  const [editing, setEditing] = useState(false);

  const { endDayLoading } = useAppSelector((state) => state.cashierStation);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handlePrintClick = () => {
    alert("Printing Day End Report");
    setIsPrintClicked(true);
  };

  const handleFinishClick = () => {
    if (!isPrintClicked) {
      setShowModal(true);
    } else {
      setIsClearRegisterEnabled(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const handleClearRegisterClick = () => {
    setShowClearRegisterModal(true);
  };

  const handleCloseClearRegisterModal = () => setShowClearRegisterModal(false);

  const handleClearRegister = () => {
    setShowClearRegisterModal(false);
    setIsRegisterBalancingEnabled(true);
    dispatch(setIsRegisterCleaned());
  };

  const handleRegisterBalancingClick = () => {
    navigate("/pos/cash-register/day-end/register-balancing");
  };

  // const buttonTexts = [
  //   "Print Day End Report",
  //   "Download Day End Report",
  //   "Day End",
  //   "Clear Register",
  //   "Register Balancing",
  //   "Save",
  // ];
  const buttons = [
    {
      label: "Print Day End Report",
      onClick: handlePrintClick,
      disabled: false,
    },
    {
      label: "Download Day End Report",
      onClick: () => alert("Downloading Day End Report"),
      disabled: false,
    },
    {
      label: "Day End",
      onClick: handleFinishClick,
      disabled: isRegisterBalancingPage || isClearRegisterEnabled,
    },
    {
      label: "Clear Register",
      onClick: handleClearRegisterClick,
      disabled:
        !isClearRegisterEnabled ||
        isRegisterBalancingPage ||
        isRegisterBalancingEnabled,
    },
    {
      label: isRegisterBalancingPage
        ? "Balance Register"
        : "Register Balancing",
      onClick: () => {
        if (onRegisterBalancingClick) {
          onRegisterBalancingClick();
          setEditing(true);
        }
        handleRegisterBalancingClick();
      },
      disabled:
        (!isRegisterBalancingEnabled && !isRegisterBalancingPage) || editing,
    },
    {
      label: endDayLoading ? <CircularProgress /> : "Save",
      onClick: onSaveClick,
      disabled: !(isRegisterBalancingPage && editing) || endDayLoading,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "30rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.6rem",
          borderRadius: "0.7142857142857143rem",
          marginTop: "2.5rem",
        }}
      >
        {buttons.map((button, index) => (
          <CustomButton
            key={index}
            sx={{
              width: "30rem",
              height: "6.571428571428571rem",
              backgroundColor: Colors.WHITE,
              color: Colors.PRIMARY,
              fontSize: "1.428571428571429rem",
              borderRadius: "0.7142857142857143rem",
              border: "none",
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={button.onClick}
            disabled={button.disabled}
          >
            {button.label}
          </CustomButton>
        ))}
      </Box>

      <ConfirmationModal
        open={showModal}
        onClose={handleCloseModal}
        onContinue={() => {
          setShowModal(false);
          setIsClearRegisterEnabled(true);
        }}
        onPrint={() => {
          setShowModal(false);
          handlePrintClick();
          setIsClearRegisterEnabled(true);
        }}
      />
      <ClearRegisterModal
        open={showClearRegisterModal}
        onClose={handleCloseClearRegisterModal}
        onClear={handleClearRegister}
      />
    </>
  );
};

export default DayEndButtons;
