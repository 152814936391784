import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import {
  BaseResponseSingle,
  IdamageResponse,
} from "../../typings/interfaces/responses";
import {
  ADD_DAMAGE,
  DELETE_DAMAGE,
  GET_ALL_DAMAGES,
  UPDATE_DAMAGE,
} from "../../configs/apiConfig";
import { number } from "yup";
import { INewDamageRequest } from "../../typings/interfaces/requests/damageRequests";

export const getAllDamagesAction = createAsyncThunk(
  "damages/all-damages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<BaseResponse<IdamageResponse>>(
        GET_ALL_DAMAGES
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const CreateDamageAction = createAsyncThunk(
  "damage/create-damage",
  async (
    {
      storeId,
      merchantId,
      damage,
    }: { storeId: number; merchantId: number; damage: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<BaseResponseSingle<IdamageResponse>>(
        ADD_DAMAGE(merchantId, storeId),
        { merchantId, storeId, damage }
      );
      return response.data;
    } catch (err) {
      if (!err) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteDamageAction = createAsyncThunk<
  boolean,
  { merchantId: number; storeId: number; damageId: number }
>(
  "spot/delete-spot",
  async ({ merchantId, storeId, damageId }, { rejectWithValue }) => {
    try {
      await axios.delete(DELETE_DAMAGE(merchantId, storeId, damageId));
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const updateDamageAction = createAsyncThunk<
  IdamageResponse,
  {
    merchantId: number;
    storeId: number;
    id: number;
    damage: string;
  } & INewDamageRequest
>(
  "damage/update-damage",
  async ({ merchantId, storeId, id, damage }, { rejectWithValue }) => {
    try {
      const response = await axios.put<BaseResponseSingle<IdamageResponse>>(
        UPDATE_DAMAGE(merchantId, storeId),
        { id, merchantId, storeId, damage }
      );

      return response.data.body;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);
