import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton, CustomTextBox } from "../../../components";
import { pxToRem } from "../../../util";
import { Colors } from "../../../configs/colors";

interface IVoidInvoiceModalProps {
  open: boolean;
  onClose: () => void;
}

const VoidInvoiceModal: FC<IVoidInvoiceModalProps> = ({ open, onClose }) => {
  const [instruction, setInstruction] = useState("");

  return (
    <CustomModal
      title=""
      openModal={open}
      width={pxToRem(564)}
      height={pxToRem(410)}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          gap: "1rem",
          marginTop: pxToRem(-20),
        }}
      >
        <Typography sx={{ fontSize: pxToRem(20), color: Colors.MAIN_GRAY }}>
          You are about void this invoice IN3443. Please let us know
          .................... :
        </Typography>

        <CustomTextBox
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          sx={{
            width: pxToRem(467),
            "& .MuiInputBase-input": {
              width: pxToRem(467),
              height: pxToRem(69),
              minHeight: pxToRem(69),
              maxHeight: pxToRem(69),
            },
          }}
        />

        <Typography
          sx={{
            fontSize: pxToRem(26),
            fontfamily: "Sebino-Medium",
            color: Colors.MAIN_GRAY,
            marginTop: pxToRem(30),
          }}
        >
          Are you sure to void invoice?
        </Typography>

        <CustomButton
          sx={{
            background: Colors.PRIMARY,
            width: pxToRem(467),
            maxWidth: pxToRem(467),
            minWidth: pxToRem(467),
            height: pxToRem(64),
            maxHight: pxToRem(64),
            minHight: pxToRem(64),
            fontSize: pxToRem(24),
          }}
          onClick={onClose}
        >
          Void
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default VoidInvoiceModal;
