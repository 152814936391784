import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { FC, forwardRef, useEffect, useRef } from "react";
import { Colors } from "../../../configs";
import { NumericFormat } from "react-number-format";

export interface ITextBoxProps {
  styles?: React.CSSProperties;
  type?: "text" | "number" | "password" | "currency";
  prefix?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  autoFocus?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

type CustomTextBoxProps = ITextBoxProps & TextFieldProps;

export const CustomTextBox: FC<CustomTextBoxProps> = forwardRef(
  (props, ref) => {
    const {
      styles = {},
      type = "text",
      prefix = "",
      // value = "",
      autoFocus = false,
      onKeyDown = (e) => {},
      onChange = (e) => {},
      onClick = (e) => {},
      sx = {},
      ...otherProps
    } = props;

    const handleCurrencyInputValueChange = (e: {
      formattedValue: string;
      value: string;
      floatValue: number | undefined;
    }) => {
      let { value } = e;
      // if (value === "") {
      //   value = "0";
      // }
      // if (value !== "0.00" && value !== "0") {
      value = value.replace(/^0+(?!$)/, "");
      // }

      // console.log("value", value);
      const fakeEvent = {
        target: {
          value: value,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(fakeEvent);
    };
    const simpleRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (autoFocus) {
        simpleRef?.current?.focus();
        if (simpleRef.current) {
          simpleRef.current.selectionStart = simpleRef.current.value.length - 3;
          simpleRef.current.selectionEnd = simpleRef.current.value.length - 3;
        }
      }
    }, [autoFocus, ref]);

    if (type !== "currency") {
      return (
        <TextField
          onChange={onChange}
          onKeyDown={onKeyDown}
          variant="outlined"
          inputProps={{
            style: {
              height: "2.857rem",
              padding: "0rem 1.14285714rem",
              fontSize: "1.14rem",
              backgroundColor: Colors.WHITE,
              ...styles,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "0.157rem",
            },
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            ...sx,
            paddingBottom: "0",
            maxHeight: "2.857rem",
          }}
          inputRef={ref}
          type={type}
          value={otherProps?.value}
          onClick={onClick}
          {...otherProps}
        />
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 0,
            m: 0,
            gap: otherProps.helperText ? "0.38rem" : "0rem",
          }}
        >
          <NumericFormat
            onKeyDown={onKeyDown}
            onValueChange={handleCurrencyInputValueChange}
            thousandSeparator=","
            decimalScale={2}
            fixedDecimalScale
            maxLength={16}
            prefix={prefix}
            getInputRef={simpleRef}
            allowNegative={false}
            allowLeadingZeros={false}
            onFocus={(e) => {
              e.target.selectionStart = e.target.value.length - 3;
              e.target.selectionEnd = e.target.value.length - 3;
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = "0.00";
              }
            }}
            value={Number(otherProps?.value ?? 0)}
            style={{
              height: "2.857rem",
              padding: "0rem 1.14285714rem",
              fontSize: "1.6rem",
              background: otherProps.disabled
                ? Colors.ACCENT_GRAY
                : Colors.WHITE,
              border: `0.0714285714rem solid ${
                otherProps.error
                  ? Colors.ERROR_RED
                  : Colors.ACCENT_GRAY_DISABLED
              }`,
              borderRadius: "0.285714286rem",
              color: otherProps.disabled
                ? Colors.ACCENT_GRAY_DISABLED
                : Colors.TEXT_GRAY_DARK,
              textAlign: "right",
              ...styles,
            }}
            min={0}
            onClick={onClick}
            disabled={otherProps.disabled}
          />
          {otherProps.helperText && (
            <Typography
              sx={{ color: Colors.ERROR_RED, fontSize: "0.8rem", ml: "1rem" }}
            >
              {otherProps.helperText}
            </Typography>
          )}
        </Box>
      );
    }
  }
);
